import { useRoutes } from "react-router-dom"

// routes
import MainRoutes from "./MainRoute"
import AuthenticationRoutes from "./AuthenticationRoutes"
import { useSelector } from "react-redux"

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes(): React.ReactElement | null {
  return useRoutes([MainRoutes, AuthenticationRoutes])
}
