import React, { useState, useEffect, useMemo, type ChangeEvent } from "react";
import debounce from "lodash.debounce";
import {
  Box,
  Button,
  CircularProgress,
  TableContainer,
  type SelectChangeEvent,
  Card,
  CardContent,
  Popover,
  Chip,
  TextField,
  Checkbox,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import Paper from "@mui/material/Paper";
import { DocumentService } from "../../../services/intranet-portal-services";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import CustomPagination from "../../../ui-component/CustomUiComponent/Pagination";
import CustomTypography from "../../../ui-component/CustomUiComponent/Typography";
import CustomButton from "../../../ui-component/CustomUiComponent/Button";
import DatePickerCustom from "../../../ui-component/CustomUiComponent/DatePicker";
import CustomTable from "../../../ui-component/CustomUiComponent/TableContainer";
import moment from "moment";
import useIntranetPortalCoreApi from "../../../hooks/useIntranetPortalAdminApi";
import SelectOption from "../../../ui-component/CustomUiComponent/SelectOption";
import RevokeConfirmationModal from "../../../ui-component/CustomUiComponent/RevokeConfirmationModal";
import { type Row } from "react-table";
import usePageTracking from "../../../utils/withPageTracking";
import { useSelector } from "react-redux";
import { selectToken } from "../../utilities/auth/authSlice";
import axios, { AxiosError } from "axios";

const loaderStyle = {
  width: "100%",
  maxWidth: 360,
  bgcolor: "background.paper",
  align: "center",
};

const AcknowledgementAdminRequest = (): React.ReactElement => {
  const { handleRequest, isLoading } = useIntranetPortalCoreApi();
  const token = useSelector(selectToken);

  const [documentSearchKeyword, setDocumentSearchKeyword] = useState<any>("");
  const [employeeSearchKeyword, setEmployeeSearchKeyword] = useState<any>("");
  const [sortType, setSortType] = useState("asc");
  const [fromDate, setFromDate] = useState<any>("");
  const [toDate, setToDate] = useState<any>("");
  const [acknowledgedFromDate, setAcknowledgedFromDate] = useState<any>("");
  const [acknowledgedToDate, setAcknowledgedToDate] = useState<any>("");
  const [sorting, setSorting] = useState<string>("asc");
  const [skipCount, setSkipCount] = useState(0);
  const [maxResultCount, setMaxResultCount] = useState(10);
  const [
    documentAcknowledgementRequestStatusId,
    setDocumentAcknowledgementRequestStatusId,
  ] = useState<any>(null);
  const [documentId] = useState();
  const [data, setData] = useState<any>();
  const [page, setPage] = useState(0);
  const [acknowledgementStatusIds, setAcknowledgementStatusIds] =
    useState<any>();
  const [selectedRowIds, setSelectedRowIds] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [selectedRevokeDocument, setSelectedRevokeDocument] = useState<any>();
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  usePageTracking();
  const columns = [
    {
      id: "selection",
      Header: (
        <Checkbox
          checked={
            Object.keys(selectedRowIds).length === (data?.items?.length ?? 0)
          }
          onChange={() => {
            handleSelectAllRows();
          }}
        />
      ),
      Cell: ({ row }: any) => {
        const isChecked = !!selectedRowIds[row.original.id];
        return (
          <Checkbox
            checked={isChecked}
            onChange={() => {
              handleCheckboxChange(row);
            }}
          />
        );
      },
    },
    {
      Header: "S.No",
      Cell: ({ row }: { row: Row }) => {
        return <>{skipCount + row.index + 1}</>;
      },
    },
    {
      accessor: "acknowledgementRequestedToFullName",
      Header: "Employee Name",
    },
    {
      accessor: "documentName",
      Header: "Document Name",
    },
    {
      Header: "Request Sent Date",
      accessor: "creationTime",
      Cell: ({ row }: any) => {
        const date = moment(row.original.creationTime).format("YYYY-MM-DD");
        return date;
      },
      hidden: true,
    },
    {
      Header: "Acknowledgement Deadline",
      accessor: "dueDateTime",
      Cell: ({ row }: any) => {
        const date = moment(row.original.dueDateTime).format("YYYY-MM-DD");
        return date;
      },
      hidden: true,
    },
    {
      Header: "Acknowledged Date",
      accessor: "acknowledgementDoneDateTime",
      Cell: ({ row }: any) => {
        const date = moment(row.original.acknowledgementDoneDateTime).format(
          "YYYY-MM-DD"
        );
        return date === "Invalid date"
          ? row.original.documentStatusDisplayName === "Revoked"
            ? "Document Revoked"
            : "Document Not Acknowledged"
          : date;
      },
    },
    {
      accessor: "createdByFullName",
      Header: "Created By",
      hidden: true,
    },
    {
      accessor: "documentStatusDisplayName",
      Header: "Status",
      Cell: ({ row }: any) => {
        return (
          <Chip
            size="small"
            label={row.original.documentStatusDisplayName}
            sx={{
              fontSize: "1.2rem",
              background:
                row.original.documentStatusDisplayName === "New"
                  ? "#13A83D"
                  : row.original.documentStatusDisplayName === "Revoked"
                  ? "#D42C2C"
                  : row.original.documentStatusDisplayName === "Acknowledged"
                  ? "orange" // Blue color for "Acknowledged"
                  : "",
              color: "white",
            }}
          />
        );
      },
    },
    {
      accessor: "Revoke",
      Header: "Action",
      Cell: ({ row }: any) => {
        return (
          <Button
            disabled={row.original.documentStatusSystemName === "Revoked"}
            variant="outlined"
            onClick={() => {
              handleOpen();
              setSelectedRevokeDocument([row.original.id]);
            }}
          >
            Revoke
          </Button>
        );
      },
    },
  ];

  const handleOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  // ALL ACKNOWLEDGEMENT REQUEST
  const getAllAcknowledgeRequestForAdmin = async (): Promise<void> => {
    await handleRequest(
      DocumentService.getCoreApiApiAppDocumentPagedAndSortedDocumentAcknowledgmentRequestForAdmin(
        documentSearchKeyword,
        employeeSearchKeyword,
        sortType,
        fromDate,
        toDate,
        acknowledgedFromDate,
        acknowledgedToDate,
        documentId,
        documentAcknowledgementRequestStatusId,
        sorting,
        skipCount,
        maxResultCount
      )
    ).then((response) => {
      if (response != null) {
        setData(response);
      }
    });
  };

  // HANDLE REVOKE DATA
  const handleRevokeDocument = async (): Promise<void> => {
    // const payload: any = { selectedRevokeDocument };

    const payload = {
      documentAcknowledgementRequestId: selectedRevokeDocument,
    };
    await handleRequest(
      DocumentService.postCoreApiApiAppDocumentRevokeDocumentAcknowledgementRequest(
        payload
      )
    ).then(async (response) => {
      if (response != null) {
        toast.success("File Revoked successfully");
        setSelectedRowIds({});
        await getAllAcknowledgeRequestForAdmin();
      }
    });
  };

  // GET ACKNOWLEDGEMENT STATUS ID
  const handleAcknowledgeStatusId = async (): Promise<void> => {
    await handleRequest(
      DocumentService.getCoreApiApiAppDocumentDocumentAcknowledgmentRequestStatuses()
    ).then((response) => {
      if (response != null) {
        setAcknowledgementStatusIds(response);
      }
    });
  };

  const exportToExcel = async (): Promise<void> => {
    const requestBody = {
      maxResultCount: maxResultCount,
      skipCount: skipCount,
      sorting: sorting,
      documentSearchKeyword: documentSearchKeyword,
      employeeSearchKeyword: employeeSearchKeyword,
      sortType: sortType,
      fromDate: fromDate ? new Date(fromDate).toISOString() : null,
      toDate: toDate ? new Date(toDate).toISOString() : null,
      acknowledgedFromDate: acknowledgedFromDate
        ? new Date(acknowledgedFromDate).toISOString()
        : null,
      acknowledgedToDate: acknowledgedToDate
        ? new Date(acknowledgedToDate).toISOString()
        : null,
      documentId: documentId,
      documentAcknowledgementRequestStatusId:
        documentAcknowledgementRequestStatusId
          ? [documentAcknowledgementRequestStatusId]
          : null,
    };

    try {
      setExportLoading(true);
      const response = await axios({
        url: `${window.__RUNTIME_CONFIG__.VITE_BASE_URL}/core-api/api/app/document/download-document-acknowledgement-request`,
        method: "POST",
        data: requestBody,
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = "AcknowledgementRequest.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        toast.success("Excel file is being downloaded");
      } else {
        toast.error(
          "An error occurred while generating the Excel file. Please try again later."
        );
      }
    } catch (error: any) {
      toast.error(
        error?.message ?? "An error occurred while exporting to Excel"
      );
    } finally {
      setExportLoading(false);
    }
  };

  useEffect(() => {
    void getAllAcknowledgeRequestForAdmin();
    void handleAcknowledgeStatusId();
  }, [
    documentSearchKeyword,
    employeeSearchKeyword,
    sortType,
    fromDate,
    toDate,
    acknowledgedFromDate,
    acknowledgedToDate,
    documentAcknowledgementRequestStatusId,
    sorting,
    skipCount,
    maxResultCount,
  ]);

  const putInTableFirstPage = () => {
    setPage(0);
    setSkipCount(0);
  };

  // HANDLE ORDER
  const handleOrder = (sortOrder: any, id: string): void => {
    sortType === "asc" ? setSortType("desc") : setSortType("asc");
    setSorting(id);
  };

  // SEARCH
  const onSearchTermInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setDocumentSearchKeyword(event.target.value);
    putInTableFirstPage();
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(onSearchTermInput, 500),
    []
  );

  const onSearchFromDate = (fromDate: any): void => {
    setFromDate(moment(fromDate.$d).format("YYYY-MM-DD"));
    putInTableFirstPage();
  };

  const debouncedFromDateChangeHandler = useMemo(
    () => debounce(onSearchFromDate, 500),
    []
  );

  const onSearchToDate = (date: any): void => {
    setToDate(moment(date.$d).format("YYYY-MM-DD"));
    putInTableFirstPage();
  };

  const debouncedToDateChangeHandler = useMemo(
    () => debounce(onSearchToDate, 500),
    []
  );

  const onSearchAcknowledgeFromDate = (acknowledgedFromDate: any): void => {
    setAcknowledgedFromDate(
      moment(acknowledgedFromDate.$d).format("YYYY-MM-DD")
    );
    putInTableFirstPage();
  };

  const debouncedAcknowledgeFromDateChangeHandler = useMemo(
    () => debounce(onSearchAcknowledgeFromDate, 500),
    []
  );

  const onSearchAcknowledgeToDate = (acknowledgedToDate: any): void => {
    setAcknowledgedToDate(moment(acknowledgedToDate.$d).format("YYYY-MM-DD"));
    putInTableFirstPage();
  };

  const debouncedAcknowledgeToDateChangeHandler = useMemo(
    () => debounce(onSearchAcknowledgeToDate, 500),
    []
  );

  const onEmployeeSearchTermInput = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setEmployeeSearchKeyword(event.target.value);
    putInTableFirstPage();
  };

  const debouncedEmployeeChangeHandler = useMemo(
    () => debounce(onEmployeeSearchTermInput, 500),
    []
  );

  // PAGINATION
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setMaxResultCount(Number(event.target.value));
    putInTableFirstPage();
  };

  const handlePageChange = (event: any, page: number): void => {
    setSkipCount(maxResultCount * page);
    setPage(page);
  };

  const handleSearchClear = (): void => {
    setFromDate("");
    setToDate("");
    setDocumentSearchKeyword("");
    setEmployeeSearchKeyword("");
    setAcknowledgedFromDate("");
    setAcknowledgedToDate("");
    setDocumentAcknowledgementRequestStatusId(null);
    const input = document.getElementsByTagName("input");
    for (let i = 0; i < input.length; i++) {
      input[i].value = "";
    }
  };

  // filter popover
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  const handleSelectAllRows = (): void => {
    if (Object.keys(selectedRowIds).length === data?.items?.length) {
      // Deselect all rows
      setSelectedRowIds({});
    } else {
      // Select all rows
      const newSelectedRowIds: any = {};
      data?.items?.forEach((item: any) => {
        newSelectedRowIds[item.id] = true;
      });
      setSelectedRowIds(newSelectedRowIds);
    }
  };

  const handleCheckboxChange = (row: any): void => {
    const selectedRowId = row.original.id;
    setSelectedRowIds((prevSelectedRowIds: any) => {
      const updatedSelectedRowIds = { ...prevSelectedRowIds };
      updatedSelectedRowIds[selectedRowId] =
        !updatedSelectedRowIds[selectedRowId];
      return updatedSelectedRowIds;
    });
  };

  return (
    <>
      <Box>
        <Grid
          container
          spacing={2}
          mb={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <CustomTypography
              variant="h5"
              label={"Acknowledgement Request"}
              sx={{ fontWeight: "500", color: "#D42C2C" }}
            />
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <CustomButton
                  sx={{ height: "100%" }}
                  color="error"
                  variant="contained"
                  onClick={() => {
                    setSelectedRevokeDocument(Object.keys(selectedRowIds));
                    handleOpen();
                  }}
                  label={"Revoke All Selected Employee Docs"}
                />
              </Grid>
              <Grid item>
                <CustomButton
                  sx={{ height: "100%" }}
                  color="error"
                  variant="contained"
                  startIcon={<DownloadForOfflineIcon fontSize="small" />}
                  onClick={exportToExcel}
                  disabled={exportLoading}
                  label={exportLoading ? "Downloading...." : "Export to Excel"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container gap={2} sx={{ py: "1rem" }}>
          <Grid item xs={12} sm={5} lg={3}>
            <TextField
              label="Search Employee Name"
              fullWidth
              size="small"
              id="filled-basic"
              onChange={debouncedEmployeeChangeHandler}
            />
          </Grid>
          <Grid item xs={12} sm={5} lg={3}>
            <TextField
              label="Search Document Name"
              fullWidth
              size="small"
              id="filled-basic"
              // placeholder="Search"
              onChange={debouncedChangeHandler}
            />
          </Grid>
          <Grid item xs={12} sm={5} lg={2} gap={2}>
            <SelectOption
              label="Search Status"
              name="documentAcknowledgementRequestStatusId"
              value={documentAcknowledgementRequestStatusId ?? ""}
              onChange={(event: SelectChangeEvent<string>) => {
                setDocumentAcknowledgementRequestStatusId(event.target.value);
                putInTableFirstPage();
              }}
              options={acknowledgementStatusIds}
            />
          </Grid>
          <Grid item xs={12} lg={3} gap={2}>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                height: "100%",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <CustomButton
                label={"Filter"}
                variant={"contained"}
                aria-describedby={id}
                onClick={handlePopoverClick}
                startIcon={<TuneIcon fontSize="small" />}
                color="error"
                sx={{ height: "100%" }}
              />
              <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Card>
                  <CardContent sx={{ p: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.5rem",
                      }}
                    >
                      <DatePickerCustom
                        label={"Request Sent From Date"}
                        selected={fromDate}
                        onChange={debouncedFromDateChangeHandler}
                        maxDate
                      />

                      <DatePickerCustom
                        label={"Request Sent To Date"}
                        selected={toDate}
                        onChange={debouncedToDateChangeHandler}
                        minDate={fromDate}
                      />
                      <DatePickerCustom
                        label={"Acknowledge From Date"}
                        selected={acknowledgedFromDate}
                        onChange={debouncedAcknowledgeFromDateChangeHandler}
                        maxDate
                      />
                      <DatePickerCustom
                        label={"Acknowledge To Date"}
                        selected={acknowledgedToDate}
                        onChange={debouncedAcknowledgeToDateChangeHandler}
                        minDate={acknowledgedFromDate}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Popover>
              <CustomButton
                label={"Clear"}
                variant={"contained"}
                onClick={handleSearchClear}
                sx={{ height: "100%" }}
                color="error"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Card sx={{ mb: "1rem" }} elevation={1}>
        <CardContent sx={{ p: 2 }}>
          <Box component={Paper}>
            <TableContainer component={Paper} sx={{ height: 550 }}>
              {isLoading ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress color="primary" sx={loaderStyle} />
                </Grid>
              ) : (
                <>
                  {data?.items && data?.items.length > 0 ? (
                    <CustomTable
                      columns={columns}
                      data={data.items}
                      handleSort={handleOrder}
                      handleRevoke={handleRevokeDocument}
                    />
                  ) : (
                    <span style={{ alignContent: "center" }}>
                      No Record Available
                    </span>
                  )}
                </>
              )}
            </TableContainer>
            {data?.items && data?.items.length > 0 && (
              <CustomPagination
                component={"div"}
                count={Number(data?.totalCount)}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={maxResultCount}
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
              />
            )}
          </Box>
        </CardContent>
      </Card>

      <RevokeConfirmationModal
        open={open}
        onHide={handleClose}
        onRevoke={handleRevokeDocument}
      />
    </>
  );
};

export default AcknowledgementAdminRequest;
