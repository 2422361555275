/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IntranetPortal_Responses_ResponseDto } from '../models/IntranetPortal_Responses_ResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CalendarService {

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static getCoreApiApiAppCalendarPublicGoogleCalendarUrl(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/calendar/public-google-calendar-url',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param calendarUrl
     * @returns IntranetPortal_Responses_ResponseDto Success
     * @throws ApiError
     */
    public static postCoreApiApiAppCalendarSetPublicGoogleCalendarUrl(
        calendarUrl?: string,
    ): CancelablePromise<IntranetPortal_Responses_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/calendar/set-public-google-calendar-url',
            query: {
                'calendarUrl': calendarUrl,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
