import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import {
  CardContent,
  Typography,
  Button,
  Grid,
  TextField,
  useTheme,
  FormHelperText,
  useMediaQuery,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Box,
  Card,
  styled,
} from "@mui/material";

// project imports
import AnimateButton from "../../../ui-component/extended/AnimateButton";
import useQuery from "../../../hooks/useQuery";
import {
  InternalApplicationLogoService,
  InternalApplicationService,
  type IntranetPortal_InternalApplications_Dtos_InternalApplicationDto,
} from "../../../services/intranet-portal-services";
import SelectOption from "../../../ui-component/CustomUiComponent/SelectOption";
import useIntranetPortalCoreApi from "../../../hooks/useIntranetPortalAdminApi";

// third party
import * as Yup from "yup";
import { ErrorMessage, Form, Formik, type FormikHelpers } from "formik";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { toast } from "react-toastify";
import usePageTracking from "../../../utils/withPageTracking";

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required("Name is required *"),
  displayName: Yup.string().required("Display Name is required *"),
  isActive: Yup.string().required("Status is required *"),
  applicationUrl: Yup.string().required("Application is required *"),
});

// export const ThumbsContainer = styled(() => ({
//   display: "flex",
//   flexDirection: "row",
//   flexWrap: "wrap",
//   marginTop: "16px",
// }))

const AddInternalApp = (): React.ReactElement => {
  const { handleRequest } = useIntranetPortalCoreApi();

  const navigate = useNavigate();
  const theme = useTheme();
  const [getEditInternalAppData, setGetEditInternalAppData] =
    useState<IntranetPortal_InternalApplications_Dtos_InternalApplicationDto>();
  const [addLogoResponseUrl, setAddLogoResponse] = useState<string>("");
  const [files, setFiles] = useState<any>([]);
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [logoResponse, setLogoResponse] = useState("");
  const params = useQuery();
  const id = params.get("id");

  const initialState = {
    name: "",
    displayName: "",
    logoUrl: addLogoResponseUrl,
    applicationUrl: "",
    isActive: "",
    description: "",
  };
  const statusOptions = [
    { id: "Active", name: "Active" },
    { id: "InActive", name: "InActive" },
  ];

  const logoInitialState = {
    logo: "",
  };

  const handleInternalAppLogo = async (
    logo: string | { logo: string },
    _: FormikHelpers<{ logo: string }> | undefined = undefined
  ): Promise<void> => {
    const formDataLogo: any = {
      logo,
    };
    await handleRequest(
      InternalApplicationService.postCoreApiApiAppInternalApplicationUploadLogo(
        formDataLogo
      )
    ).then((response) => {
      if (response != null) {
        setAddLogoResponse(response);
        toast.success("Logo uploaded successfully");
      }
    });
  };

  const handleInternalApp = async (val: any): Promise<void> => {
    const formData: any = {
      name: val.name,
      description: val.description,
      displayName: val.displayName,
      applicationUrl: val.applicationUrl,
      isActive: val.isActive !== "InActive",
      logoUrl: id == null ? addLogoResponseUrl : val.logoUrl,
    };
    if (id != null) {
      try {
        const response =
          await InternalApplicationService.putCoreApiApiAppInternalApplicationInternalApplication(
            id,
            formData
          );
        if (response != null) {
          toast.success("Internal app updated successfully");
          navigate(-1);
        }
      } catch (e: any) {
        if (e.status !== null) {
          toast.error(e.status);
        } else if (e?.body?.error?.validationErrors !== null) {
          toast.error(e?.body?.error?.validationErrors);
        } else if (e.message !== null) {
          toast.error(e.message);
        } else {
          toast.error("Unable to fetch api");
        }
      }
    } else {
      try {
        const response =
          await InternalApplicationService.postCoreApiApiAppInternalApplicationInternalApplication(
            formData
          );
        if (response != null) {
          toast.success("Internal app added successfully");
          navigate(-1);
        }
      } catch (e: any) {
        if (e.status != null) {
          toast.error(e.status);
        } else if (e?.body?.error?.validationErrors != null) {
          toast.error(e?.body?.error?.validationErrors);
        } else if (e.message != null) {
          toast.error(e.message);
        } else {
          toast.error("Unable to fetch api");
        }
      }
    }
  };

  const fetchEditData = async (): Promise<void> => {
    if (id != null) {
      await handleRequest(
        InternalApplicationService.getCoreApiApiAppInternalApplicationInternalApplicationById(
          id
        )
      ).then((response) => {
        if (response != null) {
          setGetEditInternalAppData(response);
        }
      });
    }
  };

  const fetchLogoImageData = async (): Promise<void> => {
    if (id != null) {
      await handleRequest(
        InternalApplicationLogoService.getCoreApiInternalApplicationLogosFileUncached(
          id
        )
      ).then((response) => {
        if (response != null) {
          setLogoResponse(response);
        }
      });
    }
  };

  useEffect(() => {
    if (id !== null) {
      void fetchEditData();
      void fetchLogoImageData();
    }
  }, []);
  usePageTracking();

  const onDrop = useCallback((acceptedFiles: any): void => {
    const logo = acceptedFiles;
    handleInternalAppLogo(logo)
      .then(() => {
        if (acceptedFiles?.length != null) {
          setFiles((previousFiles: any) => [
            // ...previousFiles,
            ...acceptedFiles.map((file: any) =>
              Object.assign(file, { preview: URL.createObjectURL(file) })
            ),
          ]);
        }
      })
      .catch((error) => {
        // Handle error if necessary
        console.error("Error handling document logo:", error);
      });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    },
    maxSize: 1024 * 1000,
  });
  const removeFile = (name: string): void => {
    setFiles((files: any) => files.filter((file: any) => file.name !== name));
  };

  const Dropzone = styled("div")({
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "2px",
    borderRadius: "2px",
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    "&:hover": {
      borderColor: "#c62828",
    },
  });

  return (
    <>
      <Box>
        <Grid container spacing={2} mb={2} alignItems={"center"}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: 500, color: "#D42C2C" }}>
              {id !== null ? "Update" : "Add"} Internal Application
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            {/* Logo Upload Area of internal app */}
            <Formik
              initialValues={logoInitialState}
              onSubmit={handleInternalAppLogo}
              enableReinitialize={true}
            >
              {({ setFieldValue }) => (
                <>
                  <Form>
                    <div {...getRootProps()}>
                      <Dropzone>
                        <input {...getInputProps()} />
                        {/* {isDragActive ? (
                          <p>Drop the files here ...</p>
                        ) : ( */}
                        <p>click to select files</p>
                        {/* )} */}
                      </Dropzone>
                      <div>
                        <>
                          <ImageList /* sx={{}} */ cols={8}>
                            {files?.map((file: any) => (
                              <ImageListItem key={file.name}>
                                <img
                                  src={file.preview}
                                  alt={file.name}
                                  width={100}
                                  height={100}
                                  onLoad={() => {
                                    URL.revokeObjectURL(file.preview);
                                  }}
                                  loading="lazy"
                                />
                                <ImageListItemBar
                                  sx={{
                                    background:
                                      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, " +
                                      "rgba(0,0,0,0.6) 70%, rgba(0,0,0,0) 100%)",
                                  }}
                                  position="bottom"
                                  actionIcon={
                                    <IconButton sx={{ color: "white" }}>
                                      <HighlightOffRoundedIcon
                                        sx={{ fontSize: "24px" }}
                                      />
                                    </IconButton>
                                  }
                                  actionPosition="right"
                                  onClick={() => {
                                    removeFile(file.name);
                                  }}
                                />
                              </ImageListItem>
                            ))}
                          </ImageList>
                        </>
                      </div>
                    </div>
                    <FormHelperText
                      variant="outlined"
                      sx={{ fontSize: "1rem" }}
                      error
                      id="standard-weight-helper-text--register"
                    >
                      <ErrorMessage name="logo" />
                    </FormHelperText>
                  </Form>
                </>
              )}
            </Formik>

            {/* Form post of internal app */}
            <Formik
              initialValues={
                id != null
                  ? {
                      name: getEditInternalAppData?.name ?? "",
                      displayName: getEditInternalAppData?.displayName ?? "",
                      description: getEditInternalAppData?.description ?? "",
                      logoUrl:
                        (addLogoResponseUrl != null && addLogoResponseUrl) ??
                        (getEditInternalAppData?.logoUrl != null &&
                          getEditInternalAppData?.logoUrl),
                      applicationUrl:
                        getEditInternalAppData?.applicationUrl ?? "",
                      isActive:
                        getEditInternalAppData?.isActive === true
                          ? "Active"
                          : "InActive" ?? "",
                    }
                  : initialState
              }
              onSubmit={handleInternalApp}
              enableReinitialize={id != null}
              validationSchema={FORM_VALIDATION}
            >
              {({ handleChange, values, setFieldValue }) => (
                <Form noValidate>
                  <Grid container spacing={matchDownSM ? 0 : 2} marginY={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Name"
                        margin="none"
                        name="name"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        autoComplete="off"
                        variant="outlined"
                      />
                      <FormHelperText
                        variant="outlined"
                        sx={{ fontSize: "1rem" }}
                        error
                        id="standard-weight-helper-text--register"
                      >
                        <ErrorMessage name="name" />
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Display Name"
                        margin="none"
                        name="displayName"
                        type="text"
                        value={values.displayName}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      <FormHelperText
                        variant="outlined"
                        sx={{ fontSize: "1rem" }}
                        error
                        id="standard-weight-helper-text--register"
                      >
                        <ErrorMessage name="displayName" />
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Application Url"
                        margin="none"
                        name="applicationUrl"
                        type="text"
                        value={values.applicationUrl}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      <FormHelperText
                        variant="outlined"
                        sx={{ fontSize: "1rem" }}
                        error
                        id="standard-weight-helper-text--register"
                      >
                        <ErrorMessage name="applicationUrl" />
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectOption
                        label="Status"
                        name="isActive"
                        value={values.isActive}
                        onChange={handleChange}
                        options={statusOptions}
                        placeholder="Status"
                        // setFieldValue={setFieldValue}
                      />
                      <FormHelperText
                        variant="outlined"
                        sx={{ fontSize: "1rem" }}
                        error
                        id="standard-weight-helper-text--register"
                      >
                        <ErrorMessage name="isActive" />
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                      <TextField
                        fullWidth
                        label="Description"
                        margin="none"
                        name="description"
                        type="text"
                        multiline
                        onChange={handleChange}
                        rows={3}
                        value={values.description}
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        style={{ display: "none" }}
                        fullWidth
                        label={
                          (addLogoResponseUrl === "" ||
                            getEditInternalAppData?.logoUrl) === ""
                            ? ""
                            : "Logo Url"
                        }
                        margin="none"
                        name="logoUrl"
                        type="text"
                        value={
                          addLogoResponseUrl === ""
                            ? getEditInternalAppData?.logoUrl
                            : addLogoResponseUrl
                        }
                        // disabled
                        // value={addLogoResponseUrl}
                        autoComplete="off"
                      />
                      <FormHelperText
                        variant="outlined"
                        sx={{ fontSize: "1rem" }}
                        error
                        id="standard-weight-helper-text--register"
                      >
                        <ErrorMessage name="logoUrl" />
                      </FormHelperText>
                    </Grid>

                    {id != null && (
                      <>
                        <ImageList sx={{}} cols={8}>
                          <ImageListItem>
                            <img
                              src={`${
                                window.__RUNTIME_CONFIG__.VITE_BASE_URL
                              }/core-api/internal-application-logos/file/${
                                getEditInternalAppData?.id as string
                              }/uncached`}
                              width={100}
                              height={100}
                              loading="lazy"
                            />
                          </ImageListItem>
                        </ImageList>
                      </>
                    )}
                  </Grid>
                  <Box sx={{ mt: 2 }}>
                    <AnimateButton>
                      <Button
                        disableElevation
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="error"
                      >
                        {id != null ? "Update" : "Add"}
                      </Button>
                    </AnimateButton>
                  </Box>
                </Form>
              )}
            </Formik>
            <AnimateButton>
              <Button
                sx={{ mt: 2 }}
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="outlined"
                color="error"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back To the list
              </Button>
            </AnimateButton>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
export default AddInternalApp;
