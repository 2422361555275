import type { ReactElement } from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import type { RootState } from "../store/reducer";
import Loader from "../ui-component/Loader";
import PageNotFound from "../views/pages/pageNotFound";

const AdminAccess = ({
  requiredRoles,
  children,
}: {
  requiredRoles: string;
  children: any;
}): ReactElement => {
  const { currentUserRole } = useSelector((state: RootState) => state.user);

  const [validUser, setValidUser] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (requiredRoles === currentUserRole) {
      setValidUser(true);
    } else {
      setValidUser(false);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [currentUserRole]);

  return loading ? (
    <Loader />
  ) : validUser ? (
    children
  ) : (
    <PageNotFound message={"You're not authorized"} status={"401"} />
  );
};

export default AdminAccess;
