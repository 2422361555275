import _ from "lodash"

// Format data to objects
export const restructureNonFieldPermissions = (
  data: string[],
  permissionIdentifier: string
): any => {
  return data?.map((item: string) => {
    const permissionParts = item.replaceAll(permissionIdentifier, "").split(".")
    return {
      groupKey: permissionParts[0],
      key: item,
      text: permissionParts.length > 1 ? permissionParts[1] : "View",
    }
  })
}

// Filter data which has permissionIdentifier
const getAdminPolicies = (policies: any, permissionIdentifier: string): string[] =>
  Object.keys(policies)
    .map((item) => {
      return item
    })
    .filter((item) => {
      return item.startsWith(permissionIdentifier)
    })

// Filter data which don't have FieldLevel
export const getIntranetPermissions = (permissions: any): any => {
  return permissions.filter((item: any) => {
    return item.includes("IntranetPortal.")
  })
}

// Converts Strings Data with value to groups objects
export const getFilteredPermissionData = (policies: any, permissionInfo?: string): any => {
  const permissionIdentifier = permissionInfo ?? "IntranetPortal."

  const adminPolicies = getAdminPolicies(policies, permissionIdentifier)

  const intranetPermissions = getIntranetPermissions(adminPolicies)

  const intranetPermissionsData = restructureNonFieldPermissions(
    intranetPermissions,
    permissionIdentifier
  )

  const intranetPermissionsGroups = _.groupBy(intranetPermissionsData, (item) => item.groupKey)

  return { intranetPermissionsGroups }
}
