import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Grid,
  List,
  ListItem,
  Menu,
  MenuItem,
  Modal,
  Paper,
  TableContainer,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import CustomTypography from "../../../ui-component/CustomUiComponent/Typography";
import CustomButton from "../../../ui-component/CustomUiComponent/Button";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect, useMemo, useState } from "react";
import useIntranetPortalCoreApi from "../../../hooks/useIntranetPortalAdminApi";
import { EmployeeMoodService } from "../../../services/intranet-portal-services";
import CustomTable from "../../../ui-component/CustomUiComponent/TableContainer";
import { DownloadRounded } from "@mui/icons-material";
import DeleteConfirmationModal from "../../../ui-component/CustomUiComponent/DeleteConfirmationModal";
import { toast } from "react-toastify";
import FilterListIcon from "@mui/icons-material/FilterList";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const loaderStyle = {
  width: "100%",
  maxWidth: 360,
  bgcolor: "background.paper",
  align: "center",
};

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3, 2),
  },
}));
const MoodMonitor = (): React.ReactElement => {
  const { handleRequest, isLoading } = useIntranetPortalCoreApi();
  const classes = useStyles();
  const theme = useTheme();

  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [sortOpen, setSortOpen] = useState(false);

  // DELETE CONFIRMATION MODAL
  const handleOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  // DRAGGABLE SORTING MODAL
  const handleSortOpen = (): void => {
    // getImage(data)
    setSortOpen(true);
  };
  const handleSortClose = (): void => {
    setSortOpen(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row }: any) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "",
        accessor: "iconUrl",
        Cell: ({ row }: any) => {
          return (
            <img
              src={`${
                window.__RUNTIME_CONFIG__.VITE_BASE_URL
              }/core-api/Mood-logos/file/${row.original.id as string}/uncached`}
              alt=""
              width={30}
            />
          );
        },
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
        // hidden: true,
      },
      {
        Header: "Status",
        Cell: ({ row }: any) => {
          return (
            <Chip
              size="small"
              label={row.original.isActive === true ? "Active" : "InActive"}
              sx={{
                fontSize: "1.2rem",
                background:
                  (row.original.isActive === true && "#13A83D") ||
                  (row.original.isActive === false && "#D42C2C") ||
                  "orange",
                color: "white",
              }}
            />
          );
        },
      },
      {
        Header: "Action",
        Cell: ({ row }: any) => {
          return (
            <>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState: any) => (
                  <>
                    <MoreVertIcon {...bindTrigger(popupState)} />
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() => {
                          navigate(
                            `/pages/add-mood-emoji?id=${
                              row?.original?.id as string
                            }`
                          );
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleOpen();
                          setDeleteId(row.original.id);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </PopupState>
            </>
          );
        },
      },
    ],
    []
  );

  // get mood emoji data
  const getMoodEmojiData = async (): Promise<void> => {
    const response = await handleRequest(
      EmployeeMoodService.getCoreApiApiAppEmployeeMoodMood()
    );
    response !== null && setData(response);
  };
  const deleteMood = async (): Promise<void> => {
    const resp = await handleRequest(
      EmployeeMoodService.deleteCoreApiApiAppEmployeeMoodMood(deleteId)
    );
    await getMoodEmojiData();
    setDeleteId("");
    resp !== null && toast.success("Deleted successfully");
  };

  const handleSort = async (
    srcId: string,
    desId: string,
    srcOrder: number,
    desOrder: number
  ): Promise<void> => {
    const body = {
      id: [desId, srcId],
      orderNumber: [desOrder, srcOrder],
    };
    await handleRequest(
      EmployeeMoodService.postCoreApiApiAppEmployeeMoodChangeMoodOrder(body)
    ).then(async (response) => {
      if (response != null) {
        toast.success("Sorted Successfully.");
        await getMoodEmojiData();
      }
    });
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    padding: "0.5rem",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    borderRadius: 0,
    borderBottom: "1px solid #f5f5f5",
    justifyContent: "space-between",
  }));

  useEffect(() => {
    void getMoodEmojiData();
  }, []);

  return (
    <>
      <Box>
        <Grid container spacing={2} mb={2} alignItems={"center"}>
          <Grid item xs={12} md={6}>
            <CustomTypography
              variant="h5"
              label={"Mood Monitor"}
              sx={{ fontWeight: "500", color: "#D42C2C" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",

                justifyContent: "flex-end",
                gap: "1rem",
              }}
            >
              <CustomButton
                label={"Reorder Internal Application"}
                variant={"outlined"}
                onClick={() => {
                  handleSortOpen();
                }}
                startIcon={<FilterListIcon fontSize="small" />}
                sx={{ height: "100%" }}
              />
              <CustomButton
                label={"Add Mood"}
                variant={"contained"}
                onClick={() => {
                  navigate("/pages/add-mood-emoji");
                }}
                startIcon={<AddIcon fontSize="small" />}
                color="error"
                sx={{ height: "100%" }}
                // disabled={data && data?.length >= 12}
              />
              <CustomButton
                label={" Emoji "}
                variant={"contained"}
                title={"Download Emoji"}
                onClick={() =>
                  window.open("https://joypixels.com/emoji/animated", "_blank")
                }
                startIcon={<DownloadRounded fontSize="small" />}
                color="error"
                sx={{ height: "100%" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Card sx={{ mb: "1rem" }} elevation={1}>
        <CardContent sx={{ p: 2 }}>
          <Box component={Paper}>
            <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
              {isLoading ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress color="primary" sx={loaderStyle} />
                </Grid>
              ) : (
                <>
                  {data && data?.length > 0 ? (
                    <>
                      <CustomTable
                        columns={columns}
                        data={data}
                        // handleSort={handleOrder}
                      ></CustomTable>
                    </>
                  ) : (
                    <span style={{ alignContent: "center" }}>
                      No Record Available
                    </span>
                  )}
                </>
              )}
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
      <Modal
        open={sortOpen}
        onClose={handleSortClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <List
          className={classes.paper}
          style={{
            height: "315px",
            width: "300px",
            boxSizing: "content-box",
            overflow: "auto",
            paddingRight: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "1rem",
            }}
          >
            <CustomTypography
              sx={{ fontWeight: "400", color: theme.palette.error.main }}
              variant="h6"
              label={"Sort Internal Application"}
            />
            <ButtonBase sx={{ borderRadius: "12px" }} onClick={handleSortClose}>
              <HighlightOffRoundedIcon color="error" />
            </ButtonBase>
          </Box>

          <DragDropContext
            onDragEnd={(param: any) => {
              const srcI = param?.source?.index;
              const desI = param?.destination?.index;

              if (desI !== undefined && srcI !== desI) {
                const srcOrder = data[srcI].orderNumber;
                const desOrder = data[desI].orderNumber;

                const srcId = data[srcI].id; // Source item ID
                const desId = data[desI].id; // Destination item ID

                data[srcI].orderNumber = desOrder;
                data[desI].orderNumber = srcOrder;

                // const reorderedData = [...data]
                // const draggedItem = reorderedData.splice(srcI, 1)[0]
                // reorderedData.splice(desI, 0, draggedItem)

                void handleSort(srcId, desId, desOrder, srcOrder);
              }
            }}
          >
            <Droppable droppableId="droppable-1">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {data?.map((item: any, index1: number) => (
                    <Draggable
                      key={item?.id}
                      draggableId={"draggable-" + String(item?.id)}
                      index={index1}
                    >
                      {(provided, snapshot) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{ width: "100%", padding: 0 }}
                        >
                          <Box sx={{ width: "100%" }}>
                            <Item sx={{ display: "flex" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "1rem",
                                }}
                              >
                                <img
                                  width="30px"
                                  height="30px"
                                  src={`${
                                    window.__RUNTIME_CONFIG__.VITE_BASE_URL
                                  }/core-api/Mood-logos/file/${
                                    item.id as string
                                  }`}
                                  alt={"Image is not available"}
                                  style={{
                                    objectFit: "contain",
                                    objectPosition: "center",
                                  }}
                                />
                                <Typography
                                  variant="body1"
                                  color="#434343"
                                  textAlign="center"
                                >
                                  {item?.name}
                                </Typography>
                              </Box>
                              <DragIndicatorIcon />
                            </Item>
                          </Box>
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </List>
      </Modal>
      <DeleteConfirmationModal
        open={open}
        onHide={handleClose}
        onDelete={deleteMood}
      />
    </>
  );
};

export default MoodMonitor;
