import { IDENTITY_CONFIG, METADATA_OIDC } from "../utils/authConst";
import { UserManager, WebStorageStateStore, Log } from "oidc-client";

export default class AuthService {
  UserManager;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      metadata: {
        ...METADATA_OIDC,
      },
    });
    // Logger

    this.UserManager.events.addUserLoaded((user) => {
      if (window.location.href.includes("signin-oidc")) {
        this.navigateToScreen();
      }
    });
    // this.UserManager.events.addSilentRenewError((e) => {
    //     //console.log("silent renew error", e.message);
    // });

    // this.UserManager.events.addAccessTokenExpired(() => {
    //     //console.log("token expired");
    //     //this.signinSilent();
    // });
  }

  signinRedirectCallback = (): any => {
    return this.UserManager.signinRedirectCallback();
  };

  getUser = async (): Promise<any> => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return await this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  parseJwt = (token: string): any => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = (): void => {
    localStorage.setItem("redirectUri", window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  navigateToScreen = (): void => {
    window.location.replace("/");
  };

  isAuthenticated = (): boolean => {
    // console.log('aunticated')
    const oidcStorage = JSON.parse(
      localStorage.getItem(
        `oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`
      )!
    );

    return !!oidcStorage && !!oidcStorage.access_token;
  };

  signinSilent = (): void => {
    this.UserManager.signinSilent()
      .then((user) => {
        console.log("signed in", user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  signinSilentCallback = (): void => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = (): any => {
    return this.UserManager.createSigninRequest();
  };

  logout = (): void => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem("id_token"),
    });
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = (): void => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace("/");
    });
    this.UserManager.clearStaleState();
  };

  getToken = (): string => {
    const oidcStorage = JSON.parse(
      localStorage.getItem(
        `oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`
      )!
    );
    return oidcStorage ? oidcStorage.access_token : null;
  };
}
