import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Chip,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Skeleton,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { subtle } from "crypto";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FaAngleDown, FaAngleUp, FaTelegramPlane } from "react-icons/fa";
import { useSelector } from "react-redux";
import { v4 as uid } from "uuid";
import BotImage from "../../assets/images/amniverseLogo/Amniverse.svg";
import banner from "../../assets/images/curve-red.webp";
import chatPlaceholderLogo from "../../assets/undraw_social_networking_re_i1ex.svg";
import useIntranetPortalCoreApi from "../../hooks/useIntranetPortalAdminApi";
import { UserProfileService } from "../../services/intranet-portal-services";
import { RootState } from "../../store/reducer";
import CustomTypography from "../../ui-component/CustomUiComponent/Typography";
import Amniverse from "../../assets/images/amniverseLogo/Amniverse.svg"

type TMessages = {
  text: string;
  sender: "bot" | "user";
  sourceDocuments: any[];
};

type Props = {
  clientId: string;
  clientSecret: string;
  projectId: string;
  themeColor: string;
  botTitle: string;
  subTitle: string;
  setModalIsOpen: any;
};

export type ChatRequest = {
  api_key: string | undefined;
  question: string;
  history: any[];
  conversation_id: string;
};

export type NewConversation = {
  projectId?: string;
  api_key?: string;
  projectIngestIdentifier?: string;
};

const chat = async (props: ChatRequest) => {
  try {
    const response = await fetch(
      `${window.__RUNTIME_CONFIG__.CHATWITHDOCS_BASE_URL}/chat`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(props),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return error;
  }
};

export default function BotForm({
  clientId,
  clientSecret,
  projectId,
  themeColor,
  botTitle,
  subTitle,
  setModalIsOpen,
}: Props) {
  const theme = useTheme<any>();
  const [messages, setMessages] = useState<Array<TMessages>>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [cError, setCerror] = useState<any>();
  const [conversationId, setConversationId] = useState("");
  const [history, setHistory] = useState<any>([]);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  // user profile data

  const { handleRequest } = useIntranetPortalCoreApi();
  const [employeeDetails, setEmployeeDetails] = useState<any>();

  const { user } = useSelector((state: RootState) => state.auth);

  const getEmployeeProfile = async (): Promise<void> => {
    await handleRequest(
      UserProfileService.getCoreApiApiAppUserProfileUserProfileById(
        user?.profile?.sub
      )
    ).then((response) => {
      if (response != null) {
        setEmployeeDetails(response);
      }
    });
  };

  // Specify the type explicitly as an array of booleans
  const [expandedStates, setExpandedStates] = useState<boolean[]>([]);

  const apiKey = btoa(clientId.concat(":", clientSecret));

  const toggleExpand = (index: number) => {
    const newExpandedStates = [...expandedStates];
    newExpandedStates[index] = !newExpandedStates[index];
    setExpandedStates(newExpandedStates);
  };

  const { handleSubmit, register, reset } = useForm();

  //get conversation id
  useEffect(() => {
    fetch(
      `${window.__RUNTIME_CONFIG__.CHATWITHDOCS_BASE_URL}/newConversation`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          projectId,
          api_key: apiKey,
        }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setConversationId(data.conversation_id);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        setCerror("Invalid api key provided");
      });
    getEmployeeProfile();
  }, []);

  //scroll effect
  useEffect(() => {
    // Scroll to the bottom when messages change
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const updateMessage = useCallback(
    (message = "", sender: "bot" | "user", sourceDocuments = []) => {
      setMessages((currentMessages) => [
        ...currentMessages,
        {
          text: message,
          sender,
          sourceDocuments: sourceDocuments,
        },
      ]);
    },
    [setMessages]
  );

  const updateHistory = (prompt: string, response: string) => {
    const chatHistory = [...history];
    chatHistory.push([prompt, response]);
    setHistory(chatHistory);
  };

  const handleFormSubmit = async (prompt: string) => {
    setLoading(true);
    try {
      updateMessage(prompt, "user", []);
      const response = await chat({
        conversation_id: conversationId,
        question: prompt,
        history,
        api_key: apiKey,
      });
      const { text, sourceDocuments }: any = response;
      updateHistory(prompt, text as string);
      updateMessage(text, "bot", sourceDocuments);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const onSubmit = (data: any) => {
    handleFormSubmit(data.prompt);
    reset();
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "50rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* title */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          p: "1rem",
          boxShadow: "0 -8px 12px 10px rgba(0,0,0,.18)",
          zIndex: 1,
        }}
      >
        <Box
          className="title-icon"
          sx={{
            display: "flex",
            gap: "2rem",
            alignItems: "center",
          }}
        >
          {/* <Avatar src={BotImage} /> */}
          <CustomTypography label={botTitle} variant={"h6"} />
        </Box>
        <Button onClick={() => setModalIsOpen(false)}>close</Button>
      </Box>
      <div className="chatBot-container">
        <aside
          className="chatbot-sidebar"
          style={{
            // background: "linear-gradient(to right, #D82D2B, #1f1c18)",
            background:
              "linear-gradient(320deg, rgb(216, 45, 43), rgb(45 40 32))",
            position: "relative",
            zIndex: "10",
          }}
        >

          {/* curve botform */}
          {/* <Box
            sx={{
              width: "104%",
              position: "absolute",
              bottom: 0,
              right: 0,
              transform: "rotate(180deg)",
            }}
          >
            <svg viewBox="0 30 500 1000">
              <path
                d="M 0,100 C 150,200 350,0 500,100 L 500,00 L 0,0"
                fill="white"
              ></path>
            </svg>
          </Box> */}

          <Box
            className="item"
            sx={{
              display: "flex",
              height: "97%",
              alignItems: "flex-start",
              flexDirection: "column",
              gap: "1.5rem",
              p: 1,
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                alignSelf: "flex-start",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: ".3rem",
              }}
            >
              <img src={Amniverse} height={45} alt="Amniverse logo" />
              {/* <Avatar
                src={BotImage}
                alt=""
                sx={{
                  width: "5rem",
                  height: "5rem",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                }}
              /> */}
              <CustomTypography
                variant="h5"
                sx={{
                  mt: ".9rem",
                  color: "white",
                  fontWeight: "600",
                  letterSpacing: "1px",
                  textShadow: "1px 1px 3px rgba(0, 0, 0, 0.5)",
                }}
                label="AMWISE"
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: "1rem",
                position: "relative",
                pl: ".5rem",
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  transform: "rotate(180deg)",
                  left: 0,
                  top: -12,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-quote"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M10 11h-4a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h3a1 1 0 0 1 1 1v6c0 2.667 -1.333 4.333 -4 5"></path>
                  <path d="M19 11h-4a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h3a1 1 0 0 1 1 1v6c0 2.667 -1.333 4.333 -4 5"></path>
                </svg>
              </Typography>
              <CustomTypography
                variant="body1"
                sx={{
                  fontSize: "1.2rem",
                  letterSpacing: ".6px",
                  ml: "1rem",
                  // textAlign: "justify",
                  // color: "#e0e0e0",
                  lineHeight: "2.3rem",
                }}
                label={`${subTitle}`}
              />
            </Box>
          </Box>
        </aside>
        <main>
          <div className="chatBot-mainContainer">
            {/* message-box  */}
            <Box
              className="box mainImgContainer"
              ref={chatContainerRef}
              sx={{
                // maxHeight: "38rem",
                overflowY: "auto",
              }}
            >
              {cError ? (
                <Box className="item">
                  <Box className="err-msg" sx={{}}>
                    <CustomTypography
                      label="Something went wrong. Please wait for a while and try again !"
                      variant="body2"
                      sx={{
                        fontSize: "1.1rem",
                        color: "red",
                        mx: "4rem",
                        p: "1rem",
                        border: "1px solid red",
                        borderRadius: "10px",
                      }}
                    />
                  </Box>
                </Box>
              ) : (
                <>
                  {/* static chatbot description responsive sidebar replacement */}
                  <Box
                    className="msgItem static-msg-item"
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "flex-start",
                      mb: "2rem",
                    }}
                  >
                    <Box
                      className="icon"
                      sx={{
                        order: "",
                      }}
                    >
                      <Avatar
                        src={BotImage}
                        alt="img"
                        sx={{
                          height: "3rem",
                          width: "3rem",
                          background: "transparent",
                        }}
                      />
                    </Box>
                    <Box className="msg">
                      <Paper
                        variant="elevation"
                        sx={{
                          background: "#fff",
                          color: "#000",
                          // ml: "14px",
                          // p: "1rem",
                          order: "",
                          wordBreak: "break-word",
                        }}
                        className="msg-box msg-bot"
                      >
                        <Box>
                          <Typography fontSize={"12px"}>{subTitle}</Typography>
                        </Box>
                      </Paper>
                    </Box>
                  </Box>
                  {/*static chatbot description ends*/}
                  {messages?.map((message, index) => {
                    return (
                      <>
                        <Box
                          className="msgItem"
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent:
                              message.sender == "bot"
                                ? "flex-start"
                                : "flex-end",
                            mb: "2rem",
                          }}
                        >
                          <Box
                            className="icon"
                            sx={{
                              order: message.sender == "bot" ? "" : 2,
                            }}
                          >
                            {message.sender === "bot" ? (
                              <Avatar
                                src={BotImage}
                                alt="img"
                                sx={{
                                  height: "3rem",
                                  width: "3rem",
                                  background: "transparent",
                                }}
                              />
                            ) : (
                              <Avatar
                                alt={employeeDetails?.fullName ?? "Profile"}
                                src={`http://hr.amniltech.com//images/Uploads/${
                                  employeeDetails?.profilePictureUrl as string
                                }`}
                                sx={{
                                  width: "3rem",
                                  height: "3rem",
                                  // ml: "1rem",
                                }}
                              />
                            )}
                          </Box>
                          <Box className="msg">
                            <Paper
                              variant="elevation"
                              sx={{
                                background:
                                  message.sender == "bot" ? "#fff" : "#d42c2b",
                                color:
                                  message.sender == "bot" ? "#000" : "white",
                                // ml: "14px",
                                // p: "1rem",
                                order: message.sender == "bot" ? "" : 1,
                                wordBreak: "break-word",
                              }}
                              className={`msg-box ${
                                message.sender == "bot" ? "msg-bot" : "msg-user"
                              }`}
                            >
                              <div>{message.text}</div>
                              {/* <CustomTypography
                                variant="body1"
                                label={message.text}
                              /> */}
                              {message?.sender === "bot" ? (
                                <div className="source">
                                  {message?.sourceDocuments?.map(
                                    (source, i) => (
                                      <div key={i}>
                                        <li
                                          onClick={() => toggleExpand(i)}
                                          style={{
                                            marginTop: "1rem",
                                            fontWeight: "300",
                                            color: "grey",
                                            fontSize: "1.2rem",
                                            cursor: "pointer",
                                          }}
                                        >
                                          Source {i + 1}:{" "}
                                          {source?.metadata.source
                                            .split("/")
                                            .pop()
                                            .split("-")
                                            .slice(2)
                                            .join("-")}
                                          {expandedStates[i] ? (
                                            <FaAngleUp
                                              style={{paddingLeft: "5px"}}
                                            />
                                          ) : (
                                            <FaAngleDown
                                              style={{paddingLeft: "5px"}}
                                            />
                                          )}
                                        </li>

                                        {expandedStates[i] ? (
                                          <ul>
                                            <li
                                              style={{
                                                fontWeight: "300",
                                                color: "grey",
                                                fontSize: "1.2rem",
                                              }}
                                            >
                                              Page:{" "}
                                              {source?.metadata?.pdf_numpages}
                                            </li>
                                            <li
                                              style={{
                                                fontWeight: "300",
                                                color: "grey",
                                                fontSize: "1.2rem",
                                                marginBottom: "1rem",
                                              }}
                                            >
                                              Line: from{" "}
                                              {source?.metadata?.loc.lines.from}{" "}
                                              to{" "}
                                              {source?.metadata?.loc.lines.to}
                                            </li>
                                          </ul>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : null}
                            </Paper>
                          </Box>
                        </Box>
                      </>
                    );
                  })}

                  {/* Initial Messgae Box Picture */}
                  {messages?.length == 0 ? (
                    <Box
                      sx={{
                        height: "100%",
                        textAlign: "center",
                        position: "relative",
                      }}
                      className="chatbox-placeholder-img"
                    >
                      <img
                        src={chatPlaceholderLogo}
                        style={{
                          height: "100%",
                          width: "90%",
                          mixBlendMode: "darken",
                        }}
                        alt=""
                        draggable="false"
                      />
                      <h4
                        style={{
                          margin: 0,
                          fontWeight: 500,
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          fontSize: "12px",
                        }}
                      >
                        No messages yet !
                      </h4>
                    </Box>
                  ) : null}

                  {isLoading ? (
                    <Box
                      className="loader"
                      sx={{
                        // height: "11rem",
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-start",
                        mt: "2rem",
                        gap: "1rem",
                      }}
                    >
                      <Avatar
                        src={BotImage}
                        sx={{
                          height: "3rem",
                          width: "3rem",
                          background: "transparent",
                        }}
                      />
                      <Box
                        sx={{
                          background: "#eeeeee47",
                        }}
                      >
                        <Paper
                          variant="elevation"
                          sx={{
                            background: "#fff",
                            color: "#000",
                            wordBreak: "break-word",
                            borderRadius: "10px",
                            borderBottomLeftRadius: 0,
                          }}
                          className={`msg-box ${"msg-bot"}`}
                        >
                          <div id="wave">
                            <span className="srfriendzone">
                              <span className="srname">AMWISE</span> is typing
                            </span>
                            <span className="dot one" />
                            <span className="dot two" />
                            <span className="dot three" />
                          </div>
                        </Paper>
                      </Box>

                      {/* <CustomTypography label="Generating answer, Please wait ....." /> */}
                    </Box>
                  ) : null}
                  {error ? (
                    <Box className="item">
                      <Box className="err-msg" sx={{}}>
                        <CustomTypography
                          label="Something went wrong. Please wait for a while and try again !"
                          variant="body2"
                          sx={{
                            fontSize: "1.1rem",
                            color: "red",
                            mx: "4rem",
                            p: "1rem",
                            border: "1px solid red",
                            borderRadius: "10px",
                          }}
                        />
                      </Box>
                    </Box>
                  ) : null}
                </>
              )}
            </Box>

            {/* input or typing area   */}
            <Box className="typing-area">
              {/* loader */}
              {/* {isLoading && (
                <div id="wave">
                  <span className="srfriendzone">
                    <span className="srname">AMWISE</span> is typing
                  </span>
                  <span className="dot one" />
                  <span className="dot two" />
                  <span className="dot three" />
                </div>
              )} */}
              <div className="typing-area-wrapper">
                {/* avatar of user */}
                <Avatar
                  alt={employeeDetails?.fullName ?? "Profile"}
                  src={`http://hr.amniltech.com//images/Uploads/${
                    employeeDetails?.profilePictureUrl as string
                  }`}
                  sx={{
                    width: "3.5rem",
                    height: "3.5rem",
                  }}
                />

                <div className="input-field">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{position: "relative"}}
                  >
                    <OutlinedInput
                      id="outlined-basic-prompt"
                      placeholder="Ask your question"
                      {...register("prompt")}
                      fullWidth
                      autoComplete="off"
                      required={true}
                      autoFocus={true}
                      sx={{
                        pr: "5.3rem",
                        "& input": {
                          "&::placeholder": {
                            fontSize: "13px", // Adjust the font size as needed
                          },
                        },
                      }}
                      endAdornment={
                        <IconButton
                          type="submit"
                          sx={{
                            cursor: "pointer",
                            m: 0,
                            position: "absolute",
                            right: 5,
                          }}
                          size="small"
                          // variant="contained"
                          disabled={!!isLoading}
                        >
                          {isLoading ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22px"
                              height="22px"
                              viewBox="0 0 100 100"
                              preserveAspectRatio="xMidYMid"
                            >
                              <circle
                                cx="50"
                                cy="50"
                                fill="none"
                                stroke={"#1f1f1f"}
                                strokeWidth="8"
                                r="35"
                                stroke-dasharray="164.93361431346415 56.97787143782138"
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="rotate"
                                  repeatCount="indefinite"
                                  dur="1s"
                                  values="0 50 50;360 50 50"
                                  keyTimes="0;1"
                                />
                              </circle>
                            </svg>
                          ) : (
                            <FaTelegramPlane
                              style={{
                                fill: "",
                                cursor: "pointer",
                                height: "22px",
                                width: "22px",
                              }}
                            />
                          )}
                        </IconButton>
                      }
                    />
                  </form>
                </div>
              </div>
            </Box>
          </div>
        </main>
      </div>
    </Box>
  );
}
