import { useCallback, useState } from "react";
import { type ApiError, OpenAPI } from "../services/intranet-portal-services";

import { selectToken } from "../views/utilities/auth/authSlice";
import { toast } from "react-toastify";

interface Type {
  dismissError: () => void;
  error: ApiError | undefined;
  isLoading: boolean;
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
}

export const useIntranetPortalCoreApi = (): Type => {
  const [error, setError] = useState<ApiError | undefined>(undefined);
  const [isLoading, setIsloading] = useState<boolean>(false);
  //   const token = useSelector(selectToken);

  OpenAPI.BASE = window.__RUNTIME_CONFIG__?.VITE_BASE_URL ?? "";
  OpenAPI.TOKEN = selectToken()?.toString();

  const handleRequest = useCallback(async function <T>(request: Promise<T>) {
    setIsloading(true);
    try {
      const response = await request;
      setError(undefined);
      return response;
    } catch (exception: any) {
      setError(exception);
      if (exception?.body?.error?.validationErrors !== null) {
        toast.error(exception?.body?.error?.validationErrors);
      } else if (exception.message !== null) {
        toast.error(exception.message);
      } else {
        toast.error("Unable to fetch api");
      }
    } finally {
      setIsloading(false);
    }
  }, []);

  const dismissError = (): void => {
    setError(undefined);
  };

  return { dismissError, error, isLoading, handleRequest };
};

export default useIntranetPortalCoreApi;
