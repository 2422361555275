import { Dialog } from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

interface ModalComponentType {
  open: boolean;
  handleClose: () => void;
  children: any;
  styleValue?: Record<string, string | number>;
  showClose?: boolean;
  maxWidth?: any;
}

const ModalComponent = ({
  open,
  handleClose,
  children,
  styleValue,
  showClose,
  maxWidth,
}: ModalComponentType): React.ReactElement => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={styleValue}
        scroll="paper"
        maxWidth={maxWidth}
      >
        {showClose != null && (
          <ClearRoundedIcon
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              margin: "1rem",
              cursor: "pointer",
            }}
          />
        )}

        {children}
      </Dialog>
    </>
  );
};

export default ModalComponent;
