import { useState, useRef, useEffect } from "react"

import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

// material-ui
import { useTheme, styled } from "@mui/material/styles"
import {
  Avatar,
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
  Badge,
} from "@mui/material"

// third-party
import PerfectScrollbar from "react-perfect-scrollbar"

// project imports
import MainCard from "../../../../ui-component/cards/MainCard"
import Transitions from "../../../../ui-component/extended/Transitions"

// assets
import { IconLogout, IconUserCircle } from "@tabler/icons"
import {
  AbpApplicationConfigurationService,
  EmployeeMoodService,
  UserProfileService,
} from "../../../../services/intranet-portal-services"
import type { Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_ApplicationConfigurationDto } from "../../../../services/intranet-portal-services"
import AuthService from "../../../../services/authService"
import { selectIsAuthenticated, selectUser } from "../../../../views/utilities/auth/authSlice"
import useIntranetPortalCoreApi from "../../../../hooks/useIntranetPortalAdminApi"
import { RootState } from "../../../../store/reducer"

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = (): React.ReactElement => {
  const theme = useTheme()
  const customization = useSelector((state: any) => state.customization)
  const navigate = useNavigate()
  // const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false)
  const [data, setData] =
    useState<Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_ApplicationConfigurationDto>()
  const [greetMessage, setGreetMessage] = useState("")
  const [employeeDetails, setEmployeeDetails] = useState<any>()
  const [myMoodToday, setMyMoodToday] = useState<any>([])
  const [dataFromLocalStorage, setDataFromLocalStorage] = useState<string | null>("")

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */

  const user = useSelector(selectUser)
  const userId = user?.profile?.sub
  const authenticated = useSelector(selectIsAuthenticated)
  // const anchorRef = useRef()
  const authService = new AuthService()
  const { handleRequest } = useIntranetPortalCoreApi()
  const anchorRef = useRef<HTMLElement | HTMLDivElement | any | null>(null)
  const { myMoodIdValue } = useSelector((state: RootState) => state.mood)

  const getMyMoodForToday = async (): Promise<void> => {
    await handleRequest(EmployeeMoodService.getCoreApiApiAppEmployeeMoodMyMoodForToday()).then(
      (response) => {
        if (response != null) {
          setMyMoodToday([response])
        }
      }
    )
  }
  const moodValue = localStorage.getItem("mood")

  const handleLogout = async (): Promise<void> => {
    authService.signoutRedirectCallback()
  }
  const getApplicationConfigurationData = async (): Promise<void> => {
    await handleRequest(
      AbpApplicationConfigurationService.getCoreApiApiAbpApplicationConfiguration()
    ).then((response) => {
      if (response != null) {
        setData(response)
      }
    })
  }

  const getEmployeeProfile = async (): Promise<void> => {
    await handleRequest(UserProfileService.getCoreApiApiAppUserProfileUserProfileById(userId)).then(
      (response) => {
        if (response != null) {
          setEmployeeDetails(response)
        }
      }
    )
  }

  useEffect(() => {
    if (authenticated !== null) {
      void getEmployeeProfile()
      void getMyMoodForToday()
    }
    void getApplicationConfigurationData()
    getHour()
  }, [authenticated])

  const handleClose = (event: any): void => {
    if (anchorRef.current?.contains(event.target) ?? false) {
      return
    }
    setOpen(false)
  }

  const getHour = (): void => {
    const date = new Date()
    const hour = date.getHours()
    if (hour < 12) setGreetMessage("Good Morning")
    else if (hour >= 12 && hour <= 17) setGreetMessage("Good Afternoon")
    else if (hour >= 17 && hour <= 24) setGreetMessage("Good Evening")
  }

  // const handleListItemClick = ({ event, index, route = "" }: any) => {
  //   setSelectedIndex(index);
  //   handleClose(event);

  //   if (route && route !== "") {
  //     navigate(route);
  //   }
  // };
  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen)
  }

  // const prevOpen = useRef(open);
  // useEffect(() => {
  //   getHour();
  //   if (prevOpen.current && !open) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open;
  // }, [open]);
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 24,
    height: 24,
    border: "none",
    background: "white",
    padding: 0,
  }))

  return (
    <>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        badgeContent={
          <SmallAvatar
            alt="M"
            title="Your today's mood"
            src={`${window.__RUNTIME_CONFIG__.VITE_BASE_URL}/core-api/Mood-logos/file/${
              myMoodIdValue ? myMoodIdValue : myMoodToday[0]?.moodId
            }`}
          />
        }
      >
        <Avatar
          variant="circular"
          src={`http://hr.amniltech.com//images/Uploads/${
            employeeDetails?.profilePictureUrl as string
          }`}
          sx={{
            ...theme.typography,
            cursor: "pointer",
            width: 40,
            height: 40,
          }}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          color="inherit"
          onClick={handleToggle}
        />
      </Badge>

      {/* {myMoodToday ? (
        <img
          src={`${window.__RUNTIME_CONFIG__.VITE_BASE_URL}/core-api/Mood-logos/file/${
            myMoodToday?.moodId as string
          }`}
          alt=""
          title="Your today's mood"
          style={{ width: "50px", height: "50px" }}
        />
      ) : null} */}
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }: any) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack spacing={0.5}>
                        <Typography variant="h6" sx={{ color: "#D42C2C" }}>
                          {greetMessage}
                        </Typography>
                        <Typography component="span" variant="body1" sx={{ fontWeight: 400 }}>
                          {`${(data?.currentUser?.name as string) ?? ""} ${
                            (data?.currentUser?.surName as string) ?? ""
                          }`}
                        </Typography>
                      </Stack>
                      <Typography variant="subtitle2">
                        {data?.currentUser?.roles?.map((role: string) => role)}
                      </Typography>
                    </Stack>
                  </Box>
                  <Divider />
                  <PerfectScrollbar style={{ height: "100%", overflowX: "hidden" }}>
                    <Box sx={{ p: 1 }}>
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "10px",
                          p: 0,
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            // mt: 0.5,
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius as number}px`,
                            py: "8px",
                            pl: "10px",
                            m: "0",
                          }}
                          // selected={selectedIndex === 0}
                          // onClick={(event) => handleListItemClick(event, 0, '/user/account-profile/profile1')}
                        >
                          <ListItemIcon>
                            <IconUserCircle stroke={1.5} size="1.8rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                onClick={(event) => {
                                  navigate(`pages/employee-details?id=${userId as string}`)
                                  handleClose(event)
                                }}
                              >
                                Profile
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        {/* <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 0}
                          onClick={() => {
                            navigate("/profile/account-setting");
                            handleClose(event);
                          }}
                        >
                          <ListItemIcon>
                            <IconSettings stroke={1.5} size="1.8rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body1">
                                Account Settings
                              </Typography>
                            }
                          />
                        </ListItemButton> */}
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius as string}px`,
                          }}
                          // selected={selectedIndex === 4}
                          onClick={() => {
                            handleLogout()
                              .then()
                              .catch((error) => {
                                // Handle any errors that might occur during logout
                                console.error("Logout error:", error)
                              })
                          }}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.8rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body1">Logout</Typography>} />
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  )
}

export default ProfileSection
