import { ThemeProvider } from "@mui/material/styles"
import { CssBaseline, StyledEngineProvider } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { selectToken, setCredentials } from "./views/utilities/auth/authSlice"
import { useLocation, useNavigate } from "react-router-dom"
import React, { useEffect, useState } from "react"
import ReactGA from "react-ga4"
// routing
import Routes from "../src/routes"

// defaultTheme
import theme from "./themes"

// project imports
import NavigationScroll from "./layout/NavigationScroll"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import AuthService from "./services/authService"
import useIntranetPortalCoreApi from "./hooks/useIntranetPortalAdminApi"
import { setUserRole } from "./views/utilities/auth/userRoleSlice"
import { AbpApplicationConfigurationService } from "./services/intranet-portal-services"
import { AppDispatch } from "./store/reducer"

// import { MeService } from "./services/variable-product-services";

// ==============================|| APP ||============================== //

const App = (): React.ReactElement => {
  const location = useLocation()
  const history = useNavigate()
  const customization = useSelector((state: any) => state.customization)
  const { handleRequest } = useIntranetPortalCoreApi()
  const token = useSelector(selectToken)
  const user = useSelector((state: any) => state.user?.userRole)
  const [loading, isLoading] = useState<boolean>(true)

  const gaTrackingID = window.__RUNTIME_CONFIG__.VITE_GA_TRACKING_CODE
  React.useEffect(() => {
    if (gaTrackingID) {
      ReactGA.initialize(gaTrackingID, {
        gaOptions: {
          debug_mode: true,
        },
        gtagOptions: {
          debug_mode: true,
        },
      })
      ReactGA.send({ hitType: "pageview", page: location.pathname })
    }
  }, [location])

  /* Login */
  const dispatch: AppDispatch = useDispatch()
  const authService = new AuthService()

  useEffect(() => {
    if (authService.isAuthenticated()) {
      authService
        .getUser()
        .then((user) => {
          if (user != null) {
            dispatch(
              setCredentials({
                isAuthenticated: authService.isAuthenticated(),
                token: authService.getToken(),
                user,
              })
            )
          }
        })
        .catch((err) => {
          if (err) authService.signoutRedirectCallback()
          console.log(err)
        })
    } else {
      if (location.pathname !== "/callback") {
        history("/login")
      }
    }
  }, [])

  useEffect(() => {
    if (token?.length > 0) {
      void handleRequest(
        AbpApplicationConfigurationService.getCoreApiApiAbpApplicationConfiguration()
      ).then((values: any) => {
        dispatch(
          setUserRole({
            userRole: values,
            permission: values?.auth?.grantedPolicies,
          })
        )
      })
      // const getAuthUserDataPromise = handleRequest(
      //   AbpApplicationConfigurationService.getCoreApiApiAbpApplicationConfiguration()
      // )
      // const getAuthUserPermissionPromise = handleRequest(
      //   AbpApplicationConfigurationService.getCoreApiApiAbpApplicationConfiguration()
      // )

      // Promise.all([getAuthUserDataPromise, getAuthUserPermissionPromise])
      //   .then((values: any) => {
      //
      //   })
      //   .catch((exception) => {
      //     authService.signoutRedirectCallback()
      //     console.log(exception)
      //   })
    }
  }, [token])

  // const isAuthenticated = useSelector(selectIsAuthenticated)
  // const appToken = useSelector(selectToken);

  /*  set Last Loggin Time */
  // useEffect(() => {
  //   if (isAuthenticated && !localStorage.getItem("loggedTime")) {
  //     handleRequest(
  //       AbpApplicationConfigurationService.getCoreApiApiAbpApplicationConfiguration()
  //     )
  //       .then((data: any) => {
  //         // const lastloggedin = data.data.lastLoggedInTime;
  //         localStorage.setItem("loggedTime", "2023-01-01");
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       })
  //   }
  // }, [isAuthenticated]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeProvider>
      <ToastContainer />
    </StyledEngineProvider>
  )
}

export default App
