/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IntranetPortal_Employees_Dtos_EmployeeDto } from '../models/IntranetPortal_Employees_Dtos_EmployeeDto';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Employees_Dtos_EmployeeDto_IntranetPortal_Application_Contracts } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Employees_Dtos_EmployeeDto_IntranetPortal_Application_Contracts';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmployeeService {

    /**
     * @param searchKeyword
     * @returns IntranetPortal_Employees_Dtos_EmployeeDto Success
     * @throws ApiError
     */
    public static getCoreApiApiAppEmployeeSearchEmployees(
        searchKeyword?: string,
    ): CancelablePromise<Array<IntranetPortal_Employees_Dtos_EmployeeDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/employee/search-employees',
            query: {
                'SearchKeyword': searchKeyword,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param searchKeyword
     * @param sortType
     * @param departmentId
     * @param designationId
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Employees_Dtos_EmployeeDto_IntranetPortal_Application_Contracts Success
     * @throws ApiError
     */
    public static getCoreApiApiAppEmployeePagedAndSortedEmployeeList(
        searchKeyword?: string,
        sortType?: string,
        departmentId?: Array<string>,
        designationId?: Array<string>,
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
    ): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Employees_Dtos_EmployeeDto_IntranetPortal_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/employee/paged-and-sorted-employee-list',
            query: {
                'SearchKeyword': searchKeyword,
                'SortType': sortType,
                'DepartmentId': departmentId,
                'DesignationId': designationId,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
