import { useEffect, useState } from "react";

// material-ui
import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ButtonBase,
  CircularProgress,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

// third-party

import { useNavigate } from "react-router-dom";

// project imports

// assets
import { IconSearch } from "tabler-icons";
import { shouldForwardProp } from "@mui/system";
import { EmployeeService } from "../../../../services/intranet-portal-services";

// styles
// const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
//   zIndex: 1100,
//   width: "99%",
//   top: "-55px !important",
//   padding: "0 12px",
//   [theme.breakpoints.down("sm")]: {
//     padding: "0 10px",
//   },
// }))

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: 434,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    "& input": {
      background: "transparent !important",
      paddingLeft: "4px !important",
      "&::placeholder": {
        fontSize: "13px", // Adjust the font size as needed
      },
    },
    [theme.breakpoints.down("lg")]: {
      width: 250,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 4,
      background: "#fff",
    },
  })
);

interface UserProfile {
  abpUserId: string;
  userName: string;
  name: string;
  middleName: string;
  surName: string;
  fullName: string;
  designationId: string;
  designationName: string;
  departmentId: string;
  departmentName: string;
  email: string;
  profilePictureUrl: string | null;
  phoneNumber: string;
  hiredDate: string;
}

// const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
//   // ...theme.typography.commonAvatar,
//   // ...theme.typography.mediumAvatar,
//   background: theme.palette.secondary.light,
//   color: theme.palette.secondary.dark,
//   "&:hover": {
//     background: theme.palette.secondary.dark,
//     color: theme.palette.secondary.light,
//   },
// }))

// const cursorStyle = {
//   cursor: "pointer",
// }

// ==============================|| SEARCH INPUT - MOBILE||============================== //

// const MobileSearch = ({ value, setValue }: any) => {
//   const theme = useTheme();
//   const { handleRequest } = useIntranetPortalCoreApi();

//   return (
//     <TextField
//       label="Search"
//       fullWidth
//       size="small"
//       id="filled-basic"
//       onChange={(e: any) => setValue(e.target.value)}
//       InputProps={{
//         endAdornment: (
//           <InputAdornment position="end">
//             <ButtonBase sx={{ borderRadius: "12px" }}>
//               <HighlightOffRoundedIcon color="error" />
//             </ButtonBase>
//           </InputAdornment>
//         ),
//       }}
//     />
//   );
// };

// MobileSearch.propTypes = {
//   value: PropTypes.string,
//   setValue: PropTypes.func,
//   popupState: PopupState,
// };

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = (): React.ReactElement => {
  const theme = useTheme();
  // const [value, setValue] = useState("")
  const [data, setData] = useState<UserProfile[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const { handleRequest } = useIntranetPortalCoreApi()

  const navigate = useNavigate();

  const getEmployeeData = async (): Promise<void> => {
    setIsLoading(true);
    await EmployeeService.getCoreApiApiAppEmployeeSearchEmployees(
      searchKeyword
    ).then((response) => {
      setIsLoading(false);
      setData(response as UserProfile[]);
    });
  };

  useEffect(() => {
    if (searchKeyword.length > 0) {
      void getEmployeeData();
    }
  }, [searchKeyword]);

  const loaderStyle = {
    width: "100%",
    maxWidth: 100,
    bgcolor: "transparent",
    align: "center",
  };

  // CLEAR
  const handleClear = (): void => {
    setSearchKeyword("");
    const input = document.getElementsByTagName("input");
    for (let i = 0; i < input.length; i++) {
      input[i].value = "";
    }
  };

  // const name = data?.map((item: any) => item?.name)

  return (
    <>
      <Box sx={{ display: "flex", position: "relative" }}>
        <OutlineInputStyle
          id="input-search-header"
          value={searchKeyword}
          onChange={(e: any) => {
            setSearchKeyword(e.target.value);
          }}
          autoComplete="off"
          placeholder="Search Employee"
          startAdornment={
            <InputAdornment position="start">
              <IconSearch
                stroke={1.5}
                size="1.4rem"
                color={theme.palette.grey[500]}
              />
            </InputAdornment>
          }
          endAdornment={
            searchKeyword.length > 0 && (
              <InputAdornment position="end">
                <ButtonBase sx={{ borderRadius: "12px" }} onClick={handleClear}>
                  <HighlightOffRoundedIcon />
                </ButtonBase>
              </InputAdornment>
            )
          }
          aria-describedby="search-helper-text"
          inputProps={{ "aria-label": "weight" }}
        />

        {/* Table for employee */}
        <Box
          sx={{ mt: 6.5, position: "absolute", width: "100%" }}
          component={Paper}
        >
          {isLoading ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress color="primary" sx={loaderStyle} />
            </Grid>
          ) : (
            <>
              {searchKeyword.length > 0 && data?.length > 0 ? (
                <List
                  sx={{
                    height: data.length > 1 ? "300px" : "auto",
                    overflow: "auto",
                  }}
                >
                  {data.map((row: any) => (
                    <ListItem
                      key={row.abpUserId}
                      onClick={() => {
                        navigate(
                          `/pages/employee-details?id=${
                            row?.abpUserId as string
                          }`
                        );
                        setSearchKeyword("");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <Avatar
                        src={`http://hr.amniltech.com/Uploads/${
                          row?.profilePictureUrl as string
                        }`}
                        sx={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          mr: 3,
                        }}
                      />
                      <ListItemText
                        primary={row.fullName}
                        secondary={`${row.designationName as string}, ${
                          row.departmentName as string
                        }`}
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                searchKeyword.length > 1 && (
                  <List>
                    <ListItem>
                      <ListItemText>No Employee Found</ListItemText>
                    </ListItem>
                  </List>
                )
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SearchSection;
