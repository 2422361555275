import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from "react-ga4"

const usePageTracking = (): void => {
  const location = useLocation()

  // const gaTrackingID = window.__RUNTIME_CONFIG__.VITE_GA_TRACKING_CODE

  useEffect(() => {
    // if (gaTrackingID) {
    // ReactGA.initialize(gaTrackingID, {
    //   gaOptions: {
    //     debug_mode: true,
    //   },
    //   gtagOptions: {
    //     debug_mode: true,
    //   },
    // })
    ReactGA.send({ hitType: "pageview", page: location.pathname })
    // }
  }, [location])
}

export default usePageTracking
