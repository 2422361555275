import { createSlice } from "@reduxjs/toolkit";

interface MoodState {
  myMoodIdValue: string;
}

const initialState: MoodState = {
  myMoodIdValue: "",
};

const moodSlice = createSlice({
  name: "mood",
  initialState,
  reducers: {
    handleSetMyMoodToday: (state, { payload }) => {
      state.myMoodIdValue = payload;
    },
  },
});

export default moodSlice.reducer;
export const { handleSetMyMoodToday } = moodSlice.actions;
