import dashboard from "./dashboard"
import pages from "./pages"
import configuration from "./configurations"
import { routeType } from "../routes/MainRoute"
// import other from './other';

// ==============================|| MENU ITEMS ||============================== //


const menuItems = {
  items: [dashboard, pages, configuration],
}

export default menuItems
