import { Suspense, lazy } from "react";

// project imports
import MainLayout from "../layout/MainLayout";
import Loadable from "../ui-component/Loadable";
import AcknowledgementAdminRequest from "../views/pages/acknowledgementAdminRequest";
import Departments from "../views/pages/department";
import Designations from "../views/pages/designation";
import Document from "../views/pages/document";
import AddDocument from "../views/pages/document/AddDocument";
import ReuploadDocument from "../views/pages/document/ReuploadDocument";
// import InternalApplication from "../views/pages/internalApplication"
import AddInternalApp from "../views/pages/internalApplication/AddInternalApp";
import DocumentRequestForMe from "../views/pages/RequestForMe";
import EmployeeDetails from "../views/pages/employeeDetails";
// import AccountSetting from "../views/pages/AccountSetting";
import AddPublicCalendar from "../views/configurations";
import AllEmployee from "../views/pages/allEmployee";
import MoodMonitor from "../views/pages/moodMonitor";
import AddMoodEmoji from "../views/pages/moodMonitor/AddMoodEmoji";
import PageNotFound from "../views/pages/pageNotFound";
import InternalApplication from "../views/pages/internalApplication";
import AdminAccess from "../utils/AdminAccess";
import type { TablerIcon } from "tabler-icons";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(async () => await import("../views/dashboard/Default"))
);

// utilities routing

// ==============================|| MAIN ROUTING ||============================== //

export interface childRouteType {
  breadcrumbs?: boolean;
  guardName: string;
  icon: TablerIcon;
  id: string;
  title: string;
  type: string;
  url: string;
}
export interface routeType {
  children: childRouteType[];
  id: string;
  title: string;
  type: string;
}

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: (
        <Suspense fallback="Loading...">
          <DashboardDefault />
        </Suspense>
      ),
    },
    {
      path: "pages",
      children: [
        {
          path: "internal-application",
          element: (
            <AdminAccess requiredRoles={"admin"}>
              <InternalApplication />
            </AdminAccess>
          ),
        },
      ],
    },
    {
      path: "pages",
      children: [
        {
          path: "add-internal-application",
          element: (
            <AdminAccess requiredRoles={"admin"}>
              <AddInternalApp />
            </AdminAccess>
          ),
        },
      ],
    },
    {
      path: "pages",
      children: [
        {
          path: "add-internal-application/:id",
          element: (
            <AdminAccess requiredRoles={"admin"}>
              <AddInternalApp />,
            </AdminAccess>
          ),
        },
      ],
    },
    {
      path: "pages",
      children: [
        {
          path: "mood-monitor",
          element: (
            <AdminAccess requiredRoles={"admin"}>
              <MoodMonitor />,
            </AdminAccess>
          ),
        },
      ],
    },
    {
      path: "pages",
      children: [
        {
          path: "add-mood-emoji/",
          element: (
            <AdminAccess requiredRoles={"admin"}>
              <AddMoodEmoji />,
            </AdminAccess>
          ),
        },
      ],
    },
    {
      path: "pages",
      children: [
        {
          path: "add-mood-emoji/:id",
          element: (
            <AdminAccess requiredRoles={"admin"}>
              <AddMoodEmoji />,
            </AdminAccess>
          ),
        },
      ],
    },
    {
      path: "pages",
      children: [
        {
          path: "document",
          element: (
            <AdminAccess requiredRoles={"admin"}>
              <Document />,
            </AdminAccess>
          ),
        },
      ],
    },
    {
      path: "pages",
      children: [
        {
          path: "add-document",
          element: (
            <AdminAccess requiredRoles={"admin"}>
              <AddDocument />,
            </AdminAccess>
          ),
        },
      ],
    },
    {
      path: "pages",
      children: [
        {
          path: "reupload-document",
          element: (
            <AdminAccess requiredRoles={"admin"}>
              <ReuploadDocument />,
            </AdminAccess>
          ),
        },
      ],
    },
    {
      path: "pages",
      children: [
        {
          path: "acknowledgement-request",
          element: (
            <AdminAccess requiredRoles={"admin"}>
              <AcknowledgementAdminRequest />,
            </AdminAccess>
          ),
        },
      ],
    },
    {
      path: "pages",
      children: [
        {
          path: "all-employee",
          element: <AllEmployee />,
        },
      ],
    },
    {
      path: "pages",
      children: [
        {
          path: "document-acknowledgement-request-for-me",
          element: <DocumentRequestForMe />,
        },
      ],
    },
    {
      path: "pages",
      children: [
        {
          path: "departments",
          element: <Departments />,
        },
      ],
    },
    {
      path: "pages",
      children: [
        {
          path: "designations",
          element: <Designations />,
        },
      ],
    },
    {
      path: "pages",
      children: [
        {
          path: "employee-details",
          element: <EmployeeDetails />,
        },
      ],
    },
    // {
    //   path: "profile",
    //   children: [
    //     {
    //       path: "account-setting",
    //       element: <AccountSetting />,
    //     },
    //   ],
    // },
    {
      path: "configurations",
      children: [
        {
          path: "public-calendar",
          element: (
            <Suspense fallback="Loading...">
              <AdminAccess requiredRoles={"admin"}>
                <AddPublicCalendar />,
              </AdminAccess>
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
    {
      path: "pages/",
      element: <PageNotFound />,
    },
    {
      path: "configurations/",
      element: <PageNotFound />,
    },
  ],
};

export default MainRoutes;
