// import Head from 'next/head';
import {
  useState,
  useEffect,
  useMemo,
  type ChangeEvent,
  type MouseEvent,
} from "react";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import {
  Box,
  CircularProgress,
  TableContainer,
  type SelectChangeEvent,
  Popover,
  Card,
  CardContent,
  Chip,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";
import TuneIcon from "@mui/icons-material/Tune";
import Paper from "@mui/material/Paper";
import {
  AbpApplicationConfigurationService,
  DocumentService,
  type IntranetPortal_Documents_Dtos_DocumentStatusOutputDto,
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_DocumentDto_IntranetPortal_Application_Contracts,
} from "../../../services/intranet-portal-services";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import SelectOption from "../../../ui-component/CustomUiComponent/SelectOption";
import CustomTypography from "../../../ui-component/CustomUiComponent/Typography";
import CustomButton from "../../../ui-component/CustomUiComponent/Button";
import DatePickerCustom from "../../../ui-component/CustomUiComponent/DatePicker";
import CustomTable from "../../../ui-component/CustomUiComponent/TableContainer";
import moment from "moment";
import CustomPagination from "../../../ui-component/CustomUiComponent/Pagination";
import DeleteConfirmationModal from "../../../ui-component/CustomUiComponent/DeleteConfirmationModal";
import Switch from "@mui/material/Switch";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem } from "@mui/material";
import useIntranetPortalCoreApi from "../../../hooks/useIntranetPortalAdminApi";
import EmployeeModal from "./EmployeeModal";
import { selectToken } from "../../utilities/auth/authSlice";
import { type Row } from "react-table";
import { useSelector } from "react-redux";
import usePageTracking from "../../../utils/withPageTracking";
import { CgArrowsExpandUpRight } from "react-icons/cg";
// const style = {
//   position: "absolute" as const,
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxshadow: 24,
//   p: 4,
// }

const loaderStyle = {
  width: "100%",
  maxWidth: 360,
  bgcolor: "background.paper",
  align: "center",
};

const cursorStyle = {
  cursor: "pointer",
};

const Document = (): React.ReactElement => {
  const { handleRequest, isLoading } = useIntranetPortalCoreApi();
  const token = useSelector(selectToken);

  const [searchKeywordforDocument, setSearchKeywordforDocument] = useState("");
  const [searchKeywordforEmployee] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [acknowledgedFromDate] = useState("");
  const [acknowledgedToDate] = useState("");
  const [documentStatusId, setDocumentStatusId] = useState<any>(null);
  const [sorting, setSorting] = useState("asc");
  const [skipCountForDocument, setSkipCountForDocument] = useState(0);
  const [skipCountForEmployee] = useState(0);
  const [maxResultCountForDocument, setMaxResultCountForDocument] =
    useState(10);
  const [maxResultCountForEmployees] = useState(1000);
  const [data, setData] =
    useState<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_DocumentDto_IntranetPortal_Application_Contracts>(
      {}
    );
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = useState(0);
  const [getStatusResponse, setStatusResponse] = useState<
    IntranetPortal_Documents_Dtos_DocumentStatusOutputDto[]
  >([]);
  const userPermission = useSelector((state: any) => state?.user?.userRole);
  const [permission, setPermission] = useState<any>();
  // Employee
  const [documentId, setDocumentId] = useState<any>();
  const [departmentId] = useState<any>();
  const [designationId] = useState<any>();
  const [employeeData, setEmployeeData] = useState<any>();
  const [selected, setSelected] = useState<string[]>([]);
  // const [urls, setUrls] = useState<any>()

  // DELETE CONFIRMATION MODAL
  const handleOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  const [openDialog, setOpenDialog] = useState(false);

  // EMPLOYEE SELECT MODAL
  const handleEmployeeSelectOpen = (): void => {
    setOpenDialog(true);
  };
  const handleEmployeeSelectClose = (): void => {
    setOpenDialog(false);
  };

  const navigate = useNavigate();
  usePageTracking();
  // const getPermission = async (): Promise<void> => {
  //   await AbpApplicationConfigurationService.getCoreApiApiAbpApplicationConfiguration().then(
  //     (response) => {
  //       // Check if response and currentUser exist before accessing roles
  //       const firstRole = response?.currentUser?.roles?.[0] ?? undefined
  //       setPermission(firstRole)
  //     }
  //   )
  // }

  const columns = [
    {
      Header: "S.No",
      Cell: ({ row }: { row: Row }) => {
        return <>{skipCountForDocument + row.index + 1}</>;
      },
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }: any) => {
        return (
          <>
            {row.original.documentStatusDisplayName === "Active" ? (
              <Box
                sx={{
                  cursor: "pointer",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "flex-end",
                  transition: "all 0.4s ease-in-out",
                  "&:hover": {
                    color: "#d82d2b",
                  },
                }}
                title="Assign document to employee"
                onClick={() => {
                  handleEmployeeSelect(row.original.id);
                }}
              >
                {row.original.name}
                <InputAdornment
                  position="end"
                  sx={{
                    ml: "1.2rem",
                    mb: "1.1rem",
                    color: "#d82d2b",
                  }}
                >
                  <CgArrowsExpandUpRight />
                </InputAdornment>
              </Box>
            ) : (
              <span>{row.original.name}</span>
            )}
          </>
        );
      },
    },
    {
      Header: "Uploaded Date",
      accessor: "creationTime",
      Cell: ({ row }: any) => {
        const date = moment(row.original.creationTime).format("YYYY-MM-DD");
        return date;
      },
      hidden: true,
    },
    {
      Header: "Description",
      accessor: "description",
      hidden: true,
    },
    {
      Header: "Acknowledgement Deadline",
      accessor: "acknowledgementDeadlineInDays",
      Cell: ({ row }: any) => {
        const deadlineDate = moment(row.original.creationTime).add(
          row.original.acknowledgementDeadlineInDays,
          "days"
        );
        return deadlineDate.format("YYYY-MM-DD");
      },
    },
    {
      Header: "Created By",
      accessor: "createdByFullName",
      Cell: ({ row }: any) => {
        return row.original.createdByFullName === null
          ? "-"
          : row.original.createdByFullName;
      },
      hidden: true,
    },
    {
      Header: "Modified By",
      accessor: "lastModifiedByFullName",
      Cell: ({ row }: any) => {
        return row.original.lastModifiedByFullName === null
          ? "-"
          : row.original.lastModifiedByFullName;
      },
      hidden: true,
    },
    {
      Header: "Status",
      accessor: "documentStatusDisplayName",
      Cell: ({ row }: any) => {
        return (
          <Chip
            size="small"
            label={row.original.documentStatusDisplayName}
            sx={{
              fontSize: "1.2rem",
              background:
                row.original.documentStatusDisplayName === "Active"
                  ? "#13A83D"
                  : "#D42C2C",
              color: "white",
            }}
          />
        );
      },
    },
    {
      Header: "Toggle",
      accessor: "toggle",
      Cell: ({ row }: any) => {
        return (
          <>
            {/* {isAuthenticated && permission == "admin" && ( */}
            <Switch
              color="primary"
              checked={row?.original?.status === "Active"}
              onClick={() => {
                void handleToggle(row.original.id);
              }}
            />
            {/* )} */}
          </>
        );
      },
    },
    {
      Header: "Action",
      Cell: ({ row }: any) => {
        return (
          <>
            {permission === "admin" && (
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <>
                    <MoreVertIcon {...bindTrigger(popupState)} />
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() => {
                          navigate(
                            `/pages/add-document?id=${
                              row?.original?.id as string
                            }`
                          );
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleOpen();
                          setDeleteId(row.original.id);
                        }}
                      >
                        Delete
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate(
                            `/pages/reupload-document?id=${
                              row?.original?.id as string
                            }`
                          );
                          handleOpen();
                        }}
                      >
                        Reupload
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          void handleDownload(
                            row.original.id,
                            row.original.documentUrl
                          );
                          popupState.close();
                        }}
                      >
                        Download
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </PopupState>
            )}
          </>
        );
      },
    },
  ];

  const employeeColumn = [
    {
      title: (
        <Typography fontSize={"1.4rem"} color="#000000">
          Name
        </Typography>
      ),
      field: "fullName",
      filterPlaceholder: "Filter by name",
    },
    {
      title: (
        <Typography fontSize={"1.4rem"} color="#000000">
          Designation
        </Typography>
      ),
      field: "designation",
      filterPlaceholder: "Filter by designation",
    },
    {
      title: (
        <Typography fontSize={"1.4rem"} color="#000000">
          Department
        </Typography>
      ),
      field: "department",
      filterPlaceholder: "Filter by department",
    },
    {
      title: (
        <Typography fontSize={"1.4rem"} color="#000000">
          Email
        </Typography>
      ),
      field: "email",
      filterPlaceholder: "Filter by email",
    },
    // {
    //   title: (
    //     <Typography fontSize={"1.4rem"} color="#000000">
    //       Phone
    //     </Typography>
    //   ),
    //   field: "phoneNumber",
    //   type: "numeric",
    // },
  ];

  // GET DOCUMENT DATA
  const getDocumentData = async (): Promise<void> => {
    await handleRequest(
      DocumentService.getCoreApiApiAppDocumentPagedAndSortedDocumentList(
        searchKeywordforDocument,
        documentStatusId,
        fromDate,
        toDate,
        acknowledgedFromDate,
        acknowledgedToDate,
        sortType,
        sorting,
        skipCountForDocument,
        maxResultCountForDocument
      )
    ).then((response) => {
      if (response != null) {
        setData({
          ...response,
          items: response.items?.map((data) => ({
            ...data,
            status: data?.documentStatusDisplayName,
          })),
        });
      }
    });
  };

  // GET EMPLOYEE DATA
  const getEmployeeAcknowledgementRequestData = async (): Promise<void> => {
    await handleRequest(
      DocumentService.getCoreApiApiAppDocumentEmployeesForDocumentAcknowledgementRequest(
        searchKeywordforEmployee,
        documentId,
        fromDate,
        toDate,
        sortType,
        departmentId,
        designationId,
        sorting,
        skipCountForEmployee,
        maxResultCountForEmployees
      )
    ).then((response) => {
      if (response != null) {
        setEmployeeData(response?.items);
      }
    });
  };

  /* SEND REQUEST TO EMPLOYEE */
  const handleGroupingSubmit = async (): Promise<void> => {
    const payload = {
      documentId,
      acknowledgmentRequestedToIds: selected,
    };
    await handleRequest(
      DocumentService.postCoreApiApiAppDocumentRequestAcknowledgementForDocument(
        payload
      )
    ).then(async (response) => {
      if (response != null) {
        await getEmployeeAcknowledgementRequestData();
        toast.success(response.message);
      }
    });
  };

  // GET STATUS OF DOCUMENT
  const getStatus = async (): Promise<void> => {
    await handleRequest(
      DocumentService.getCoreApiApiAppDocumentDocumentStatuses()
    ).then((response) => {
      if (response != null) {
        setStatusResponse(response);
      }
    });
  };

  const handleDownload = async (val: any, fileUrl: string): Promise<void> => {
    try {
      const response = await fetch(
        `${window.__RUNTIME_CONFIG__.VITE_BASE_URL}/core-api/documents/file/${
          val as string
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token as string}`,
            "Content-Type": "application/octet-stream",
          },
        }
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${fileUrl}`;
      document.body.appendChild(a);
      a.click();
    } catch (e: any) {
      if (e?.body?.error?.validationErrors != null) {
        toast.error(e?.body?.error?.validationErrors);
      } else if (e.message != null) {
        toast.error(e.message);
      } else {
        toast.error("Unable to fetch api");
      }
    }
  };

  // HANDLE TOGGLE
  const handleToggle = async (selectedId: string): Promise<void> => {
    const checkid: any = data?.items?.find((item) => item?.id === selectedId);

    checkid.documentStatusDisplayName === "InActive"
      ? await DocumentService.postCoreApiApiAppDocumentActivateDocument(
          checkid.id
        )
      : await DocumentService.postCoreApiApiAppDocumentDeactivateDocument(
          checkid.id
        );
    await getDocumentData();
  };

  // DELETE
  const handleDelete = async (): Promise<void> => {
    await handleRequest(
      DocumentService.deleteCoreApiApiAppDocumentDocument(deleteId)
    ).then(async (response) => {
      if (response != null) {
        await getDocumentData();
        handleClose();
        toast.success("Deleted Successfully");
      }
    });
  };

  useEffect(() => {
    if (data !== null) {
      setPermission(userPermission?.currentUser?.roles[0]);
    }
  }, []);

  useEffect(() => {
    void getDocumentData();
    void getStatus();
    void getEmployeeAcknowledgementRequestData();
  }, [
    documentId,
    searchKeywordforDocument,
    documentStatusId,
    fromDate,
    toDate,
    acknowledgedFromDate,
    acknowledgedToDate,
    sortType,
    sorting,
    skipCountForDocument,
    skipCountForEmployee,
    maxResultCountForDocument,
    maxResultCountForEmployees,
    departmentId,
    designationId,
    page,
  ]);

  const putInDocumentTableFirstPage = () => {
    setPage(0);
    setSkipCountForDocument(0);
  };

  // SELECT EMPLOYEE
  const handleEmployeeSelect = (id: any): void => {
    setDocumentId(id);
    handleEmployeeSelectOpen();
  };

  // HANDLE ORDER
  const handleOrder = (id: string): void => {
    sortType === "asc" ? setSortType("desc") : setSortType("asc");
    setSorting(id);
  };

  // SEARCH
  const onSearchTermInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchKeywordforDocument(event.target.value);
    putInDocumentTableFirstPage();
  };
  const debouncedChangeHandler = useMemo(
    () => debounce(onSearchTermInput, 500),
    []
  );

  const onSearchFromDate = (fromDate: any): void => {
    setFromDate(moment(fromDate.$d).format("YYYY-MM-DD"));
    setPage(0);
    setSkipCountForDocument(0);
  };

  const debouncedFromDateChangeHandler = useMemo(
    () => debounce(onSearchFromDate, 500),
    []
  );

  const onSearchToDate = (date: any): void => {
    setToDate(moment(date.$d).format("YYYY-MM-DD"));
    setPage(0);
    setSkipCountForDocument(0);
  };

  const debouncedToDateChangeHandler = useMemo(
    () => debounce(onSearchToDate, 500),
    []
  );

  // PAGINATION
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setMaxResultCountForDocument(Number(event.target.value));
    setSkipCountForDocument(0);
    setPage(0);
  };

  const handlePageChange = (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number
  ): void => {
    setSkipCountForDocument(maxResultCountForDocument * page);
    setPage(page);
  };

  // CLEAR
  const handleSearchClear = (): void => {
    setFromDate("");
    setToDate("");
    setSearchKeywordforDocument("");
    setDocumentStatusId(null);
    const input = document.getElementsByTagName("input");
    for (let i = 0; i < input.length; i++) {
      input[i].value = "";
    }
  };

  // filter popover
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (event: any): void => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  return (
    <>
      <Box>
        <Grid container spacing={2} mb={2} alignItems={"center"}>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              variant="h5"
              label={"Documents"}
              sx={{ fontWeight: "500", color: "#D42C2C" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "1rem",
              }}
            >
              <CustomButton
                label={"Add Document"}
                variant={"contained"}
                onClick={() => {
                  navigate("/pages/add-document");
                }}
                startIcon={<AddIcon fontSize="small" />}
                color="error"
                sx={{ height: "100%" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container gap={2} sx={{ py: "1rem" }}>
          <Grid item xs={12} sm={5} lg={4}>
            <TextField
              label="Search"
              fullWidth
              size="small"
              id="filled-basic"
              // placeholder="Search"
              onChange={debouncedChangeHandler}
            />
          </Grid>
          <Grid item xs={12} sm={5} lg={3}>
            <SelectOption
              label="Search Status"
              name="documentStatusIds"
              variant={"outlined"}
              value={documentStatusId ?? ""}
              onChange={(event: SelectChangeEvent<string>) => {
                setDocumentStatusId(event.target.value);
                setPage(0);
                setSkipCountForDocument(0);
              }}
              options={getStatusResponse}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={4} gap={2}>
            <CustomButton
              label={"Filter"}
              variant={"contained"}
              aria-describedby={id}
              onClick={handlePopoverClick}
              startIcon={<TuneIcon fontSize="small" />}
              color="error"
              sx={{ height: "100%" }}
              title="With Uploaded Date"
            />
            <Popover
              id={id}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Card>
                <CardContent sx={{ p: 2 }} title="With Uploaded Date">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.8rem",
                    }}
                  >
                    <DatePickerCustom
                      label={"Uploaded From Date"}
                      selected={fromDate}
                      onChange={debouncedFromDateChangeHandler}
                      maxDate
                    />
                    <DatePickerCustom
                      label={"Uploaded To Date"}
                      selected={toDate}
                      onChange={debouncedToDateChangeHandler}
                      minDate={fromDate}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Popover>
            <CustomButton
              sx={{ ml: 2, height: "100%" }}
              label={"Clear"}
              variant={"contained"}
              onClick={handleSearchClear}
              color="error"
            />
          </Grid>
        </Grid>
      </Box>

      <Card sx={{ mb: "1rem" }} elevation={1}>
        <CardContent sx={{ p: 2 }}>
          <Box component={Paper}>
            <TableContainer component={Paper} sx={{ height: 550 }}>
              {isLoading ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress color="primary" sx={loaderStyle} />
                </Grid>
              ) : (
                <>
                  {data?.items && data?.items?.length > 0 ? (
                    <CustomTable
                      columns={columns}
                      data={data?.items}
                      handleSort={handleOrder}
                    />
                  ) : (
                    <span style={{ alignContent: "center" }}>
                      No Record Available
                    </span>
                  )}
                </>
              )}
            </TableContainer>
            {data?.items && data?.items.length > 0 && (
              <CustomPagination
                component={"div"}
                count={Number(data?.totalCount)}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={Number(page && page)}
                rowsPerPage={Number(maxResultCountForDocument)}
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
              />
            )}
          </Box>
        </CardContent>
      </Card>

      <DeleteConfirmationModal
        open={open}
        onHide={handleClose}
        onDelete={handleDelete}
      />

      {openDialog && (
        <EmployeeModal
          open={handleEmployeeSelectOpen}
          onHide={handleEmployeeSelectClose}
          column={employeeColumn}
          employeeData={employeeData}
          groupSubmit={handleGroupingSubmit}
          getEmployee={getEmployeeAcknowledgementRequestData}
          selectedEmployee={setSelected}
        />
      )}
    </>
  );
};

export default Document;
