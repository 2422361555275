import PropTypes from "prop-types";

// material-ui
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project imports
import LogoSection from "../LogoSection";
import ProfileSection from "./ProfileSection";
import SearchSection from "./SearchSection";
// import NotificationSection from './NotificationSection';

// assets
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { IconMenu2 } from "@tabler/icons";
import moment from "moment";
import { useEffect, useRef } from "react";
import Bot from "../../../bot/components/Bot";
import Clock from "./Clock/clock";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }: any): React.ReactElement => {
  const theme = useTheme();

  return (
    <>
      {/* logo & toggler button */}
      {/* <h1>{todayDate}</h1> */}

      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>
        <IconButton color="error" onClick={handleLeftDrawerToggle} size="small">
          <IconMenu2 fontSize="inherit" />
        </IconButton>
      </Box>

      {/* header search */}
      <SearchSection />
      <Box sx={{ flexGrow: 1 }}> </Box>
      {/* notification & profile */}
      {/* <NotificationSection /> */}
      {/* <button role="button" className="askAmwise"><QuestionAnswerIcon sx={{mr:1}} /> Ask Amwise</button> */}

      {window?.__RUNTIME_CONFIG__.ENABLE_AMWISE == "true" ? (
        <Box sx={{ flexGrow: 0.03 }}>
          <Bot
            botTitle="Ask AMWISE"
            subTitle=" Hi, I am AMWISE, your dedicated internal Chat GPT. I will promptly address inquiries regarding our ISMS and HR policies. I will help deliver precise guidance on compliance, data security, and best practices, ensuring a seamless work environment within our organization."
            themeColor="#d42c2b"
            clientId={
              window.__RUNTIME_CONFIG__.CHATWITHDOCS_BASIC_AUTH_CLIENTID ?? ""
            }
            clientSecret={
              window.__RUNTIME_CONFIG__.CHATWITHDOCS_BASIC_AUTH_CLIENTSECRET ??
              ""
            }
            projectId={window.__RUNTIME_CONFIG__.CHATWITHDOCS_PROJECTID ?? ""}
          />
        </Box>
      ) : null}

      <Clock />

      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
