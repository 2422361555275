import React, { useCallback, useEffect, useState } from "react";
import useQuery from "../../../hooks/useQuery";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import useIntranetPortalCoreApi from "../../../hooks/useIntranetPortalAdminApi";
import { toast } from "react-toastify";
import { EmployeeMoodService } from "../../../services/intranet-portal-services";
import { useDropzone } from "react-dropzone";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import * as Yup from "yup";
import SelectOption from "../../../ui-component/CustomUiComponent/SelectOption";
import AnimateButton from "../../../ui-component/extended/AnimateButton";
import { Navigate, useNavigate } from "react-router-dom";

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required("Name is required *"),
  description: Yup.string()
    .required("Description is required *")
    .max(50, "Description should not exceed 50 characters."),
  isActive: Yup.string().required("Status is required *"),
});

const AddMoodEmoji = (): React.ReactElement => {
  const { handleRequest } = useIntranetPortalCoreApi();
  const navigate = useNavigate();
  const [addIconResponseUrl, setAddIconResponseUrl] = useState<any>();
  const [formEditData, setFormEditData] = useState<any>();
  const [files, setFiles] = useState<any>([]);
  const theme = useTheme<any>();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const params = useQuery();
  const id: string | null = params.get("id");

  const initialValues = {
    name: "",
    iconUrl: "",
    description: "",
    isActive: "",
  };

  const logoInitialState = {
    logo: "",
  };

  const statusOptions = [
    { id: "Active", name: "Active" },
    { id: "InActive", name: "InActive" },
  ];

  const handleMoodLogo = async (val: any): Promise<void> => {
    const formDataLogo: any = {
      logo: val,
    };
    await handleRequest(
      EmployeeMoodService.postCoreApiApiAppEmployeeMoodUploadMoodLogo(
        formDataLogo
      )
    ).then((response) => {
      if (response !== null) {
        setAddIconResponseUrl(response);
        toast.success("Logo uploaded successfully");
      }
    });
  };

  const handelDropMoodLogo = async (val: any): Promise<void> => {
    const formDataLogo: any = {
      logo: val,
    };
    await handleRequest(
      EmployeeMoodService.postCoreApiApiAppEmployeeMoodUploadMoodLogo(
        formDataLogo
      )
    ).then((response) => {
      if (response !== null) {
        setAddIconResponseUrl(response);
        toast.success("Logo uploaded successfully");
      }
    });
  };

  const handleFormSubmit = async (val: any): Promise<void> => {
    const formIconUrlValue = (): string => {
      if (id !== null && addIconResponseUrl !== undefined) {
        return addIconResponseUrl;
      }
      if (id === null) {
        return addIconResponseUrl;
      } else {
        return val.iconUrl;
      }
    };

    const formData: any = {
      name: val.name,
      iconUrl: formIconUrlValue(),
      description: val.description,
      isActive: val.isActive === "InActive" ? false : true,
    };
    if (id !== null) {
      try {
        const editEmojiResp =
          await EmployeeMoodService.putCoreApiApiAppEmployeeMoodMood(
            id,
            formData
          );
        editEmojiResp && toast.success(`${formData.name} updated successfully`);
        navigate(-1);
      } catch (e: any) {
        if (e.status) {
          toast.error(e.status);
        } else if (e?.body?.error?.validationErrors) {
          toast.error(e?.body?.error?.validationErrors);
        } else if (e.message) {
          toast.error(e.message);
        } else {
          toast.error("Unable to fetch api");
        }
      }
    } else {
      try {
        const addMoodEmojiResp =
          await EmployeeMoodService.postCoreApiApiAppEmployeeMoodMood(formData);
        addMoodEmojiResp &&
          toast.success(`${formData.name} added successfully`);
        navigate(-1);
      } catch (e: any) {
        if (e.status) {
          toast.error(e.status);
        } else if (e?.body?.error?.validationErrors) {
          toast.error(e?.body?.error?.validationErrors);
        } else if (e.message) {
          toast.error(e.message);
        } else {
          toast.error("Unable to fetch api");
        }
      }
    }
  };

  // api calling
  const fetchEditData = async (editId: string): Promise<void> => {
    const resp = await handleRequest(
      EmployeeMoodService.getCoreApiApiAppEmployeeMoodMoodById(editId)
    );
    !!resp && setFormEditData(resp);
  };

  useEffect(() => {
    if (id !== null) {
      void fetchEditData(id);
    }
  }, []);

  const onDrop = useCallback((acceptedFiles: any) => {
    const logo = acceptedFiles;
    void handelDropMoodLogo(logo);
    if (acceptedFiles?.length > 0) {
      setFiles((previousFiles: any) => [
        // ...previousFiles,
        ...acceptedFiles.map((file: any) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        ),
      ]);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    },
    maxSize: 1024 * 1000,
  });
  const removeFile = (name: string): void => {
    setFiles((files: any) => files.filter((file: any) => file.name !== name));
  };

  const Dropzone = styled("div")({
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "2px",
    borderRadius: "2px",
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    "&:hover": {
      borderColor: "#c62828",
    },
  });

  let iconUrlValue = !!addIconResponseUrl
    ? addIconResponseUrl
    : formEditData?.iconUrl;

  return (
    <>
      <Box>
        <Grid container spacing={2} mb={2} alignItems={"center"}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: 500, color: "#D42C2C" }}>
              {id !== null ? "Update" : "Add"} Mood Emoji
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Formik
              initialValues={logoInitialState}
              onSubmit={handleMoodLogo}
              enableReinitialize={true}
            >
              {({ setFieldValue }) => (
                <>
                  <Form>
                    <div {...getRootProps()}>
                      <Dropzone>
                        <input {...getInputProps()} />
                        {/* {isDragActive ? (
                          <p>Drop the files here ...</p>
                        ) : ( */}
                        <p>click to select files</p>
                        {/* )} */}
                      </Dropzone>
                      <FormHelperText
                        variant="outlined"
                        sx={{ fontSize: "1rem" }}
                        error
                        id="standard-weight-helper-text--register"
                      >
                        {!iconUrlValue && "Please, select a file *"}
                      </FormHelperText>
                      <div>
                        <>
                          {!!id && files.length <= 0 ? (
                            <>
                              <ImageList sx={{}} cols={8}>
                                <ImageListItem>
                                  <img
                                    src={`${window.__RUNTIME_CONFIG__.VITE_BASE_URL}/core-api/Mood-logos/file/${formEditData?.id}/uncached`}
                                    width={100}
                                    height={100}
                                    loading="lazy"
                                  />
                                  <ImageListItemBar
                                    sx={{
                                      background:
                                        "linear-gradient(to top, rgba(0,0,0,0.7) 0%, " +
                                        "rgba(0,0,0,0.6) 70%, rgba(0,0,0,0) 100%)",
                                    }}
                                    position="bottom"
                                    actionIcon={
                                      <IconButton sx={{ color: "white" }}>
                                        <HighlightOffRoundedIcon
                                          sx={{ fontSize: "24px" }}
                                        />
                                      </IconButton>
                                    }
                                    actionPosition="right"
                                    onClick={() => (iconUrlValue = "")}
                                  />
                                </ImageListItem>
                              </ImageList>
                            </>
                          ) : (
                            <ImageList /* sx={{}} */ cols={8}>
                              {files?.map((file: any) => (
                                <ImageListItem key={file.name}>
                                  <img
                                    src={file.preview}
                                    alt={file.name}
                                    width={100}
                                    height={100}
                                    onLoad={() => {
                                      URL.revokeObjectURL(file.preview);
                                    }}
                                    loading="lazy"
                                  />
                                  <ImageListItemBar
                                    sx={{
                                      background:
                                        "linear-gradient(to top, rgba(0,0,0,0.7) 0%, " +
                                        "rgba(0,0,0,0.6) 70%, rgba(0,0,0,0) 100%)",
                                    }}
                                    position="bottom"
                                    actionIcon={
                                      <IconButton sx={{ color: "white" }}>
                                        <HighlightOffRoundedIcon
                                          sx={{ fontSize: "24px" }}
                                        />
                                      </IconButton>
                                    }
                                    actionPosition="right"
                                    onClick={() => void removeFile(file.name)}
                                  />
                                </ImageListItem>
                              ))}
                            </ImageList>
                          )}
                        </>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>

            {/* post of mood emoji */}

            <Formik
              initialValues={
                id !== null
                  ? {
                      name: formEditData?.name ?? "",
                      iconUrl:
                        (formEditData?.iconUrl && formEditData?.iconUrl) ??
                        (addIconResponseUrl && addIconResponseUrl),
                      description: formEditData?.description ?? "",
                      isActive:
                        formEditData?.isActive === true
                          ? "Active"
                          : "InActive" || "",
                    }
                  : initialValues
              }
              onSubmit={handleFormSubmit}
              enableReinitialize={id ? true : false}
              validationSchema={FORM_VALIDATION}
            >
              {({ handleChange, values, errors, setFieldValue }) => (
                <Form noValidate>
                  <Grid container spacing={matchDownSM ? 0 : 2} marginY={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Name"
                        margin="none"
                        name="name"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        autoComplete="off"
                        variant="outlined"
                      />
                      <FormHelperText
                        variant="outlined"
                        sx={{ fontSize: "1rem" }}
                        error
                        id="standard-weight-helper-text--register"
                      >
                        <ErrorMessage name="name" />
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectOption
                        label="Status"
                        name="isActive"
                        value={values.isActive}
                        onChange={handleChange}
                        options={statusOptions}
                        placeholder="Status"
                        // setFieldValue={setFieldValue}
                      />
                      <FormHelperText
                        variant="outlined"
                        sx={{ fontSize: "1rem" }}
                        error
                        id="standard-weight-helper-text--register"
                      >
                        <ErrorMessage name="isActive" />
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Description"
                        margin="none"
                        name="description"
                        type="text"
                        value={values.description}
                        onChange={handleChange}
                        autoComplete="off"
                        variant="outlined"
                      />
                      <FormHelperText
                        variant="outlined"
                        sx={{ fontSize: "1rem" }}
                        error
                        id="standard-weight-helper-text--register"
                      >
                        <ErrorMessage name="description" />
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        style={{ display: "none" }}
                        fullWidth
                        label="Icon Url"
                        margin="none"
                        name="iconUrl"
                        type="text"
                        value={iconUrlValue}
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 2 }}>
                    <AnimateButton>
                      <Button
                        disableElevation
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="error"
                      >
                        {id !== null ? "Update" : "Add"}
                      </Button>
                    </AnimateButton>
                  </Box>
                </Form>
              )}
            </Formik>
            <AnimateButton>
              <Button
                sx={{ mt: 2 }}
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="outlined"
                color="error"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back To the list
              </Button>
            </AnimateButton>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default AddMoodEmoji;
