// assets
import {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconBriefcase,
  IconUserPlus,
  IconUsers,
  IconUserCircle,
  IconBuilding,
  IconHome2,
  IconMoodConfuzed,
} from "@tabler/icons";
// constant
const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconBriefcase,
  IconUserPlus,
  IconUsers,
  IconUserCircle,
  IconBuilding,
  IconHome2,
  IconMoodConfuzed,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const pages = {
  id: "pages",
  title: "pages",
  type: "group",
  children: [
    {
      id: "internal-application",
      title: "Internal App",
      type: "item",
      url: "/pages/internal-application",
      icon: icons.IconHome2,
      breadcrumbs: false,
      guardName: "InternalApplicationAdmin",
    },
    {
      id: "mood-monitor",
      title: "Mood Monitor",
      type: "item",
      url: "/pages/mood-monitor",
      icon: icons.IconMoodConfuzed,
      breadcrumbs: false,
      guardName: "MoodAdmin",
    },
    {
      id: "document",
      title: "Documents",
      type: "item",
      url: "/pages/document",
      icon: icons.IconBriefcase,
      breadcrumbs: false,
      guardName: "DocumentAdmin",
    },
    {
      id: "acknowledgement-request",
      title: "All Document Requests",
      type: "item",
      url: "/pages/acknowledgement-request",
      icon: icons.IconUserPlus,
      breadcrumbs: false,
      guardName: "DocumentAcknowledgementRequestAdmin",
    },
    {
      id: "all-employee",
      title: "All Employee ",
      type: "item",
      url: "/pages/all-employee",
      icon: icons.IconUsers,
      breadcumbs: false,
      guardName: "AllEmployee",
    },

    {
      id: "departments",
      title: "Departments",
      type: "item",
      url: "/pages/departments",
      icon: icons.IconBuilding,
      breadcrumbs: false,
      guardName: "Departments",
    },
    {
      id: "designations",
      title: "Designations",
      type: "item",
      url: "/pages/designations",
      icon: icons.IconUserCircle,
      breadcrumbs: false,
      guardName: "Designations",
    },
    {
      id: "document-acknowledgement-request-for-me",
      title: "Request For Me",
      type: "item",
      url: "/pages/document-acknowledgement-request-for-me",
      icon: icons.IconUserCircle,
      breadcrumbs: false,
      guardName: "RequestForMe",
    },
  ],
  //   userid:'pages',
  //     usertype: 'user',
  //     child: [
  //         {
  //             id: 'document-acknowledgement-request-for-me',
  //             title: 'Request For Me',
  //             type: 'item',
  //             url: '/pages/document-acknowledgement-request-for-me',
  //             icon: icons.IconUserCircle,
  //             breadcrumbs: false
  //         },
  //     ]
};

// const item = utilities?.children

export default pages;
