// import Head from 'next/head';
import { useState, useEffect, useMemo, type ChangeEvent } from "react";
import debounce from "lodash.debounce";
import {
  Box,
  CircularProgress,
  TableContainer,
  Menu,
  MenuItem,
  type SelectChangeEvent,
  Popover,
  Card,
  CardContent,
  InputAdornment,
  ButtonBase,
  TextField,
  Chip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import {
  DocumentService,
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_DocumentAcknowledgementRequestsForMeDto_IntranetPortal_Application_Contracts,
  type IntranetPortal_Documents_Dtos_DocumentStatusOutputDto,
} from "../../../services/intranet-portal-services";
import Grid from "@mui/material/Grid";
import TuneIcon from "@mui/icons-material/Tune";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { toast } from "react-toastify";
import DatePickerCustom from "../../../ui-component/CustomUiComponent/DatePicker";
import moment from "moment";
import CustomButton from "../../../ui-component/CustomUiComponent/Button";
import CustomTable from "../../../ui-component/CustomUiComponent/TableContainer";
import CustomPagination from "../../../ui-component/CustomUiComponent/Pagination";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { selectToken } from "../../utilities/auth/authSlice";
import useIntranetPortalCoreApi from "../../../hooks/useIntranetPortalAdminApi";
import SelectOption from "../../../ui-component/CustomUiComponent/SelectOption";
import RequestViewModal from "./RequestViewModal";
import CustomTypography from "../../../ui-component/CustomUiComponent/Typography";
import { useSelector } from "react-redux";
import usePageTracking from "../../../utils/withPageTracking";

const loaderStyle = {
  width: "100%",
  maxWidth: 360,
  bgcolor: "background.paper",
  align: "center",
};

const DocumentRequestForMe = (): React.ReactElement => {
  // Event handling
  const { handleRequest, isLoading } = useIntranetPortalCoreApi();

  const [maxResultCount, setMaxResultCount] = useState(5);
  const [skipCount, setSkipCount] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [acknowledgedFromDate, setAcknowledgedFromDate] = useState("");
  const [acknowledgedToDate, setAcknowledgedToDate] = useState("");
  const [data, setData] =
    useState<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_DocumentAcknowledgementRequestsForMeDto_IntranetPortal_Application_Contracts>();
  const [sorting, setSorting] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [
    documentAcknowledgementRequestStatusId,
    setDocumentAcknowledgementRequestStatusId,
  ] = useState<any>(null);
  const [page, setPage] = useState(0);
  const token = useSelector(selectToken);
  const [getStatusResponse, setStatusResponse] = useState<
    IntranetPortal_Documents_Dtos_DocumentStatusOutputDto[]
  >([]);

  const [openAcknowledge, setOpenAcknowledge] = useState(false);

  // States For Acknowledge Modal
  const [rowData, setRowData] = useState<any>();
  const [urls, setUrls] = useState("");

  usePageTracking();

  const handleCloseAcknowledge = (): void => {
    setOpenAcknowledge(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row }: any) => {
          return <>{Number(row.index) + 1}</>;
        },
      },
      {
        accessor: "documentName",
        Header: "Document Name",
      },
      {
        accessor: "creationTime",
        Header: "Creation Time",
        Cell: ({ row }: any) => {
          const date = moment(row.original.creationTime).format("YYYY-MM-DD");
          return date === "Invalid date" ? "Document not acknowledged" : date;
        },
        hidden: true,
      },
      {
        accessor: "acknowledgementDoneDateTime",
        Header: "Acknowledgement Done Date",
        Cell: ({ row }: any) => {
          const date = moment(row.original.acknowledgementDoneDateTime).format(
            "YYYY-MM-DD"
          );
          return date === "Invalid date" ? "Document Not Acknowledged" : date;
        },
      },
      {
        accessor: "dueDateTime",
        Header: "Due Date Time",
        Cell: ({ row }: any) => {
          const date = moment(row.original.dueDateTime).format("YYYY-MM-DD");
          return date === "Invalid date" ? "Document not acknowledged" : date;
        },
        hidden: true,
      },
      {
        accessor: "createdByFullName",
        Header: "Created By",
        hidden: true,
      },
      {
        accessor: "documentAcknowledgementRequestStatusSystemName",
        Header: "Status",
        Cell: ({ row }: any) => {
          return (
            // <SeverityPill
            //   color={
            //     (row.original.documentAcknowledgementRequestStatusSystemName ===
            //       "New" &&
            //       "primary") ||
            //     (row.original.documentAcknowledgementRequestStatusSystemName ===
            //       "Revoked" &&
            //       "error") ||
            //     "success"
            //   }
            // >
            //   {row.original.documentAcknowledgementRequestStatusSystemName}
            // </SeverityPill>
            <Chip
              size="small"
              label={
                row.original.documentAcknowledgementRequestStatusSystemName
              }
              sx={{
                fontSize: "1.2rem",
                background:
                  row.original
                    .documentAcknowledgementRequestStatusSystemName === "New"
                    ? "#13A83D" // Green color for "New"
                    : row.original
                        .documentAcknowledgementRequestStatusSystemName ===
                      "Revoked"
                    ? "#D42C2C" // Red color for "Revoked"
                    : row.original
                        .documentAcknowledgementRequestStatusSystemName ===
                      "Acknowledged"
                    ? "orange" // Blue color for "Acknowledged"
                    : "",
                color: "white",
              }}
            />
          );
        },
      },
      {
        Header: "Action",
        Cell: ({ row }: any) => {
          return (
            <>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState: any) => (
                  <>
                    {row.original
                      .documentAcknowledgementRequestStatusSystemName ===
                    "Revoked" ? (
                      <MoreVertIcon />
                    ) : (
                      <>
                        <MoreVertIcon
                          {...bindTrigger(popupState)}
                          sx={{ cursor: "pointer" }}
                        />
                        <Menu {...bindMenu(popupState)}>
                          {row.original
                            .documentAcknowledgementRequestStatusSystemName ===
                            "New" && (
                            <MenuItem
                              onClick={() => {
                                void handleView(row.original.documentId);
                                setRowData(row.original);
                                setOpenAcknowledge(true);
                              }}
                            >
                              Read and Acknowledge
                            </MenuItem>
                          )}
                          {row.original
                            .documentAcknowledgementRequestStatusSystemName ===
                            "Acknowledged" && (
                            <MenuItem
                              onClick={() => {
                                void handleDownload(
                                  row.original.documentId,
                                  row.original.documentUrl
                                );
                                popupState.close();
                              }}
                            >
                              Download
                            </MenuItem>
                          )}
                        </Menu>
                      </>
                    )}
                  </>
                )}
              </PopupState>
            </>
          );
        },
      },
    ],
    []
  );

  // GET EMPLOYEE DATA
  const getEmployeeRequestForMeData = async (): Promise<void> => {
    await handleRequest(
      DocumentService.getCoreApiApiAppDocumentDocumentAcknowledgementRequestsForMe(
        searchKeyword,
        fromDate,
        toDate,
        acknowledgedFromDate,
        acknowledgedToDate,
        documentAcknowledgementRequestStatusId,
        sorting,
        "",
        skipCount,
        maxResultCount
      )
    ).then((response) => {
      if (response != null) {
        setData(response);
      }
    });
  };

  // GET STATUS OF DOCUMENT ACKNOWLEDGEMENT
  const getStatus = async (): Promise<void> => {
    await handleRequest(
      DocumentService.getCoreApiApiAppDocumentDocumentAcknowledgmentRequestStatuses()
    ).then(async (response) => {
      if (response != null) {
        setStatusResponse(response);
        await getEmployeeRequestForMeData(); // Await the function call here
      }
    });
  };

  // GET ACKNOWLEDGE DOCUMENTS
  const handleAcknowledgeDocument = async (val: any): Promise<void> => {
    await handleRequest(
      DocumentService.postCoreApiApiAppDocumentAcknowledgeDocumentRequest(val)
    ).then(async (response) => {
      if (response != null) {
        await getEmployeeRequestForMeData();
        toast.success("File Acknowledged successfully");
      }
    });
  };

  // HANDLE DOCUMENT DOWNLOAD
  const handleDownload = async (
    val: string,
    fileUrl: string
  ): Promise<void> => {
    try {
      const response = await fetch(
        `${window.__RUNTIME_CONFIG__.VITE_BASE_URL}/core-api/documents/file/${val}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token as string}`,
            "Content-Type": "application/octet-stream",
          },
        }
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      setUrls(url);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${fileUrl}`;
      document.body.appendChild(a);
      a.click();
    } catch (e: any) {
      if (e?.body?.error?.validationErrors != null) {
        toast.error(e?.body?.error?.validationErrors);
      } else if (e.message != null) {
        toast.error(e.message);
      } else {
        toast.error("Unable to fetch api");
      }
    }
  };

  const handleView = async (val: string): Promise<void> => {
    try {
      const response = await fetch(
        `${window.__RUNTIME_CONFIG__.VITE_BASE_URL}/core-api/documents/file/${val}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token as string}`,
            "Content-Type": "application/octet-stream",
          },
        }
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      setUrls(url);
    } catch (e: any) {
      if (e?.body?.error?.validationErrors != null) {
        toast.error(e?.body?.error?.validationErrors);
      } else if (e.message != null) {
        toast.error(e.message);
      } else {
        toast.error("Unable to fetch api");
      }
    }
  };

  useEffect(() => {
    void getEmployeeRequestForMeData();
    void getStatus();
  }, [
    searchKeyword,
    fromDate,
    toDate,
    acknowledgedFromDate,
    acknowledgedToDate,
    documentAcknowledgementRequestStatusId,
    sorting,
    skipCount,
    maxResultCount,
  ]);

  // SORTING
  const handleOrder = (sortOrder: any, id: string): void => {
    sortType === "asc" ? setSortType("desc") : setSortType("asc");
    setSorting(id);
  };

  // SEARCH
  const onSearchTermInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchKeyword(event.target.value);
    setSkipCount(0);
    setPage(0);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(onSearchTermInput, 500),
    []
  );

  const onSearchFromDate = (fromDate: any): void => {
    setFromDate(moment(fromDate.$d).format("YYYY-MM-DD"));
    setPage(0);
  };

  const debouncedFromDateChangeHandler = useMemo(
    () => debounce(onSearchFromDate, 500),
    []
  );

  const onSearchToDate = (toDate: any): void => {
    setToDate(moment(toDate.$d).format("YYYY-MM-DD"));
    setPage(0);
  };

  const debouncedToDateChangeHandler = useMemo(
    () => debounce(onSearchToDate, 500),
    []
  );

  const onSearchAcknowledgeFromDate = (acknowledgedFromDate: any): void => {
    setAcknowledgedFromDate(
      moment(acknowledgedFromDate.$d).format("YYYY-MM-DD")
    );
    setPage(0);
  };

  const debouncedAcknowledgeFromDateChangeHandler = useMemo(
    () => debounce(onSearchAcknowledgeFromDate, 500),
    []
  );

  const onSearchAcknowledgeToDate = (acknowledgedToDate: any): void => {
    setAcknowledgedToDate(moment(acknowledgedToDate.$d).format("YYYY-MM-DD"));
    setPage(0);
  };

  const debouncedAcknowledgeToDateChangeHandler = useMemo(
    () => debounce(onSearchAcknowledgeToDate, 500),
    []
  );
  // PAGINATION
  const handleLimitChange = (event: any): void => {
    setMaxResultCount(event.target.value);
    setPage(page);
  };

  const handlePageChange = (event: any, page: number): void => {
    setSkipCount(maxResultCount * page);
    setPage(page);
    setPage(page);
  };

  // CLEAR
  const handleSearchClear = (): void => {
    setFromDate("");
    setToDate("");
    setSearchKeyword("");
    setDocumentAcknowledgementRequestStatusId(null);
    setAcknowledgedFromDate("");
    setAcknowledgedToDate("");
    const input = document.getElementsByTagName("input");
    for (let i = 0; i < input.length; i++) {
      input[i].value = "";
    }
    setPage(page);
  };

  // filter popover
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (event: any): void => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;
  return (
    <>
      <Box>
        <Grid container spacing={2} mb={2} alignItems={"center"}>
          <Grid item xs={12}>
            <CustomTypography
              variant="h5"
              label={"Document Acknowledgement Request For Me"}
              sx={{ fontWeight: "500", color: "#D42C2C" }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid container gap={2} sx={{ py: "1rem" }}>
          <Grid item xs={4}>
            <TextField
              label="Search"
              fullWidth
              size="small"
              id="filled-basic"
              onChange={debouncedChangeHandler}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectOption
              label="Search Status"
              name="documentAcknowledgementRequestStatusId"
              value={
                documentAcknowledgementRequestStatusId != null
                  ? documentAcknowledgementRequestStatusId
                  : ""
              }
              onChange={(event: SelectChangeEvent<string>) => {
                setDocumentAcknowledgementRequestStatusId(event.target.value);
                setPage(0);
              }}
              options={getStatusResponse}
            />
          </Grid>
          <Grid item xs={4} gap={2}>
            <CustomButton
              label={"Filter"}
              variant={"contained"}
              aria-describedby={id}
              onClick={handlePopoverClick}
              color="error"
              startIcon={<TuneIcon fontSize="small" />}
              sx={{ height: "100%" }}
            />
            <Popover
              id={id}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Card>
                <CardContent sx={{ p: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <DatePickerCustom
                      label={"From Date"}
                      selected={fromDate}
                      onChange={debouncedFromDateChangeHandler}
                      maxDate
                    />
                    <DatePickerCustom
                      label={"To Date"}
                      selected={toDate}
                      onChange={debouncedToDateChangeHandler}
                      minDate={fromDate}
                    />
                    <DatePickerCustom
                      label={"Acknowledge From Date"}
                      selected={acknowledgedFromDate}
                      onChange={debouncedAcknowledgeFromDateChangeHandler}
                    />
                    <DatePickerCustom
                      label={"Acknowledge To Date"}
                      selected={acknowledgedToDate}
                      onChange={debouncedAcknowledgeToDateChangeHandler}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Popover>
            <CustomButton
              sx={{ ml: 2, height: "100%" }}
              label={"Clear"}
              variant={"contained"}
              onClick={handleSearchClear}
            />
          </Grid>
        </Grid>
      </Box>

      <Card sx={{ mb: "1rem" }} elevation={1}>
        <CardContent sx={{ p: 2 }}>
          <Box component={Paper}>
            <TableContainer component={Paper} sx={{ height: 550 }}>
              {isLoading ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress color="primary" sx={loaderStyle} />
                </Grid>
              ) : (
                <>
                  {data?.items != null && data?.items?.length > 0 ? (
                    <CustomTable
                      columns={columns}
                      data={data.items}
                      handleSort={handleOrder}
                    ></CustomTable>
                  ) : (
                    <span style={{ alignContent: "center" }}>
                      No Record Available
                    </span>
                  )}
                </>
              )}
            </TableContainer>
            {data?.items != null && data?.items?.length > 0 && (
              <CustomPagination
                component={"div"}
                count={Number(data?.totalCount)}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={maxResultCount}
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
              />
            )}
          </Box>
        </CardContent>
      </Card>

      {/* modal for acknowledge document */}
      {openAcknowledge && (
        <RequestViewModal
          open={openAcknowledge}
          handleClose={handleCloseAcknowledge}
          handleSubmit={handleAcknowledgeDocument}
          viewData={rowData}
          setViewData={setRowData}
          url={urls}
        />
      )}
    </>
  );
};

export default DocumentRequestForMe;
