import { makeStyles } from "@material-ui/core/styles"
import Modal from "@mui/material/Modal"
import Typography from "@mui/material/Typography"
import WarningIcon from "@mui/icons-material/Warning"
import { Box, Grid } from "@mui/material"
import CustomButton from "./Button"
import CustomTypography from "./Typography"

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))
interface Props {
  open: boolean
  onHide: () => void
  onDelete: () => void | Promise<void>
}
const DeleteConfirmationModal = (props: Props): React.ReactElement => {
  const classes = useStyles()
  const { open, onHide, onDelete } = props

  return (
    <Modal open={open} onClose={onHide}>
      <Box className={classes.paper}>
        <Grid container gap={1}>
          <WarningIcon color="error" fontSize="large" />
          <CustomTypography
            variant="h5"
            label={"Alert"}
            sx={{ fontWeight: "500", color: "#D42C2C" }}
          />
        </Grid>
        <Typography mt={2}>Are you sure you want to Delete?</Typography>

        <Grid container gap={2} mt={2} justifyContent={"flex-end"}>
          <CustomButton
            sx={{ height: "100%" }}
            color="error"
            variant="contained"
            onClick={onHide}
            label={"Cancel"}
          />
          <CustomButton
            sx={{ height: "100%", background: "#13A83D", color: "white" }}
            color="success"
            variant="contained"
            onClick={() => {
              void onDelete()
              onHide()
            }}
            label={"Confirm"}
          />
        </Grid>
      </Box>
    </Modal>
  )
}

export default DeleteConfirmationModal
