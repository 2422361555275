/* eslint-disable react/display-name */
import { Suspense } from "react"

// project imports
import Loader from "./Loader"

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  )
}
Loadable.displayName = "Loadable"

export default Loadable
