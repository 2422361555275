import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { LuExpand } from "react-icons/lu";
import { Link } from "react-router-dom";
import ModalComponent from "../../../ui-component/modal";

interface RequestViewModalTypes {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (value: string) => Promise<void>;
  viewData: any;
  setViewData: any;
  url: string;
}
const Item = styled(Paper)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: "flex",
  flexDirection: "row",
  gap: "5px",
  marginBottom: "14px",
}));
const RequestViewModal = ({
  open,
  handleClose,
  viewData,
  setViewData,
  handleSubmit,
  url,
}: RequestViewModalTypes): React.ReactElement => {
  return (
    <>
      <ModalComponent
        open={open}
        handleClose={handleClose}
        // styleValue={{  marginLeft: 25, marginTop: 5, height: "800px" }}
      >
        <DialogTitle>Document</DialogTitle>
        <DialogContent sx={{ paddingRight: "10px" }}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Grid container>
              <List
                sx={{
                  bgcolor: "background.paper",
                  display: "flex",
                  flexDirection: "column",
                }}
                // component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <Item>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#a4a4a4",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    Name:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ color: "#434343", lineHeight: 1 }}
                  >
                    {viewData?.documentName}
                  </Typography>
                </Item>

                <Item>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#a4a4a4",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    Acknowledgement Requested To:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ color: "#434343", lineHeight: 1 }}
                  >
                    {viewData?.acknowledgementRequestedToFullName}
                  </Typography>
                </Item>
                <Item>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#a4a4a4",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    Created By:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ color: "#434343", lineHeight: 1 }}
                  >
                    {viewData?.createdByFullName}
                  </Typography>
                </Item>
                <Item>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#a4a4a4",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    Acknowledgement Done Date:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ color: "#434343", lineHeight: 1 }}
                  >
                    {moment(viewData?.acknowledgementDoneDateTime).format(
                      "YYYY-MM-DD"
                    ) === "Invalid date"
                      ? "-"
                      : moment(viewData?.acknowledgementDoneDateTime).format(
                          "YYYY-MM-DD"
                        )}
                  </Typography>
                </Item>
                <Item>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#a4a4a4",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    Creation Time:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ color: "#434343", lineHeight: 1 }}
                  >
                    {moment(viewData?.creationTime).format(
                      "YYYY-MM-DD HH:MM:SS"
                    )}
                  </Typography>
                </Item>
                <Item>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#a4a4a4",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    Acknowledgement Request Status:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ color: "#13A83D", lineHeight: 1 }}
                  >
                    {viewData?.documentAcknowledgementRequestStatusDisplayName}
                  </Typography>
                </Item>
                <Item>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#a4a4a4",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    Due Date Time:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ color: "#434343", lineHeight: 1 }}
                  >
                    {moment(viewData?.dueDateTime).format(
                      "YYYY-MM-DD HH:MM:SS"
                    )}
                  </Typography>
                </Item>
                <Item>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#a4a4a4",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    Last Modification Time:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ color: "#434343", lineHeight: 1 }}
                  >
                    {viewData?.lastModificationTime
                      ? moment(viewData?.lastModificationTime).format(
                          "YYYY-MM-DD HH:MM:SS"
                        )
                      : "-"}
                  </Typography>
                </Item>
                <Item>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#a4a4a4",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    Last Modification By:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ color: "#434343", lineHeight: 1 }}
                  >
                    {viewData?.lastModifiedByFullName
                      ? viewData?.lastModifiedByFullName
                      : "-"}
                  </Typography>
                </Item>
                <Item>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#a4a4a4",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    Description:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ color: "#434343", lineHeight: 1 }}
                  >
                    {viewData?.documentDescription}
                  </Typography>
                </Item>
              </List>
              <Box
                sx={{
                  width: "100%",
                  position: "relative",
                }}
              >
                <iframe src={`${url}`} width="100%" height="200"></iframe>
                <Link to={url} target="_blank" title="Preview document">
                  <Button
                    variant="outlined"
                    sx={{
                      fontSize: "1.5rem",
                      color: "#d82d2b",
                      position: "absolute",
                      right: "2.3rem",
                      top: "0.8rem",
                      transition: "all 0.3s ease-in-out",
                      "&:hover": {
                        fontSize: "1.7rem",
                      },
                    }}
                  >
                    <LuExpand />
                  </Button>
                </Link>
              </Box>
            </Grid>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ color: "black" }}
                    onChange={(e: any) => {
                      if (e.target.checked != null) {
                        setViewData({ ...viewData, agree: e.target.checked });
                      } else {
                        setViewData({ ...viewData, agree: e.target.checked });
                      }
                    }}
                  />
                }
                label="I have read and acknowledged the content of the document."
              />
            </FormGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>
            Close
          </Button>
          {viewData?.agree === true && (
            <Button
              variant="contained"
              color="info"
              onClick={() => {
                // handleAcknowledgeDocument(acknowledgeId);
                // setAcknowledgeId("");
                void handleSubmit(viewData?.id);
                setViewData({ ...viewData, agree: true, acknowledgeId: "" });
                handleClose();
              }}
            >
              Acknowledge
            </Button>
          )}
        </DialogActions>
      </ModalComponent>
    </>
  );
};

export default RequestViewModal;
