import * as React from "react"
import TextField from "@mui/material/TextField"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

interface Props {
  label: string
  selected: string | undefined
  onChange?: any
  maxDate?: any | null | undefined
  minDate?: any | null | undefined
}

const DatePickerCustom = (props: Props): React.ReactElement => {
  const { label, selected, onChange, minDate } = props
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs as any}>
      <DatePicker
        label={label}
        value={selected}
        onChange={onChange}
        minDate={minDate}
        maxDate={new Date()}
        renderInput={(params) => <TextField size="small" {...params} error={false} />}
      />
    </LocalizationProvider>
  )
}
export default DatePickerCustom
