import PropTypes from "prop-types";
import { forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project imports
import { MENU_OPEN, SET_MENU } from "../../../../../store/actions";

// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import type { AppDispatch, RootState } from "../../../../../store/reducer";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }: any): React.ReactElement => {
  const theme = useTheme<any>();
  const dispatch: AppDispatch = useDispatch();
  const customization = useSelector((state: RootState) => state.customization);
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));

  const Icon = item.icon;
  const itemIcon =
    item?.icon != null ? (
      <Icon stroke={1.5} size="1.6rem" />
    ) : (
      <FiberManualRecordIcon
        sx={{
          width:
            customization.isOpen.findIndex((id: string) => id === item?.id) > -1
              ? 8
              : 6,
          height:
            customization.isOpen.findIndex((id: string) => id === item?.id) > -1
              ? 8
              : 6,
        }}
        fontSize={level > 0 ? "inherit" : "medium"}
      />
    );

  let itemTarget = "_self";
  if (item.target != null) {
    itemTarget = "_blank";
  }

  const listItemProps = {
    component: forwardRef((props, ref) => (
      <Link /* ref={ref} */ {...props} to={item.url} target={itemTarget} />
    )),
  };

  listItemProps.component.displayName = "ListItem";

  if (item?.external != null) {
    // listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const itemHandler = (id: string): void => {
    dispatch({ type: MENU_OPEN, id });
    if (matchesSM) dispatch({ type: SET_MENU, opened: false });
  };

  // active menu item on page load
  useEffect(() => {
    const currentPath = document.location.pathname;
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: item.id });
    }
    if (currentPath === "/") {
      dispatch({ type: MENU_OPEN, id: "dashboardContent" });
    }
  }, []);

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        // borderRadius: `${customization.borderRadius}px`,
        borderRadius: "3px",
        mb: 0.5,
        alignItems: "flex-start",
        backgroundColor: level > 1 ? "transparent !important" : "inherit",
        py: level > 1 ? 1 : "8px",
        pl: `${level * 10}px`,
        "&:hover": {
          background: "#e3e3e3",
          color: "#383838",
          fontWeight: "500",
          "& .MuiListItemIcon-root": {
            color: "#383838",
          },
        },
      }}
      selected={
        customization.isOpen.findIndex((id: string) => id === item.id) > -1
      }
      onClick={() => {
        itemHandler(item.id);
      }}
    >
      <ListItemIcon
        sx={{
          m: 0,
          minWidth: item?.icon != null ? 18 : 25,
          "&:hover": {
            color: "red",
          },
        }}
      >
        {itemIcon}
      </ListItemIcon>
      <ListItemText
        sx={{ m: 0 }}
        primary={
          <Typography color={"inherit"} fontWeight={"inherit"}>
            {item.title}
          </Typography>
        }
        secondary={
          item.caption != null && (
            <Typography
              variant="caption"
              sx={{ ...theme.typography.subMenuCaption }}
              display="block"
              gutterBottom
            >
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip != null && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar ?? <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
