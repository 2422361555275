import AddIcon from "@mui/icons-material/Add";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogContent,
  Fab,
  Paper,
  Popper,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import useIntranetPortalCoreApi from "../../hooks/useIntranetPortalAdminApi";
import { UserProfileService } from "../../services/intranet-portal-services";
import { RootState } from "../../store/reducer";
import MainCard from "../../ui-component/cards/MainCard";
import Transitions from "../../ui-component/extended/Transitions";
import BotForm from "./BotForm";
import BotModal from "./BotModal";

import "./bot.css";

type Props = {
  clientId: string;
  clientSecret: string;
  projectId: string;
  themeColor: string;
  botTitle: string;
  subTitle: string;
};

export default function Bot(props: Props) {
  const { handleRequest } = useIntranetPortalCoreApi();

  const theme = useTheme<any>();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const anchorRef = useRef<HTMLElement | HTMLDivElement | any | null>(null);

  const handleClose = (event: any): void => {
    if (anchorRef.current?.contains(event.target) ?? false) {
      return;
    }
    setModalIsOpen(false);
  };

  return (
    <>
      <Box ref={anchorRef}>
        {/* <Fab
          color="primary"
          onClick={() => {
            setModalIsOpen((prev) => !prev);
          }}
         size="small"
         sx={{
          mr: "2rem"
         }}
        >
          <QuestionAnswerOutlinedIcon
            sx={{
              fontSize: "1.4rem",
            }}
          />
        </Fab> */}

        {/* <button
          role="button"
          className="askAmwise"
          onClick={() => {
            setModalIsOpen((prev) => !prev);
          }}
        >
          <QuestionAnswerIcon sx={{ mr: 1 }} /> Ask AMWISE
        </button> */}

        <Button
          size="medium"
          variant="contained"
          endIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-message-circle-question"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M15.02 19.52c-2.341 .736 -5 .606 -7.32 -.52l-4.7 1l1.3 -3.9c-2.324 -3.437 -1.426 -7.872 2.1 -10.374c3.526 -2.501 8.59 -2.296 11.845 .48c1.649 1.407 2.575 3.253 2.742 5.152"></path>
              <path d="M19 22v.01"></path>
              <path d="M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483"></path>
            </svg>
          }
          sx={{
            // background: "linear-gradient(to right, #8e0e00, #1f1c18)",
            background: "linear-gradient(150deg, rgb(216, 45, 43), rgb(45 40 32))",
            color: "white",
            border: "none",
            boxShadow: "0px 0px 8px 2px rgba(0,0,0,0.2)",
            position: "relative",
          }}
          onClick={() => {
            setModalIsOpen((prev) => !prev);
          }}
          className="shinyButton"
        >
          <Box className="ask-amwise"
            style={{
              fontWeight: "700",
              letterSpacing: ".8px",
              fontSize: "1.2rem",
            }}
          >
            {" "}
            Ask AMWISE
          </Box>
          <i></i>
        </Button>
      </Box>

      <Dialog
        open={modalIsOpen}
        fullWidth
        maxWidth="md"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <BotForm {...props} setModalIsOpen={setModalIsOpen} />
      </Dialog>
    </>
  );
}
