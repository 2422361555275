/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IntranetPortal_Documents_Dtos_ActivateDocumentDto } from '../models/IntranetPortal_Documents_Dtos_ActivateDocumentDto';
import type { IntranetPortal_Documents_Dtos_CreateDocumentDto } from '../models/IntranetPortal_Documents_Dtos_CreateDocumentDto';
import type { IntranetPortal_Documents_Dtos_DeactivateDocumentDto } from '../models/IntranetPortal_Documents_Dtos_DeactivateDocumentDto';
import type { IntranetPortal_Documents_Dtos_DocumentDto } from '../models/IntranetPortal_Documents_Dtos_DocumentDto';
import type { IntranetPortal_Documents_Dtos_DocumentStatusOutputDto } from '../models/IntranetPortal_Documents_Dtos_DocumentStatusOutputDto';
import type { IntranetPortal_Documents_Dtos_GetDocumentAcknowledgmentRequestStatusDto } from '../models/IntranetPortal_Documents_Dtos_GetDocumentAcknowledgmentRequestStatusDto';
import type { IntranetPortal_Documents_Dtos_PagedAndSortedDocumentAcknowledgementRequestListDto } from '../models/IntranetPortal_Documents_Dtos_PagedAndSortedDocumentAcknowledgementRequestListDto';
import type { IntranetPortal_Documents_Dtos_RequestAcknowledgementForDocumentInputDto } from '../models/IntranetPortal_Documents_Dtos_RequestAcknowledgementForDocumentInputDto';
import type { IntranetPortal_Documents_Dtos_ReuploadDocumentInputDto } from '../models/IntranetPortal_Documents_Dtos_ReuploadDocumentInputDto';
import type { IntranetPortal_Documents_Dtos_RevokeDocumentAcknowledgementRequestDto } from '../models/IntranetPortal_Documents_Dtos_RevokeDocumentAcknowledgementRequestDto';
import type { IntranetPortal_Documents_Dtos_UpdateDocumentDto } from '../models/IntranetPortal_Documents_Dtos_UpdateDocumentDto';
import type { IntranetPortal_Responses_ResponseDto } from '../models/IntranetPortal_Responses_ResponseDto';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_DocumentAcknowledgementRequestDto_IntranetPortal_Application_Contracts } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_DocumentAcknowledgementRequestDto_IntranetPortal_Application_Contracts';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_DocumentAcknowledgementRequestsForMeDto_IntranetPortal_Application_Contracts } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_DocumentAcknowledgementRequestsForMeDto_IntranetPortal_Application_Contracts';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_DocumentDto_IntranetPortal_Application_Contracts } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_DocumentDto_IntranetPortal_Application_Contracts';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_ReponseForAcknowledgementRequestDto_IntranetPortal_Application_Contracts } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_ReponseForAcknowledgementRequestDto_IntranetPortal_Application_Contracts';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DocumentService {

    /**
     * @param id
     * @returns IntranetPortal_Documents_Dtos_ActivateDocumentDto Success
     * @throws ApiError
     */
    public static postCoreApiApiAppDocumentActivateDocument(
        id: string,
    ): CancelablePromise<IntranetPortal_Documents_Dtos_ActivateDocumentDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/document/{id}/activate-document',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns IntranetPortal_Responses_ResponseDto Success
     * @throws ApiError
     */
    public static deleteCoreApiApiAppDocumentDocument(
        id: string,
    ): CancelablePromise<IntranetPortal_Responses_ResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/core-api/api/app/document/{id}/document',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns IntranetPortal_Documents_Dtos_DocumentDto Success
     * @throws ApiError
     */
    public static putCoreApiApiAppDocumentDocument(
        id: string,
        requestBody?: IntranetPortal_Documents_Dtos_UpdateDocumentDto,
    ): CancelablePromise<IntranetPortal_Documents_Dtos_DocumentDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/core-api/api/app/document/{id}/document',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns IntranetPortal_Documents_Dtos_DocumentDto Success
     * @throws ApiError
     */
    public static getCoreApiApiAppDocumentDocumentById(
        id: string,
    ): CancelablePromise<IntranetPortal_Documents_Dtos_DocumentDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/document/{id}/document-by-id',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns IntranetPortal_Documents_Dtos_DocumentStatusOutputDto Success
     * @throws ApiError
     */
    public static getCoreApiApiAppDocumentDocumentStatuses(): CancelablePromise<Array<IntranetPortal_Documents_Dtos_DocumentStatusOutputDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/document/document-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param searchKeyword
     * @param documentStatusId
     * @param fromDate
     * @param toDate
     * @param acknowledgedFromDate
     * @param acknowledgedToDate
     * @param sortType
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_DocumentDto_IntranetPortal_Application_Contracts Success
     * @throws ApiError
     */
    public static getCoreApiApiAppDocumentPagedAndSortedDocumentList(
        searchKeyword?: string,
        documentStatusId?: Array<string>,
        fromDate?: string,
        toDate?: string,
        acknowledgedFromDate?: string,
        acknowledgedToDate?: string,
        sortType?: string,
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
    ): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_DocumentDto_IntranetPortal_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/document/paged-and-sorted-document-list',
            query: {
                'SearchKeyword': searchKeyword,
                'DocumentStatusId': documentStatusId,
                'FromDate': fromDate,
                'ToDate': toDate,
                'AcknowledgedFromDate': acknowledgedFromDate,
                'AcknowledgedToDate': acknowledgedToDate,
                'SortType': sortType,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns IntranetPortal_Documents_Dtos_DocumentDto Success
     * @throws ApiError
     */
    public static postCoreApiApiAppDocumentReuploadDocument(
        requestBody?: IntranetPortal_Documents_Dtos_ReuploadDocumentInputDto,
    ): CancelablePromise<IntranetPortal_Documents_Dtos_DocumentDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/document/reupload-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param documentSearchKeyword
     * @param employeeSearchKeyword
     * @param sortType
     * @param fromDate
     * @param toDate
     * @param acknowledgedFromDate
     * @param acknowledgedToDate
     * @param documentId
     * @param documentAcknowledgementRequestStatusId
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_DocumentAcknowledgementRequestDto_IntranetPortal_Application_Contracts Success
     * @throws ApiError
     */
    public static getCoreApiApiAppDocumentPagedAndSortedDocumentAcknowledgmentRequestForAdmin(
        documentSearchKeyword?: string,
        employeeSearchKeyword?: string,
        sortType?: string,
        fromDate?: string,
        toDate?: string,
        acknowledgedFromDate?: string,
        acknowledgedToDate?: string,
        documentId?: string,
        documentAcknowledgementRequestStatusId?: Array<string>,
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
    ): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_DocumentAcknowledgementRequestDto_IntranetPortal_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/document/paged-and-sorted-document-acknowledgment-request-for-admin',
            query: {
                'DocumentSearchKeyword': documentSearchKeyword,
                'EmployeeSearchKeyword': employeeSearchKeyword,
                'SortType': sortType,
                'FromDate': fromDate,
                'ToDate': toDate,
                'AcknowledgedFromDate': acknowledgedFromDate,
                'AcknowledgedToDate': acknowledgedToDate,
                'DocumentId': documentId,
                'DocumentAcknowledgementRequestStatusId': documentAcknowledgementRequestStatusId,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns IntranetPortal_Documents_Dtos_DocumentDto Success
     * @throws ApiError
     */
    public static postCoreApiApiAppDocumentDocument(
        requestBody?: IntranetPortal_Documents_Dtos_CreateDocumentDto,
    ): CancelablePromise<IntranetPortal_Documents_Dtos_DocumentDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/document/document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns IntranetPortal_Documents_Dtos_DeactivateDocumentDto Success
     * @throws ApiError
     */
    public static postCoreApiApiAppDocumentDeactivateDocument(
        id: string,
    ): CancelablePromise<IntranetPortal_Documents_Dtos_DeactivateDocumentDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/document/{id}/deactivate-document',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns IntranetPortal_Documents_Dtos_GetDocumentAcknowledgmentRequestStatusDto Success
     * @throws ApiError
     */
    public static getCoreApiApiAppDocumentDocumentAcknowledgmentRequestStatuses(): CancelablePromise<Array<IntranetPortal_Documents_Dtos_GetDocumentAcknowledgmentRequestStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/document/document-acknowledgment-request-statuses',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns IntranetPortal_Responses_ResponseDto Success
     * @throws ApiError
     */
    public static postCoreApiApiAppDocumentAcknowledgeDocumentRequest(
        id: string,
    ): CancelablePromise<IntranetPortal_Responses_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/document/{id}/acknowledge-document-request',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns IntranetPortal_Responses_ResponseDto Success
     * @throws ApiError
     */
    public static postCoreApiApiAppDocumentRequestAcknowledgementForDocument(
        requestBody?: IntranetPortal_Documents_Dtos_RequestAcknowledgementForDocumentInputDto,
    ): CancelablePromise<IntranetPortal_Responses_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/document/request-acknowledgement-for-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param searchKeyword
     * @param documentId
     * @param fromDate
     * @param toDate
     * @param sortType
     * @param departmentId
     * @param designationId
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_ReponseForAcknowledgementRequestDto_IntranetPortal_Application_Contracts Success
     * @throws ApiError
     */
    public static getCoreApiApiAppDocumentEmployeesForDocumentAcknowledgementRequest(
        searchKeyword?: string,
        documentId?: string,
        fromDate?: string,
        toDate?: string,
        sortType?: string,
        departmentId?: Array<string>,
        designationId?: Array<string>,
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
    ): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_ReponseForAcknowledgementRequestDto_IntranetPortal_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/document/employees-for-document-acknowledgement-request',
            query: {
                'SearchKeyword': searchKeyword,
                'DocumentId': documentId,
                'FromDate': fromDate,
                'ToDate': toDate,
                'SortType': sortType,
                'DepartmentId': departmentId,
                'DesignationId': designationId,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param searchKeyword
     * @param fromDate
     * @param toDate
     * @param acknowledgedFromDate
     * @param acknowledgedToDate
     * @param documentAcknowledgementRequestStatusId
     * @param sortOrder
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_DocumentAcknowledgementRequestsForMeDto_IntranetPortal_Application_Contracts Success
     * @throws ApiError
     */
    public static getCoreApiApiAppDocumentDocumentAcknowledgementRequestsForMe(
        searchKeyword?: string,
        fromDate?: string,
        toDate?: string,
        acknowledgedFromDate?: string,
        acknowledgedToDate?: string,
        documentAcknowledgementRequestStatusId?: Array<string>,
        sortOrder?: string,
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
    ): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Documents_Dtos_DocumentAcknowledgementRequestsForMeDto_IntranetPortal_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/document/document-acknowledgement-requests-for-me',
            query: {
                'SearchKeyword': searchKeyword,
                'FromDate': fromDate,
                'ToDate': toDate,
                'AcknowledgedFromDate': acknowledgedFromDate,
                'AcknowledgedToDate': acknowledgedToDate,
                'DocumentAcknowledgementRequestStatusId': documentAcknowledgementRequestStatusId,
                'SortOrder': sortOrder,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns IntranetPortal_Responses_ResponseDto Success
     * @throws ApiError
     */
    public static postCoreApiApiAppDocumentRevokeDocumentAcknowledgementRequest(
        requestBody?: IntranetPortal_Documents_Dtos_RevokeDocumentAcknowledgementRequestDto,
    ): CancelablePromise<IntranetPortal_Responses_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/document/revoke-document-acknowledgement-request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData
     * @returns string Success
     * @throws ApiError
     */
    public static postCoreApiApiAppDocumentUploadDocument(
        formData?: {
            file?: Blob;
        },
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/document/upload-document',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static postCoreApiApiAppDocumentDownloadDocumentAcknowledgementRequest(
        requestBody?: IntranetPortal_Documents_Dtos_PagedAndSortedDocumentAcknowledgementRequestListDto,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/document/download-document-acknowledgement-request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
