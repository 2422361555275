import { useTheme } from "@mui/material/styles"
import {
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useNavigate } from "react-router-dom"

import { CalendarService } from "../../services/intranet-portal-services"
import useIntranetPortalCoreApi from "../../hooks/useIntranetPortalAdminApi"
import { ErrorMessage, Form, Formik } from "formik"
import AnimateButton from "../../ui-component/extended/AnimateButton"
import { toast } from "react-toastify"
import usePageTracking from "../../utils/withPageTracking"

const AddPublicCalendar = (): React.ReactElement => {
  const { handleRequest } = useIntranetPortalCoreApi()
  const theme = useTheme<any>()
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"))

  const navigate = useNavigate()

  const CalendarinitialState = {
    calendarUrl: "",
  }
  const handleCalendarUrl = async (val: any): Promise<void> => {
    await handleRequest(
      CalendarService.postCoreApiApiAppCalendarSetPublicGoogleCalendarUrl(val.calendarUrl)
    ).then((response) => {
      if (response?.code === 200) {
        toast.success("Calendar Url successfully Added")
      }
    })
  }

  usePageTracking()
  return (
    <>
      <Box>
        <Grid container spacing={2} mb={2} alignItems={"center"}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: 500, color: "#D42C2C" }}>
              Add Public Calendar
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            {/* Form post of calendar url app */}
            <Formik initialValues={CalendarinitialState} onSubmit={handleCalendarUrl}>
              {({ handleChange, values }) => (
                <Form noValidate>
                  <Grid container spacing={matchDownSM ? 0 : 2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Calendar Url"
                        margin="normal"
                        name="calendarUrl"
                        type="text"
                        // value={values.calendarUrl}
                        onChange={handleChange}
                        autoComplete="off"
                        variant="outlined"
                        size="small"
                      />
                      <FormHelperText error id="standard-weight-helper-text--register">
                        <ErrorMessage name="calendarUrl" />
                      </FormHelperText>
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 2 }}>
                    <AnimateButton>
                      <Button
                        disableElevation
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="error"
                      >
                        Add
                      </Button>
                    </AnimateButton>
                  </Box>
                </Form>
              )}
            </Formik>
            <AnimateButton>
              <Button
                sx={{ mt: 2 }}
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="outlined"
                color="error"
                onClick={() => {
                  navigate(-1)
                }}
              >
                Back
              </Button>
            </AnimateButton>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}
export default AddPublicCalendar
