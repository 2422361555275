// assets
import {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconBriefcase,
  IconUserPlus,
  IconUsers,
  IconUserCircle,
  IconBuilding,
  IconHome2,
  IconCalendar,
} from "@tabler/icons"

// constant
const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconBriefcase,
  IconUserPlus,
  IconUsers,
  IconUserCircle,
  IconBuilding,
  IconHome2,
  IconCalendar,
}

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const configurations = {
  id: "configurations",
  title: "configurations",
  type: "group",
  children: [
    {
      id: "public-calendar",
      title: "Add Public Calendar",
      type: "item",
      url: "/configurations/public-calendar",
      icon: icons.IconCalendar,
      breadcrumbs: false,
      guardName: "SetPublicCalendarUrlAdmin",
    },
  ],
}

// const item = utilities?.children

export default configurations
