import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  TextField,
  useTheme,
  FormHelperText,
  useMediaQuery,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

// project imports
import AnimateButton from "../../../ui-component/extended/AnimateButton";
import useQuery from "../../../hooks/useQuery";
import { DocumentService } from "../../../services/intranet-portal-services";

// third party
import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import { toast } from "react-toastify";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// import { useDropzone } from "react-dropzone";
import styled from "@emotion/styled";
import usePageTracking from "../../../utils/withPageTracking";

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required("Name is required *"),
  acknowledgementDeadlineInDays: Yup.number()
    .min(1, "Acknowledge deadline days must be greate than 0")
    .required("Acknowledge deadline on days is required *"),
  description: Yup.string().required("Description is required *"),
});

const AddDocument = (): React.ReactElement => {
  const navigate = useNavigate();
  const theme = useTheme<any>();
  const [getEditDocumentData, setGetEditDocumentData] = useState<any>();
  const [addFileResponse, setAddFileResponse] = useState<any>();
  const [files, setFiles] = useState<any>([]);
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  const params = useQuery();
  const id: any = params.get("id");

  const initialState = {
    name: "",
    description: "",
    documentUrl: addFileResponse,
    acknowledgementDeadlineInDays: "",
  };

  const handleDocumentApp = async (val: any): Promise<void> => {
    const formData = {
      name: val.name,
      description: val.description,
      documentUrl: addFileResponse,
      acknowledgementDeadlineInDays: val.acknowledgementDeadlineInDays,
    };

    if (id != null) {
      try {
        const editDocumentApp =
          await DocumentService.putCoreApiApiAppDocumentDocument(id, formData);
        if (editDocumentApp != null) {
          toast.success("Document updated successfully");
          navigate(-1);
        }
      } catch (e: any) {
        if (e.status != null) {
          toast.error(e.status);
        } else if (e?.body?.error?.validationErrors != null) {
          toast.error(e?.body?.error?.validationErrors);
        } else if (e.message != null) {
          toast.error(e.message);
        } else {
          toast.error("Unable to fetch api");
        }
      }
    } else {
      try {
        const addresponse =
          await DocumentService.postCoreApiApiAppDocumentDocument(formData);
        if (addresponse != null) {
          toast.success("Document added successfully");
          navigate(-1);
        }
      } catch (e: any) {
        if (e.status != null) {
          toast.error(e.status);
        } else if (e?.body?.error?.validationErrors != null) {
          toast.error(e?.body?.error?.validationErrors);
        } else if (e.message != null) {
          toast.error(e.message);
        } else {
          toast.error("Unable to fetch api");
        }
      }
    }
  };

  const fetchEditData = async (): Promise<void> => {
    try {
      const editResponse =
        await DocumentService.getCoreApiApiAppDocumentDocumentById(id);
      setGetEditDocumentData(editResponse);
    } catch (e: any) {
      if (e.status != null) {
        toast.error(e.status);
      } else if (e?.body?.error?.validationErrors != null) {
        toast.error(e?.body?.error?.validationErrors);
      } else if (e.message != null) {
        toast.error(e.message);
      } else {
        toast.error("Unable to fetch api");
      }
    }
  };

  useEffect(() => {
    if (id != null) {
      void fetchEditData();
    }
  }, []);

  const fileInitialState = {
    file: "",
  };

  const handleDocumentLogo = async (val: any): Promise<void> => {
    try {
      const formDataFile = {
        file: val,
      };
      const addFileResponse =
        await DocumentService.postCoreApiApiAppDocumentUploadDocument(
          formDataFile
        );
      if (addFileResponse != null) {
        setAddFileResponse(addFileResponse);
        toast.success("File uploaded successfully");
      }
    } catch (e: any) {
      /* if (e.status) {
        toast.error(e.status);
      } else */ /* if (e?.body?.error?.validationErrors) {
        toast.error(e?.body?.error?.validationErrors);
      } else */ if (e.message != null) {
        toast.error("Extension with PDF and PNG are only supported");
      } else {
        toast.error("Unable to fetch api");
      }
    }
  };
  const onDrop = useCallback((acceptedFiles: any): void => {
    const logo = acceptedFiles;
    handleDocumentLogo(logo)
      .then(() => {
        if (acceptedFiles?.length != null) {
          setFiles((previousFiles: any) => [
            // ...previousFiles,
            ...acceptedFiles.map((file: any) =>
              Object.assign(file, { preview: URL.createObjectURL(file) })
            ),
          ]);
        }
      })
      .catch((error) => {
        // Handle error if necessary
        console.error("Error handling document logo:", error);
      });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 1024 * 1000,
  });
  const removeFile = (name: string): void => {
    setFiles((files: any) => files.filter((file: any) => file.name !== name));
  };

  const Dropzone = styled("div")({
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "2px",
    borderRadius: "2px",
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    "&:hover": {
      borderColor: "#c62828",
    },
  });

  usePageTracking();
  return (
    <>
      <Box>
        <Grid container spacing={2} mb={2} alignItems={"center"}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: 500, color: "#D42C2C" }}>
              {id != null ? "Update" : "Add"} Document
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            {/* Logo Upload Area of document app */}
            {id == null && (
              <Formik
                initialValues={fileInitialState}
                onSubmit={handleDocumentLogo}
                enableReinitialize={true}
              >
                {({ setFieldValue }) => (
                  <Form>
                    <div {...getRootProps()}>
                      <Dropzone>
                        <input {...getInputProps()} />
                        {/* {isDragActive ? (
                          <p>Drop the files here ...</p>
                        ) : ( */}
                        <p>click to select files</p>
                        {/* )} */}
                      </Dropzone>
                      <div>
                        <>
                          {files?.map((file: any, index: number) =>
                            file.type === "application/pdf" ? (
                              <Typography
                                sx={{ display: "flex", margin: "20px" }}
                                variant="h5"
                                key={index}
                              >
                                {`${file.path as string} uploaded`}

                                <HighlightOffIcon
                                  onClick={() => {
                                    removeFile(file?.name);
                                  }}
                                  sx={{ fontSize: "25px", marginLeft: "10px" }}
                                  key={index}
                                />
                              </Typography>
                            ) : (
                              <ImageList cols={8} key={index}>
                                <ImageListItem key={file.name}>
                                  <img
                                    src={file.preview}
                                    alt={file.name}
                                    width={100}
                                    height={100}
                                    onLoad={() => {
                                      URL.revokeObjectURL(file.preview);
                                    }}
                                    loading="lazy"
                                  />
                                  <ImageListItemBar
                                    sx={{
                                      background:
                                        "linear-gradient(to top, rgba(0,0,0,0.7) 0%, " +
                                        "rgba(0,0,0,0.6) 70%, rgba(0,0,0,0) 100%)",
                                    }}
                                    position="bottom"
                                    actionIcon={
                                      <IconButton sx={{ color: "white" }}>
                                        <HighlightOffRoundedIcon
                                          sx={{ fontSize: "24px" }}
                                        />
                                      </IconButton>
                                    }
                                    actionPosition="right"
                                    onClick={() => {
                                      removeFile(file?.name);
                                    }}
                                  />
                                </ImageListItem>
                              </ImageList>
                            )
                          )}
                        </>
                      </div>
                    </div>
                    <FormHelperText
                      variant="outlined"
                      sx={{ fontSize: "1rem" }}
                      error
                      id="standard-weight-helper-text--register"
                    >
                      <ErrorMessage name="file" />
                    </FormHelperText>
                  </Form>
                )}
              </Formik>
            )}
            {/* Form post of internal app */}
            <Formik
              initialValues={
                id != null
                  ? {
                      name: getEditDocumentData?.name ?? "",
                      description: getEditDocumentData?.description ?? "",
                      documentUrl: getEditDocumentData?.documentUrl ?? "",
                      acknowledgementDeadlineInDays:
                        getEditDocumentData?.acknowledgementDeadlineInDays ??
                        "",
                    }
                  : initialState
              }
              onSubmit={handleDocumentApp}
              enableReinitialize={id}
              validationSchema={FORM_VALIDATION}
            >
              {({ handleChange, values }) => (
                <Form noValidate>
                  <Grid container spacing={matchDownSM ? 0 : 2} marginY={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Name"
                        margin="none"
                        name="name"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        autoComplete="off"
                        variant="outlined"
                      />
                      <FormHelperText
                        variant="outlined"
                        sx={{ fontSize: "1rem" }}
                        error
                        id="standard-weight-helper-text--register"
                      >
                        <ErrorMessage name="name" />
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Acknowledge Deadline On Days"
                        margin="none"
                        name="acknowledgementDeadlineInDays"
                        type="number"
                        value={values.acknowledgementDeadlineInDays}
                        autoComplete="off"
                        onChange={handleChange}
                        variant="outlined"
                      />
                      <FormHelperText
                        variant="outlined"
                        sx={{ fontSize: "1rem" }}
                        error
                        id="standard-weight-helper-text--register"
                      >
                        <ErrorMessage name="acknowledgementDeadlineInDays" />
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Description"
                        margin="none"
                        name="description"
                        type="text"
                        onChange={handleChange}
                        rows={3}
                        value={values.description}
                        autoComplete="off"
                        variant="outlined"
                      />
                      <FormHelperText
                        variant="outlined"
                        sx={{ fontSize: "1rem" }}
                        error
                        id="standard-weight-helper-text--register"
                      >
                        <ErrorMessage name="description" />
                      </FormHelperText>
                    </Grid>
                    <Grid container spacing={matchDownSM ? 0 : 2}>
                      {id == null && (
                        <Grid item xs={12} sm={6}>
                          <TextField
                            sx={{ display: "none" }}
                            fullWidth
                            label={
                              addFileResponse === null ||
                              getEditDocumentData?.documentUrl === null
                                ? ""
                                : "Document Url"
                            }
                            margin="none"
                            name="documentUrl"
                            type="text"
                            value={addFileResponse}
                            onChange={handleChange}
                            autoComplete="off"
                            variant="outlined"
                            disabled
                          />
                          <FormHelperText
                            variant="outlined"
                            sx={{ fontSize: "1rem" }}
                            error
                            id="standard-weight-helper-text--register"
                          >
                            <ErrorMessage name="documentUrl" />
                          </FormHelperText>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 2 }}>
                    <AnimateButton>
                      <Button
                        disableElevation
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="error"
                      >
                        {id != null ? "Update" : "Add"}
                      </Button>
                    </AnimateButton>
                  </Box>
                </Form>
              )}
            </Formik>
            <AnimateButton>
              <Button
                sx={{ mt: 2 }}
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="outlined"
                color="error"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back To the list
              </Button>
            </AnimateButton>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
export default AddDocument;
