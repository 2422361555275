import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../../store/reducer";

interface UserState {
  userRole: any;
  permission: any;
  currentUserRole: string;
}

const initialState: UserState = {
  userRole: Object,
  permission: Object,
  currentUserRole: "",
};

const userRole = createSlice({
  name: "userRole",
  initialState,
  reducers: {
    setUserRole: (
      state,
      {
        payload: { userRole, permission },
      }: PayloadAction<{ userRole: any; permission: any }>
    ) => {
      state.userRole = userRole;
      state.permission = permission;
      state.currentUserRole = userRole.currentUser.roles[0];
    },
  },
});

export const { setUserRole } = userRole.actions;

export default userRole.reducer;

export const selectUserRole = (state: RootState): any => state?.user;

export const selectPermissions = (state: RootState): any =>
  state?.user?.permission;
