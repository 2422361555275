import React, { type MouseEventHandler } from "react";
import Button from "@mui/material/Button";

interface Props {
  variant?: "outlined" | "contained";
  onClick?: any;
  label: string;
  sx?: any;
  startIcon?: React.ReactNode;
  color?: any;
  xs?: any;
  disabled?: boolean;
  title?: string;
}

// Button Component
const CustomButton = (props: Props): React.ReactElement => {
  const { onClick, label, variant, sx, startIcon, color, disabled, title } =
    props;
  return (
    <Button
      variant={variant}
      onClick={onClick}
      color={color}
      sx={sx}
      startIcon={startIcon}
      disabled={disabled}
      title={title}
    >
      {label}
    </Button>
  );
};

export default CustomButton;
