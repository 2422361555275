import axios from "axios";
import AuthService from "./authService";

const authService = new AuthService();

export const axiosInstance = axios.create({
  url: window.__RUNTIME_CONFIG__?.VITE_BASE_URL ?? "",
});
export const cancelAxios = axios.CancelToken;

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    return config;
  },
  async function (error: any) {
    return await Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error: any) {
    if (error?.response?.status === 401) {
      authService?.signoutRedirectCallback();
    }
    return await Promise.reject(error);
  }
);

// import axios from 'axios';
// import { useEffect } from 'react'
// import { useNavigate } from 'react-router-dom'
// import AuthService from './authService';

// const axiosInstance = axios.create()
// const cancelAxios = axios.CancelToken

// const AxiosInterceptor = ({ children }: any) => {

//     const navigate = useNavigate();
//     const authService = new AuthService();
//     let requestArray: any = []

//     useEffect(() => {

//         const resInterceptor = async (response: any) => {
//             return await response;
//         }

//         const errInterceptor = async (error: any) => {
//             const originalRequest = error.config;
//             requestArray.push(originalRequest)
//             return Promise.reject(error);
//         }

//         const interceptor = axiosInstance.interceptors.response.use(resInterceptor, errInterceptor);

//         return () => axiosInstance.interceptors.response.eject(interceptor);

//     }, [navigate])

//     return children;
// }

// export { cancelAxios, axiosInstance };
// export { AxiosInterceptor }
