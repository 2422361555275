// material-ui
import { Box, Typography } from "@mui/material";
import moment from "moment";
import type { RefObject } from "react";
import React, { useEffect, useRef } from "react";

// assets

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Clock = (): React.ReactElement => {
  const ref = useRef<any>(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = moment();
      const formattedTime = currentTime.format("HH:mm:ss");
      const dayOfWeek = currentTime.format("dddd"); // Get the day of the week

      if (ref.current !== null) {
        ref.current.innerHTML = `${dayOfWeek}, ${formattedTime}`;
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <>
      <Box
        sx={{
          width: "19rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Typography
          variant="h6"
          color="#D42C2C"
          fontWeight={500}
          paddingRight={2}
          className="dayTimeWrapper"
          ref={ref}
        />
      </Box>
    </>
  );
};

export default Clock;
