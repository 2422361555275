import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Icon,
  Typography,
} from "@mui/material";
import useQuery from "../../../hooks/useQuery";
import { UserProfileService } from "../../../services/intranet-portal-services";
import useIntranetPortalCoreApi from "../../../hooks/useIntranetPortalAdminApi";
import { useEffect, useState } from "react";
import {
  MdChairAlt,
  MdOutlineCorporateFare,
  MdOutlineEmail,
  MdOutlinePerson,
  MdOutlinePhone,
} from "react-icons/md";
import usePageTracking from "../../../utils/withPageTracking";

export const loaderStyle = {
  width: "100%",
  maxWidth: 360,
  bgcolor: "background.paper",
  align: "center",
};

const EmployeeDetails = (): React.ReactElement => {
  const { handleRequest, isLoading } = useIntranetPortalCoreApi();
  const params = useQuery();
  const id: any = params.get("id");
  const [employeeDetails, setEmployeeDetails] = useState<any>();

  const getEmployeeFullProfile = async (): Promise<void> => {
    await handleRequest(
      UserProfileService.getCoreApiApiAppUserProfileUserProfileById(id)
    ).then((response) => {
      if (response != null) {
        setEmployeeDetails(response);
      }
    });
  };

  useEffect(() => {
    void getEmployeeFullProfile();
  }, [id]);
  usePageTracking();
  return (
    <>
      <Box>
        {isLoading ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="primary" sx={loaderStyle} />
          </Grid>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card sx={{ mb: "1rem" }} elevation={1}>
                  <CardHeader
                    title={
                      <>
                        <Grid item xs={12} sx={{ display: "flex" }}>
                          <Icon color="primary" sx={{ mr: "5px", mt: "-1px" }}>
                            <MdOutlinePerson size="2rem" />
                          </Icon>
                          <Box>
                            {employeeDetails?.name} {employeeDetails?.surName}
                          </Box>
                        </Grid>
                      </>
                    }
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className="imageAndName "
                        >
                          <Avatar
                            variant="circular"
                            src={`http://hr.amniltech.com/Uploads/${employeeDetails?.profilePictureUrl as string
                              }`}
                            // src={`data:image/png;base64,https://hr.amniltech.com/Uploads/${employeeDetails?.profilePictureUrl}`}
                            sx={{
                              width: "186px",
                              height: "186px",
                            }}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} className=" myProfileDetailCol">
                        <div className="connectAcountInfo bg-white">
                          <div className="accountInfo">
                            <Box className="d-flex flex-column">
                              <Box component="div" sx={{ mb: "2rem" }}></Box>
                              <Box>
                                <Grid
                                  container
                                  spacing={1}
                                  sx={{ mb: "1rem" }}
                                  alignItems={"flex-end"}
                                >
                                  <Grid item xs={5}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                      }}
                                    >
                                      <Icon color="primary" sx={{ mr: "8px" }}>
                                        <MdOutlineEmail size="2rem" />
                                      </Icon>
                                      <Typography
                                        variant="body1"
                                        sx={{ fontWeight: "500" }}
                                      >
                                        Email
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Box>
                                      <a
                                        href={"https://gmail.com"}
                                        target="blank"
                                      >
                                        <Box sx={{ wordBreak: "break-word" }}>
                                          {employeeDetails?.email}
                                        </Box>
                                      </a>
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Grid
                                  container
                                  spacing={2}
                                  sx={{ mb: "1rem" }}
                                  alignItems={"flex-end"}
                                >
                                  <Grid item xs={5}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                      }}
                                    >
                                      <Icon color="primary" sx={{ mr: "8px" }}>
                                        <MdOutlinePhone size="2rem" />
                                      </Icon>
                                      <Typography
                                        variant="body1"
                                        sx={{ fontWeight: "500" }}
                                      >
                                        Phone Number
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={7}
                                    className="col-lg-8 col-md-6 "
                                  >
                                    <Box>
                                      {employeeDetails?.phoneNumber != null
                                        ? employeeDetails.phoneNumber
                                        : "--"}
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Grid
                                  container
                                  spacing={2}
                                  sx={{ mb: "1rem" }}
                                  alignItems={"flex-end"}
                                >
                                  <Grid item xs={5}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                      }}
                                    >
                                      <Icon color="primary" sx={{ mr: "8px" }}>
                                        <MdChairAlt size="2rem" />
                                      </Icon>
                                      <Typography
                                        variant="body1"
                                        sx={{ fontWeight: "500" }}
                                      >
                                        Designation
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={7}
                                    className="col-lg-8 col-md-6 "
                                  >
                                    <Box>
                                      {employeeDetails?.designationName}
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Grid
                                  container
                                  spacing={2}
                                  alignItems={"flex-end"}
                                >
                                  <Grid item xs={5}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                      }}
                                    >
                                      <Icon color="primary" sx={{ mr: "8px" }}>
                                        <MdOutlineCorporateFare size="2rem" />
                                      </Icon>
                                      <Typography
                                        variant="body1"
                                        sx={{ fontWeight: "500" }}
                                      >
                                        Department
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={7}
                                    className="col-lg-8 col-md-6 "
                                  >
                                    <Box>{employeeDetails?.departmentName}</Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};
export default EmployeeDetails;
