import { Outlet } from "react-router-dom"

// project imports

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout: React.FC = () => (
  <>
    <Outlet />
  </>
)

export default MinimalLayout
