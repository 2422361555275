/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IntranetPortal_InternalApplications_Dtos_ChangeInternalApplicationOrderInputDto } from '../models/IntranetPortal_InternalApplications_Dtos_ChangeInternalApplicationOrderInputDto';
import type { IntranetPortal_InternalApplications_Dtos_ChangeInternalApplicationOrderOutputDto } from '../models/IntranetPortal_InternalApplications_Dtos_ChangeInternalApplicationOrderOutputDto';
import type { IntranetPortal_InternalApplications_Dtos_CreateInternalApplicationDto } from '../models/IntranetPortal_InternalApplications_Dtos_CreateInternalApplicationDto';
import type { IntranetPortal_InternalApplications_Dtos_InternalApplicationDto } from '../models/IntranetPortal_InternalApplications_Dtos_InternalApplicationDto';
import type { IntranetPortal_InternalApplications_Dtos_UpdateInternalApplicationDto } from '../models/IntranetPortal_InternalApplications_Dtos_UpdateInternalApplicationDto';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_InternalApplications_Dtos_ActiveInternalApplicationDto_IntranetPortal_Application_Contracts } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_InternalApplications_Dtos_ActiveInternalApplicationDto_IntranetPortal_Application_Contracts';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_InternalApplications_Dtos_InternalApplicationDto_IntranetPortal_Application_Contracts } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_InternalApplications_Dtos_InternalApplicationDto_IntranetPortal_Application_Contracts';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalApplicationService {

    /**
     * @param requestBody
     * @returns IntranetPortal_InternalApplications_Dtos_InternalApplicationDto Success
     * @throws ApiError
     */
    public static postCoreApiApiAppInternalApplicationInternalApplication(
        requestBody?: IntranetPortal_InternalApplications_Dtos_CreateInternalApplicationDto,
    ): CancelablePromise<IntranetPortal_InternalApplications_Dtos_InternalApplicationDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/internal-application/internal-application',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static deleteCoreApiApiAppInternalApplicationInternalApplication(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/core-api/api/app/internal-application/{id}/internal-application',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns IntranetPortal_InternalApplications_Dtos_InternalApplicationDto Success
     * @throws ApiError
     */
    public static putCoreApiApiAppInternalApplicationInternalApplication(
        id: string,
        requestBody?: IntranetPortal_InternalApplications_Dtos_UpdateInternalApplicationDto,
    ): CancelablePromise<IntranetPortal_InternalApplications_Dtos_InternalApplicationDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/core-api/api/app/internal-application/{id}/internal-application',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_InternalApplications_Dtos_ActiveInternalApplicationDto_IntranetPortal_Application_Contracts Success
     * @throws ApiError
     */
    public static getCoreApiApiAppInternalApplicationActiveInternalApplications(): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_InternalApplications_Dtos_ActiveInternalApplicationDto_IntranetPortal_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/internal-application/active-internal-applications',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns IntranetPortal_InternalApplications_Dtos_InternalApplicationDto Success
     * @throws ApiError
     */
    public static getCoreApiApiAppInternalApplicationInternalApplicationById(
        id: string,
    ): CancelablePromise<IntranetPortal_InternalApplications_Dtos_InternalApplicationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/internal-application/{id}/internal-application-by-id',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param searchKeyword
     * @param fromDate
     * @param toDate
     * @param sortType
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_InternalApplications_Dtos_InternalApplicationDto_IntranetPortal_Application_Contracts Success
     * @throws ApiError
     */
    public static getCoreApiApiAppInternalApplicationPagedAndSortedInternalApplicationList(
        searchKeyword?: string,
        fromDate?: string,
        toDate?: string,
        sortType?: string,
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
    ): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_InternalApplications_Dtos_InternalApplicationDto_IntranetPortal_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/internal-application/paged-and-sorted-internal-application-list',
            query: {
                'SearchKeyword': searchKeyword,
                'FromDate': fromDate,
                'ToDate': toDate,
                'SortType': sortType,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns IntranetPortal_InternalApplications_Dtos_ChangeInternalApplicationOrderOutputDto Success
     * @throws ApiError
     */
    public static postCoreApiApiAppInternalApplicationChangeInternalApplicationOrder(
        requestBody?: IntranetPortal_InternalApplications_Dtos_ChangeInternalApplicationOrderInputDto,
    ): CancelablePromise<Array<IntranetPortal_InternalApplications_Dtos_ChangeInternalApplicationOrderOutputDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/internal-application/change-internal-application-order',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData
     * @returns string Success
     * @throws ApiError
     */
    public static postCoreApiApiAppInternalApplicationUploadLogo(
        formData?: {
            logo?: Blob;
        },
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/internal-application/upload-logo',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
