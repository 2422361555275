// import Head from 'next/head';
import { useState, useEffect, useMemo } from "react";
import {
  Box,
  CircularProgress,
  TableContainer,
  Card,
  CardContent,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
// Project import
import { DepartmentService } from "../../../services/intranet-portal-services";
import Grid from "@mui/material/Grid";
// Third party import
import CustomTable from "../../../ui-component/CustomUiComponent/TableContainer";
import useIntranetPortalCoreApi from "../../../hooks/useIntranetPortalAdminApi";
import CustomTypography from "../../../ui-component/CustomUiComponent/Typography";
import { type Row } from "react-table";
import usePageTracking from "../../../utils/withPageTracking";
import TestComp from "./TestComp";

// const style = {
//   position: "absolute" as const,
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 600,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// }
const loaderStyle = {
  width: "100%",
  maxWidth: 360,
  bgcolor: "background.paper",
  align: "center",
};

export const colsDd = [{ heading: "S.No" }, { heading: "Name" }];

const Departments = (): React.ReactElement => {
  const { handleRequest, isLoading } = useIntranetPortalCoreApi();

  const [department, setDepartment] = useState<any>([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row }: { row: Row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        accessor: "name",
        Header: "Name",
      },
    ],
    []
  );

  usePageTracking();
  const getDepartments = async (): Promise<void> => {
    await handleRequest(
      DepartmentService.getCoreApiApiAppDepartmentActiveDepartments()
    ).then((response) => {
      if (response != null) {
        setDepartment(response);
      }
    });
  };

  const filterData = (): any => {
    const filterResult = department?.items?.filter((item: any) => {
      if (searchKeyword.length > 0) {
        return item?.name.toLowerCase().includes(searchKeyword?.toLowerCase());
      }
    });
    setSearchResult(filterResult);
  };

  useEffect(() => {
    void getDepartments();
    filterData();
  }, [searchKeyword]);

  return (
    <>
      <Box>
        <Grid container spacing={2} mb={2} alignItems={"center"}>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              variant="h5"
              label={"Departments"}
              sx={{ fontWeight: "500", color: "#D42C2C" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Search"
              margin="none"
              name="search"
              type="text"
              value={searchKeyword}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              autoComplete="off"
              size="small"
            />
          </Grid>
        </Grid>
      </Box>
      <Card sx={{ mb: "1rem" }} elevation={1}>
        <CardContent sx={{ p: 2 }}>
          <Box component={Paper}>
            <TableContainer component={Paper} sx={{ height: 550 }}>
              {isLoading ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress color="primary" sx={loaderStyle} />
                </Grid>
              ) : (
                <>
                  {department?.items?.length > 0 ? (
                    searchKeyword?.length > 0 ? (
                      searchResult?.length > 0 ? (
                        <CustomTable
                          columns={columns}
                          data={searchResult}
                        ></CustomTable>
                      ) : (
                        <span style={{ alignContent: "center" }}>
                          No Record Available
                        </span>
                      )
                    ) : (
                      <CustomTable
                        columns={columns}
                        data={department?.items}
                      ></CustomTable>
                    )
                  ) : (
                    <span style={{ alignContent: "center" }}>
                      No Record Available
                    </span>
                  )}
                </>
              )}
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
      {/* <TestComp dd={"hi"} /> */}
    </>
  );
};

export default Departments;
