import { useState, useEffect, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import {
  Box,
  Card,
  styled,
  CardContent,
  Typography,
  Button,
  Grid,
  TextField,
  useTheme,
  FormHelperText,
  useMediaQuery,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from "@mui/material"

// project imports
import AnimateButton from "../../../ui-component/extended/AnimateButton"
import useQuery from "../../../hooks/useQuery"
import { DocumentService } from "../../../services/intranet-portal-services"

// third party
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded"
import { ErrorMessage, Form, Formik } from "formik"
import { toast } from "react-toastify"
import { useDropzone } from "react-dropzone"
import usePageTracking from "../../../utils/withPageTracking"

const ReuploadDocument = (): React.ReactElement => {
  const navigate = useNavigate()
  const theme = useTheme<any>()
  const [files, setFiles] = useState<any>([])
  const [getEditDocumentData, setGetEditDocumentData] = useState<any>()
  const [addFileResponse, setAddFileResponse] = useState<any>()
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"))

  const params = useQuery()
  const id: any = params.get("id")

  const initialState = {
    documentId: id,
    documentUrl: getEditDocumentData?.documentUrl ?? "",
  }

  usePageTracking()
  const handleDocumentApp = async (val: any): Promise<void> => {
    const formData: any = {
      documentId: id,
      documentUrl: addFileResponse,
    }
    try {
      const addresponse: any = await DocumentService.postCoreApiApiAppDocumentReuploadDocument(
        formData
      )
      if (addresponse != null) {
        toast.success("Document reuploaded successfully")
        navigate(-1)
      }
    } catch (e: any) {
      if (e.status != null) {
        toast.error(e.status)
      } else if (e?.body?.error?.validationErrors != null) {
        toast.error(e?.body?.error?.validationErrors)
      } else if (e.message != null) {
        toast.error(e.message)
      } else {
        toast.error("Unable to fetch api")
      }
    }
  }

  const fetchEditData = async (): Promise<void> => {
    try {
      const editResponse = await DocumentService.getCoreApiApiAppDocumentDocumentById(id)
      setGetEditDocumentData(editResponse)
    } catch (e: any) {
      if (e.status != null) {
        toast.error(e.status)
      } else if (e?.body?.error?.validationErrors != null) {
        toast.error(e?.body?.error?.validationErrors)
      } else if (e.message != null) {
        toast.error(e.message)
      } else {
        toast.error("Unable to fetch api")
      }
    }
  }

  useEffect(() => {
    if (id != null) {
      void fetchEditData()
    }
  }, [])

  const fileInitialState = {
    file: "",
  }

  const handleDocumentLogo = async (val: any): Promise<void> => {
    try {
      const formDataFile: any = {
        file: val,
      }
      const addFileResponse: any = await DocumentService.postCoreApiApiAppDocumentUploadDocument(
        formDataFile
      )
      if (addFileResponse != null) {
        setAddFileResponse(addFileResponse)
        toast.success("File uploaded successfully")
      }
    } catch (e: any) {
      if (e.status != null) {
        toast.error(e.status)
      } else if (e?.body?.error?.validationErrors != null) {
        toast.error(e?.body?.error?.validationErrors)
      } else if (e.message != null) {
        toast.error(e.message)
      } else {
        toast.error("Unable to fetch api")
      }
    }
  }

  const onDrop = useCallback((acceptedFiles: any): void => {
    const logo = acceptedFiles
    handleDocumentLogo(logo)
      .then(() => {
        if (acceptedFiles?.length != null) {
          setFiles((previousFiles: any) => [
            // ...previousFiles,
            ...acceptedFiles.map((file: any) =>
              Object.assign(file, { preview: URL.createObjectURL(file) })
            ),
          ])
        }
      })
      .catch((error) => {
        // Handle error if necessary
        console.error("Error handling document logo:", error)
      })
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    },
    maxSize: 1024 * 1000,
  })
  const removeFile = (name: string): void => {
    setFiles((files: any) => files.filter((file: any) => file.name !== name))
  }

  const Dropzone = styled("div")({
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "2px",
    borderRadius: "2px",
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    "&:hover": {
      borderColor: "#c62828",
    },
  })

  return (
    <>
      <Box>
        <Grid container spacing={2} mb={2} alignItems={"center"}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: 500, color: "#D42C2C" }}>
              Reupload Document
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            {/* Logo Upload Area of document app */}
            <Formik
              initialValues={fileInitialState}
              onSubmit={handleDocumentLogo}
              enableReinitialize={true}
            >
              {({ setFieldValue }) => (
                <Form>
                  <div {...getRootProps()}>
                    <Dropzone>
                      <input {...getInputProps()} />
                      {/* {isDragActive ? (
                          <p>Drop the files here ...</p>
                        ) : ( */}
                      <p>click to select files</p>
                      {/* )} */}
                    </Dropzone>
                    <div>
                      <>
                        <ImageList sx={{}} cols={8}>
                          {files?.map((file: any) => (
                            <ImageListItem key={file.name}>
                              <img
                                src={file.preview}
                                alt={file.name}
                                width={100}
                                height={100}
                                onLoad={() => {
                                  URL.revokeObjectURL(file.preview)
                                }}
                                loading="lazy"
                              />
                              <ImageListItemBar
                                sx={{
                                  background:
                                    "linear-gradient(to top, rgba(0,0,0,0.7) 0%, " +
                                    "rgba(0,0,0,0.6) 70%, rgba(0,0,0,0) 100%)",
                                }}
                                position="bottom"
                                actionIcon={
                                  <IconButton sx={{ color: "white" }}>
                                    <HighlightOffRoundedIcon sx={{ fontSize: "24px" }} />
                                  </IconButton>
                                }
                                actionPosition="right"
                                onClick={() => {
                                  removeFile(file.name)
                                }}
                              />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      </>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            {/* Form post of internal app */}
            <Formik
              initialValues={
                id != null
                  ? {
                      documentUrl: getEditDocumentData?.documentUrl ?? "",
                    }
                  : initialState
              }
              onSubmit={handleDocumentApp}
              enableReinitialize={id}
            >
              {({ handleChange }) => (
                <Form noValidate>
                  <Grid container spacing={matchDownSM ? 0 : 2} marginY={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={addFileResponse == null ? "" : "Document Url"}
                        margin="none"
                        name="documentUrl"
                        type="text"
                        value={addFileResponse}
                        onChange={handleChange}
                        autoComplete="off"
                        variant="outlined"
                        disabled
                      />
                      <FormHelperText error id="standard-weight-helper-text--register">
                        <ErrorMessage name="documentUrl" />
                      </FormHelperText>
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 2 }}>
                    <AnimateButton>
                      <Button
                        disableElevation
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="error"
                      >
                        Reupload
                      </Button>
                    </AnimateButton>
                  </Box>
                </Form>
              )}
            </Formik>
            <AnimateButton>
              <Button
                sx={{ mt: 2 }}
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="outlined"
                color="error"
                onClick={() => {
                  navigate(-1)
                }}
              >
                Back To the list
              </Button>
            </AnimateButton>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}
export default ReuploadDocument
