import { createRoot } from "react-dom/client"
import React from "react"

// third party
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"

// project imports
import * as serviceWorker from "./serviceWorkers"
import App from "./App"

// style + assets
import "../src/assets/scss/style.scss"
import config from "./config"
import { store } from "./store"

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById("root")
const root = createRoot(container!)
root.render(
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <App />
    </BrowserRouter>
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to registerF() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
