/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IntranetPortal_EmployeeMoods_Dtos_ChangeMoodOrderInputDto } from '../models/IntranetPortal_EmployeeMoods_Dtos_ChangeMoodOrderInputDto';
import type { IntranetPortal_EmployeeMoods_Dtos_ChangeMoodOrderOutputDto } from '../models/IntranetPortal_EmployeeMoods_Dtos_ChangeMoodOrderOutputDto';
import type { IntranetPortal_EmployeeMoods_Dtos_CreateMoodDto } from '../models/IntranetPortal_EmployeeMoods_Dtos_CreateMoodDto';
import type { IntranetPortal_EmployeeMoods_Dtos_CreateMoodInputDto } from '../models/IntranetPortal_EmployeeMoods_Dtos_CreateMoodInputDto';
import type { IntranetPortal_EmployeeMoods_Dtos_GetActiveMoodDto } from '../models/IntranetPortal_EmployeeMoods_Dtos_GetActiveMoodDto';
import type { IntranetPortal_EmployeeMoods_Dtos_GetMoodDto } from '../models/IntranetPortal_EmployeeMoods_Dtos_GetMoodDto';
import type { IntranetPortal_EmployeeMoods_Dtos_GetMyMoodTodayDto } from '../models/IntranetPortal_EmployeeMoods_Dtos_GetMyMoodTodayDto';
import type { IntranetPortal_EmployeeMoods_Dtos_SetEmployeeMoodInputDto } from '../models/IntranetPortal_EmployeeMoods_Dtos_SetEmployeeMoodInputDto';
import type { IntranetPortal_EmployeeMoods_Dtos_UpdateMoodDto } from '../models/IntranetPortal_EmployeeMoods_Dtos_UpdateMoodDto';
import type { IntranetPortal_EmployeeMoods_Dtos_UpdateMoodInputDto } from '../models/IntranetPortal_EmployeeMoods_Dtos_UpdateMoodInputDto';
import type { IntranetPortal_Responses_ResponseDto } from '../models/IntranetPortal_Responses_ResponseDto';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_EmployeeMoods_Dtos_GetEmployeeMoodCountDto_IntranetPortal_Application_Contracts } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_EmployeeMoods_Dtos_GetEmployeeMoodCountDto_IntranetPortal_Application_Contracts';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmployeeMoodService {

    /**
     * @param requestBody
     * @returns IntranetPortal_EmployeeMoods_Dtos_CreateMoodDto Success
     * @throws ApiError
     */
    public static postCoreApiApiAppEmployeeMoodMood(
        requestBody?: IntranetPortal_EmployeeMoods_Dtos_CreateMoodInputDto,
    ): CancelablePromise<IntranetPortal_EmployeeMoods_Dtos_CreateMoodDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/employee-mood/mood',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns IntranetPortal_EmployeeMoods_Dtos_GetMoodDto Success
     * @throws ApiError
     */
    public static getCoreApiApiAppEmployeeMoodMood(): CancelablePromise<Array<IntranetPortal_EmployeeMoods_Dtos_GetMoodDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/employee-mood/mood',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns IntranetPortal_EmployeeMoods_Dtos_UpdateMoodDto Success
     * @throws ApiError
     */
    public static putCoreApiApiAppEmployeeMoodMood(
        id: string,
        requestBody?: IntranetPortal_EmployeeMoods_Dtos_UpdateMoodInputDto,
    ): CancelablePromise<IntranetPortal_EmployeeMoods_Dtos_UpdateMoodDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/core-api/api/app/employee-mood/{id}/mood',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns IntranetPortal_Responses_ResponseDto Success
     * @throws ApiError
     */
    public static deleteCoreApiApiAppEmployeeMoodMood(
        id: string,
    ): CancelablePromise<IntranetPortal_Responses_ResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/core-api/api/app/employee-mood/{id}/mood',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns IntranetPortal_EmployeeMoods_Dtos_GetActiveMoodDto Success
     * @throws ApiError
     */
    public static getCoreApiApiAppEmployeeMoodActiveMoods(): CancelablePromise<Array<IntranetPortal_EmployeeMoods_Dtos_GetActiveMoodDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/employee-mood/active-moods',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns IntranetPortal_EmployeeMoods_Dtos_GetMyMoodTodayDto Success
     * @throws ApiError
     */
    public static getCoreApiApiAppEmployeeMoodMyMoodForToday(): CancelablePromise<IntranetPortal_EmployeeMoods_Dtos_GetMyMoodTodayDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/employee-mood/my-mood-for-today',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns IntranetPortal_EmployeeMoods_Dtos_GetMoodDto Success
     * @throws ApiError
     */
    public static getCoreApiApiAppEmployeeMoodMoodById(
        id: string,
    ): CancelablePromise<IntranetPortal_EmployeeMoods_Dtos_GetMoodDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/employee-mood/{id}/mood-by-id',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns IntranetPortal_Responses_ResponseDto Success
     * @throws ApiError
     */
    public static postCoreApiApiAppEmployeeMoodSetEmployeeMood(
        requestBody?: IntranetPortal_EmployeeMoods_Dtos_SetEmployeeMoodInputDto,
    ): CancelablePromise<IntranetPortal_Responses_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/employee-mood/set-employee-mood',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_EmployeeMoods_Dtos_GetEmployeeMoodCountDto_IntranetPortal_Application_Contracts Success
     * @throws ApiError
     */
    public static getCoreApiApiAppEmployeeMoodEmployeesMoodsCountForToday(): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_EmployeeMoods_Dtos_GetEmployeeMoodCountDto_IntranetPortal_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/employee-mood/employees-moods-count-for-today',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData
     * @returns string Success
     * @throws ApiError
     */
    public static postCoreApiApiAppEmployeeMoodUploadMoodLogo(
        formData?: {
            logo?: Blob;
        },
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/employee-mood/upload-mood-logo',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns IntranetPortal_EmployeeMoods_Dtos_ChangeMoodOrderOutputDto Success
     * @throws ApiError
     */
    public static postCoreApiApiAppEmployeeMoodChangeMoodOrder(
        requestBody?: IntranetPortal_EmployeeMoods_Dtos_ChangeMoodOrderInputDto,
    ): CancelablePromise<Array<IntranetPortal_EmployeeMoods_Dtos_ChangeMoodOrderOutputDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/employee-mood/change-mood-order',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
