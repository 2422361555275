// material-ui
import { CircularProgress, Grid, Typography } from "@mui/material"

// project imports
import { isEmptyArray } from "formik"
import { isEmpty } from "lodash"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { isUndefined } from "util"
import menuItem from "../../../../menu-items"
import { childRouteType, routeType } from "../../../../routes/MainRoute"
import { getFilteredPermissionData } from "../../../../utils/permissionFunction"
import { loaderStyle } from "../../../../views/pages/employeeDetails"
import NavGroup from "./NavGroup"

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const [navItemList, setNavItemList] = useState<routeType[]>()

  const userPermissions = useSelector((state: any) => state.user?.permission)
  const { intranetPermissionsGroups } = getFilteredPermissionData(userPermissions ?? {})

  // Get detail about current logged in user
  const { currentUser } = useSelector((state: any) => state?.user?.userRole)

  const defaultAdminRoute = ['Dashboard', 'AllEmployee', 'Departments', 'Designations', 'RequestForMe']
  const defaultUserRoute = [...defaultAdminRoute, 'RequestForMe']

  //Set Permission For User
  const availablePermission = currentUser !== undefined ?
    isEmpty(currentUser?.roles)
      ? [...Object?.keys(intranetPermissionsGroups), ...defaultUserRoute]
      : [...Object?.keys(intranetPermissionsGroups), ...defaultAdminRoute]
    : []

  // Example permission condition function
  const hasPermission = (child: childRouteType) => {
    return (
      availablePermission?.includes(child?.guardName)
    )
  }

  // Recursive function to filter children based on permission and map the parent
  const filterAndMapItems = (items: routeType[]) => {
    return items.map((item: routeType) => {
      if (item.children && Array.isArray(item.children)) {
        // Filter children based on permission condition
        const filteredChildren = item.children.filter(hasPermission)

        // Map the parent item with filtered children
        const mappedItem = {
          ...item,
          children: filteredChildren,
        }

        return mappedItem
      } else {
        // If there are no children or children is not an array, return the original item
        return item
      }
    })
  }

  useEffect(() => {
    const childData = filterAndMapItems(menuItem?.items)
    setNavItemList(childData)
  }, [currentUser])

  const navItems = currentUser === undefined || navItemList === undefined
    ? (<Grid
      container
      display='flex'
      flexDirection='column'
      height="100%"
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="primary" sx={loaderStyle} />
    </Grid>)
    : navItemList.map((item: routeType) => {
      switch (!isEmptyArray(item)) {
        case true:
          return <NavGroup key={item.id} item={item} />
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          )
      }
    })

  // const navItems = menuItem?.items.map((item: any) => {
  //   const childItem = filterAndMapItems(menuItem?.items)

  //   switch (!isEmptyArray(childItem)) {
  //     case true:
  //       return <NavGroup key={item.id} item={childItem} />
  //     // default:
  //     //   return (
  //     //     <Typography key={item.id} variant="h6" color="error" align="center">
  //     //       Menu Items Error
  //     //     </Typography>
  //     //   )
  //   }
  // })

  return <>{navItems}</>
}

export default MenuList
