import CustomButton from "../../../ui-component/CustomUiComponent/Button";

import MaterialTable from "material-table";
import ModalComponent from "../../../ui-component/modal";
import { DialogActions, Typography } from "@mui/material";

const MaterialTableStyle = {
  marginTop: "3rem",
  paddingInlineStart: "1.5rem",
  boxShadow: "none",
};
interface Props {
  open: any;
  onHide: () => void;
  employeeData: any;
  column: any;
  groupSubmit?: any;
  getEmployee?: any;
  selectedEmployee?: any;
}
const EmployeeModal = (props: Props): React.ReactElement => {
  // const classes = useStyles();
  const {
    open,
    onHide,
    column,
    employeeData,
    groupSubmit,
    getEmployee,
    selectedEmployee,
  } = props;

  return (
    <ModalComponent
      open={open}
      handleClose={onHide}
      showClose={true}
      maxWidth="xl"
    >
      <MaterialTable
        style={MaterialTableStyle}
        columns={column}
        data={employeeData}
        title={
          <Typography sx={{ color: "#d82d2b" }} variant={"h6"}>
            Select the employee you want to send document
          </Typography>
        }
        options={{
          grouping: true,
          selection: true,
          headerStyle: { position: "sticky", top: 0 },
          maxBodyHeight: "420px",
          pageSize: 10,
          pageSizeOptions: [10, 20, 50, 100],
          paginationType: "stepped",
          tableLayout: "fixed",
          showSelectAllCheckbox: true,
          filtering: true,
          loadingType: "linear",
        }}
        onSelectionChange={(e) => {
          selectedEmployee(e.map((item: any) => item?.id));
        }}
        // parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
        // parentChildData={(row, rows) => rows.find( row.departmentId)}
      />
      <DialogActions sx={{ mt: "0.8rem", mb: "0.5rem" }}>
        <CustomButton
          label={"Close"}
          variant={"contained"}
          onClick={() => onHide()}
        />
        <CustomButton
          label={"Send Request"}
          variant={"contained"}
          onClick={() => {
            groupSubmit();
            getEmployee();
            onHide();
          }}
        />
      </DialogActions>
    </ModalComponent>
  );
};

export default EmployeeModal;
