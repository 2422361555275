import { Link } from "react-router-dom";

// material-ui
import { ButtonBase } from "@mui/material";

// project imports
import config from "../../../config";
// import Logo from '../../../ui-component/Logo';
// import Logo from "../../../../src/assets/images/amnillogomodify.png"
import Logo from "../../../../src/assets/images/amniverseLogo/Amniverse-1.svg";
import React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/reducer";
import { MENU_OPEN } from "../../../store/actions";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = (): React.ReactElement => {
  const dispatch: AppDispatch = useDispatch();
  return (
    <ButtonBase
      disableRipple
      component={Link}
      to={"/"}
      onClick={() => dispatch({ type: MENU_OPEN, id: "dashboardContent" })}
    >
      {/* <Logo /> */}
      {/* {Logo} */}
      <img src={Logo} height={45} alt="Amnil intranet" />
    </ButtonBase>
  );
};
export default LogoSection;
