import {
  type ChangeEvent,
  Fragment,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Paper,
  TableContainer,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import CustomTypography from "../../../ui-component/CustomUiComponent/Typography";
import SelectOption from "../../../ui-component/CustomUiComponent/SelectOption";
import debounce from "lodash.debounce";
import CustomButton from "../../../ui-component/CustomUiComponent/Button";
import useIntranetPortalCoreApi from "../../../hooks/useIntranetPortalAdminApi";
import {
  DepartmentService,
  DesignationService,
  EmployeeService,
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Departments_Dtos_DepartmentDto_IntranetPortal_Application_Contracts,
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Designations_Dtos_ActiveDesignationDto_IntranetPortal_Application_Contracts,
  type Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Employees_Dtos_EmployeeDto_IntranetPortal_Application_Contracts,
} from "../../../services/intranet-portal-services";
import moment from "moment";
import CustomTable from "../../../ui-component/CustomUiComponent/TableContainer";
import CustomPagination from "../../../ui-component/CustomUiComponent/Pagination";
import { useNavigate } from "react-router-dom";
import { type Row } from "react-table";
import usePageTracking from "../../../utils/withPageTracking";
import { format } from "date-fns";
const loaderStyle = {
  width: "100%",
  maxWidth: 360,
  bgColor: "background.paper",
  align: "center",
};

const AllEmployee = (): React.ReactElement => {
  const navigate = useNavigate();
  const { handleRequest, isLoading } = useIntranetPortalCoreApi();
  const [data, setData] =
    useState<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Employees_Dtos_EmployeeDto_IntranetPortal_Application_Contracts>();
  const [desgination, setDesignation] =
    useState<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Designations_Dtos_ActiveDesignationDto_IntranetPortal_Application_Contracts>();
  const [department, setDepartment] =
    useState<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfIntranetPortal_Departments_Dtos_DepartmentDto_IntranetPortal_Application_Contracts>();

  const [searchKeyword, setSearchKeyword] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [departmentId, setDepartmentId] = useState<any>(null);
  const [designationId, setDesignationId] = useState<any>(null);
  const [sorting, setSorting] = useState("");
  const [skipCount, setSkipCount] = useState(0);
  const [maxResultCount, setMaxResultCount] = useState(10);

  const [page, setPage] = useState(0);

  usePageTracking();

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row }: { row: Row }) => {
          return <>{skipCount + row.index + 1}</>;
        },
      },
      {
        Header: "",
        accessor: "profilePictureUrl",
        Cell: ({ row }: any) => {
          const { profilePictureUrl, email } = row?.original;
          return profilePictureUrl != null ? (
            <Avatar
              variant="circular"
              src={`http://hr.amniltech.com/Uploads/${profilePictureUrl}`}
              sx={{
                width: "30px",
                height: "30px",
              }}
            />
          ) : (
            <Avatar
              variant="circular"
              sx={{
                width: "30px",
                height: "30px",
                fontSize: "1.5rem",
                color: "#1e88e5",
                textTransform: "uppercase",
                fontWeight: "500",
              }}
            >
              {email.charAt(0)}
            </Avatar>
          );
        },
      },
      {
        Header: "Name",
        accessor: "fullName",
        Cell: ({ row }: any) => {
          return row?.original?.name != null ? (
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/pages/employee-details?id=${
                    row.original.abpUserId as string
                  }`
                );
              }}
            >
              {row.original.fullName}
            </Typography>
          ) : (
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/pages/employee-details?id=${
                    row.original.abpUserId as string
                  }`
                );
              }}
              variant="caption"
            >
              -------------------
            </Typography>
          );
        },
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
        Cell: ({ row }: any) => {
          const { phoneNumber } = row?.original;
          return phoneNumber == null || phoneNumber?.length < 10 ? (
            <Typography variant="caption">-------------------</Typography>
          ) : (
            row.original.phoneNumber
          );
        },
      },
      {
        Header: "Department",
        accessor: "departmentName",
        hidden: true,
      },
      {
        Header: "Designation",
        accessor: "designationName",
        hidden: true,
      },
      {
        Header: "Hired Date",
        accessor: "hiredDate",
        hidden: true,
      },
    ],
    [skipCount]
  );

  // GET EMPLOYEE DATA
  const getEmployeeAcknowledgementRequestData = async (): Promise<void> => {
    await handleRequest(
      EmployeeService.getCoreApiApiAppEmployeePagedAndSortedEmployeeList(
        searchKeyword,
        sortType,
        departmentId,
        designationId,
        sorting,
        skipCount,
        maxResultCount
      )
    ).then((response) => {
      response !== null && setData(response);
    });
  };

  // GET DESIGNATION
  const getDesignations = async (): Promise<void> => {
    const response = await handleRequest(
      DesignationService.getCoreApiApiAppDesignationActiveDesignations()
    );
    setDesignation(response);
  };

  // GET DEPARTMENT
  const getDepartments = async (): Promise<void> => {
    const response = await handleRequest(
      DepartmentService.getCoreApiApiAppDepartmentActiveDepartments()
    );
    setDepartment(response);
  };

  // CALLING EVERY POSSIBLE API
  useEffect(() => {
    void getEmployeeAcknowledgementRequestData();
    void getDesignations();
    void getDepartments();
  }, [
    searchKeyword,
    sortType,
    departmentId,
    designationId,
    sorting,
    skipCount,
    maxResultCount,
  ]);

  const putInTableFirstPage = () => {
    setPage(0);
    setSkipCount(0);
  };

  // HANDLE ORDER
  const handleOrder = (sortOrder: any, id: string): void => {
    sortType === "asc" ? setSortType("desc") : setSortType("asc");
    setSorting(id);
  };

  // SEARCH
  const onSearchTermInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchKeyword(event.target.value);
    putInTableFirstPage();
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(onSearchTermInput, 500),
    []
  );

  // PAGINATION
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setMaxResultCount(Number(event.target.value));
    putInTableFirstPage();
  };

  const handlePageChange = (event: any, value: number): void => {
    setSkipCount(maxResultCount * value);
    setPage(value);
  };

  // CLEAR
  const handleSearchClear = (): void => {
    // clear input field
    const input = document.getElementsByTagName("input");
    for (let i = 0; i < input.length; i++) {
      input[i].value = "";
    }
    setSearchKeyword("");
    setDepartmentId(null);
    setDesignationId(null);
  };

  return (
    <Fragment>
      <Box>
        <Grid container spacing={2} mb={2} alignItems={"center"}>
          <Grid item xs={12}>
            <CustomTypography
              variant="h5"
              sx={{ fontWeight: "500", color: "#D42C2C" }}
              label={"All Employee"}
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid container gap={2} sx={{ py: "1rem" }}>
          <Grid item xs={12} sm={5} lg={3}>
            <TextField
              label="Search Employee Name"
              fullWidth
              size="small"
              id="filled-basic"
              onChange={debouncedChangeHandler}
            />
          </Grid>

          <Grid item xs={12} sm={5} lg={3}>
            <SelectOption
              label="Select Designation"
              name="designation"
              value={designationId != null ? designationId : ""}
              onChange={(event) => {
                setDesignationId(event.target.value);
                putInTableFirstPage();
              }}
              options={desgination?.items}
            />
          </Grid>
          <Grid item xs={12} sm={5} lg={3}>
            <SelectOption
              label="Select Department"
              name="department"
              value={departmentId != null ? departmentId : ""}
              onChange={(event) => {
                setDepartmentId(event.target.value);
                putInTableFirstPage();
              }}
              options={department?.items}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <CustomButton
              sx={{ ml: 2 }}
              xs={2}
              label={"Clear"}
              variant={"contained"}
              onClick={handleSearchClear}
            />
          </Grid>
        </Grid>
      </Box>

      <Card sx={{ mb: "1rem" }} elevation={1}>
        <CardContent sx={{ p: 2 }}>
          <Box component={Paper}>
            <TableContainer component={Paper} sx={{ height: 550 }}>
              {isLoading ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress color="primary" sx={loaderStyle} />
                </Grid>
              ) : (
                <>
                  {data?.items && data?.items?.length > 0 ? (
                    <CustomTable
                      columns={columns}
                      data={data?.items}
                      handleSort={handleOrder}
                    />
                  ) : (
                    <span style={{ alignContent: "center" }}>
                      No Record Avaliable
                    </span>
                  )}
                </>
              )}
            </TableContainer>
            {data?.items && data?.items.length > 0 && (
              <CustomPagination
                component={"div"}
                count={data?.totalCount ?? 0}
                onPageChange={(event, value) => handlePageChange(event, value)}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={maxResultCount}
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
              />
            )}
          </Box>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default AllEmployee;
