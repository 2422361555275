import React, { useState, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import debounce from "lodash.debounce"
import {
  Box,
  Modal,
  CircularProgress,
  Menu,
  MenuItem,
  TableContainer,
  Card,
  CardContent,
  Popover,
  Chip,
  ButtonBase,
  Typography,
  styled,
  useTheme,
  TextField,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import TuneIcon from "@mui/icons-material/Tune"
import FilterListIcon from "@mui/icons-material/FilterList"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import { makeStyles } from "@material-ui/core/styles"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state"
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
// Project import
import {
  AbpApplicationConfigurationService,
  InternalApplicationLogoService,
  InternalApplicationService,
} from "../../../services/intranet-portal-services"
import DatePickerCustom from "../../../ui-component/CustomUiComponent/DatePicker"
import CustomTypography from "../../../ui-component/CustomUiComponent/Typography"
import CustomButton from "../../../ui-component/CustomUiComponent/Button"
import CustomTable from "../../../ui-component/CustomUiComponent/TableContainer"
import DeleteConfirmationModal from "../../../ui-component/CustomUiComponent/DeleteConfirmationModal"
import CustomPagination from "../../../ui-component/CustomUiComponent/Pagination"
import useIntranetPortalCoreApi from "../../../hooks/useIntranetPortalAdminApi"
// Third party import
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { toast } from "react-toastify"
import moment from "moment"
import { type Row } from "react-table"
import usePageTracking from "../../../utils/withPageTracking"

const loaderStyle = {
  width: "100%",
  maxWidth: 360,
  bgcolor: "background.paper",
  align: "center",
}

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3, 2),
  },
}))

const InternalApplication = (): React.ReactElement => {
  const theme = useTheme()
  const { handleRequest, isLoading } = useIntranetPortalCoreApi()

  const [maxResultCount, setMaxResultCount] = useState(5)
  const [skipCount, setSkipCount] = useState(0)
  const [searchKeyword, setSearchKeyword] = useState("")
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  const [data, setData] = useState<any>()
  const [open, setOpen] = useState(false)
  const [sortOpen, setSortOpen] = useState(false)
  const [deleteId, setDeleteId] = useState("")
  const [sorting, setSorting] = useState("")
  const [sortType, setSortType] = useState("asc")
  const [page, setPage] = useState(0)
  const [permission, setPermission] = useState<string>()
  const [imageUrl, setImageUrl] = useState<any>()

  const classes = useStyles()

  // DELETE CONFIRMATION MODAL
  const handleOpen = (): void => {
    setOpen(true)
  }
  const handleClose = (): void => {
    setOpen(false)
  }

  // DRAGGABLE SORTING MODAL
  const handleSortOpen = (): void => {
    // getImage(data)
    setSortOpen(true)
  }
  const handleSortClose = (): void => {
    setSortOpen(false)
  }

  const navigate = useNavigate()
  usePageTracking()

  const getPermission = async (): Promise<void> => {
    await AbpApplicationConfigurationService.getCoreApiApiAbpApplicationConfiguration().then(
      (response) => {
        // Check if response and currentUser exist before accessing roles
        const firstRole = response?.currentUser?.roles?.[0] ?? undefined
        setPermission(firstRole)
      }
    )
  }
  const columns = [
    {
      Header: "S.No",
      Cell: ({ row }: { row: Row }) => {
        return <>{row.index + 1}</>
      },
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Description",
      accessor: "description",
    },

    {
      accessor: "applicationUrl",
      Header: "App Url",
      Cell: ({ row }: any) => {
        return (
          <a
            target="#blank"
            href={`${row.original.applicationUrl as string}`}
            style={{ textDecoration: "none" }}
          >
            <p>{row.original.applicationUrl}</p>
          </a>
        )
      },
    },
    {
      Header: "Status",
      accessor: "documentStatusDisplayName",
      Cell: ({ row }: any) => {
        return (
          <Chip
            size="small"
            label={row.original.isActive === true ? "Active" : "InActive"}
            sx={{
              fontSize: "1.2rem",
              background: row.original.isActive === true ? "#13A83D" : "#D42C2C",
              color: "white",
            }}
          />
        )
      },
    },
    {
      Header: "Action",
      Cell: ({ row }: any) => {
        return (
          <>
            {permission === "admin" && (
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <>
                    <MoreVertIcon {...bindTrigger(popupState)} />
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() => {
                          navigate(
                            `/pages/add-internal-application?id=${row?.original?.id as string}`
                          )
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleOpen()
                          setDeleteId(row.original.id)
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </PopupState>
            )}
          </>
        )
      },
    },
  ]

  // GET INTERNAL APPLICATION DATA
  const getInternalAppData = async (): Promise<void> => {
    await handleRequest(
      InternalApplicationService.getCoreApiApiAppInternalApplicationPagedAndSortedInternalApplicationList(
        searchKeyword,
        fromDate,
        toDate,
        sortType,
        sorting,
        skipCount,
        maxResultCount
      )
    ).then((response) => {
      if (response != null) {
        setData(response)
      }
    })
  }

  // HANDLE SORT
  const handleSort = async (
    srcId: string,
    desId: string,
    srcOrder: number,
    desOrder: number
  ): Promise<void> => {
    // try {
    const body = {
      id: [desId, srcId],
      orderNumber: [desOrder, srcOrder],
    }
    await handleRequest(
      InternalApplicationService.postCoreApiApiAppInternalApplicationChangeInternalApplicationOrder(
        body
      )
    ).then(async (response) => {
      if (response != null) {
        toast.success("Sorted Successfully.")
        await getInternalAppData()
      }
    })
    // } catch (error) {}
  }

  // DELETE
  const handleDelete = async (): Promise<void> => {
    await handleRequest(
      InternalApplicationService.deleteCoreApiApiAppInternalApplicationInternalApplication(deleteId)
    ).then(async (response) => {
      if (response != null) {
        await getInternalAppData()
        handleClose()
        toast.success("Deleted successfully")
      }
    })
  }

  const getImage = async (data: any): Promise<void> => {
    try {
      const imageResponses = await Promise.all(
        // Map through each item in data and get the image URL
        data?.items?.map((item: any) =>
          InternalApplicationLogoService.getCoreApiInternalApplicationLogosFileUncached(item?.id)
        )
      )
      // Set the imageUrls state to the array of image URLs
      setImageUrl(imageResponses)
      // setIsLoading(false);
    } catch (e: any) {
      if (e.status !== null) {
        toast.error(e.status)
      } else if (e?.body?.error?.validationErrors !== null) {
        toast.error(e?.body?.error?.validationErrors)
      } else if (e.message !== null) {
        toast.error(e.message)
      } else {
        toast.error("Unable to fetch api")
      }
    }
  }

  useEffect(() => {
    void getInternalAppData()
    void getPermission()
    if (data?.items != null) {
      void getImage(data)
    }
  }, [searchKeyword, fromDate, toDate, sortType, sorting, skipCount, maxResultCount])

  // HANDLE ORDER
  const handleOrder = (sortOrder: any, id: string): void => {
    sortType === "asc" ? setSortType("desc") : setSortType("asc")
    setSorting(id)
  }

  // SEARCH
  const onSearchTermInput = (e: any): void => {
    setSearchKeyword(e.target.value)
  }

  const debouncedChangeHandler = useMemo(() => debounce(onSearchTermInput, 500), [])

  const onSearchFromDate = (fromDate: any): void => {
    setFromDate(moment(fromDate.$d).format("YYYY-MM-DD"))
  }

  const debouncedFromDateChangeHandler = useMemo(() => debounce(onSearchFromDate, 500), [])

  const onSearchToDate = (toDate: any): void => {
    setToDate(moment(toDate.$d).format("YYYY-MM-DD"))
  }

  const debouncedToDateChangeHandler = useMemo(() => debounce(onSearchToDate, 500), [])

  // PAGINATION
  const handleLimitChange = (event: any): void => {
    setMaxResultCount(event.target.value)
  }

  const handlePageChange = (event: any, page: number): void => {
    setSkipCount(maxResultCount * page)
    setPage(page)
  }

  // CLEAR
  const handleSearchClear = (): void => {
    setFromDate(null as any)
    setToDate(null as any)
    setSearchKeyword(null as any)
    const input = document.getElementsByTagName("input")
    for (let i = 0; i < input.length; i++) {
      input[i].value = ""
    }
  }

  // filter popover
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(null)
  }

  const openPopover = Boolean(anchorEl)
  const id = openPopover ? "simple-popover" : undefined
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    padding: "0.5rem",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    borderRadius: 0,
    borderBottom: "1px solid #f5f5f5",
    justifyContent: "space-between",
  }))

  return (
    <>
      <Box>
        <Grid container spacing={2} mb={2} alignItems={"center"}>
          <Grid item xs={12} md={6}>
            <CustomTypography
              variant="h5"
              label={"Internal Application"}
              sx={{ fontWeight: "500", color: "#D42C2C" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",

                justifyContent: "flex-end",
                gap: "1rem",
              }}
            >
              <CustomButton
                label={"Reorder Internal Application"}
                variant={"outlined"}
                onClick={() => {
                  handleSortOpen()
                }}
                startIcon={<FilterListIcon fontSize="small" />}
                sx={{ height: "100%" }}
              />

              <CustomButton
                label={"Add Application"}
                variant={"contained"}
                onClick={() => {
                  navigate("/pages/add-internal-application")
                }}
                startIcon={<AddIcon fontSize="small" />}
                color="error"
                sx={{ height: "100%" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container gap={2} sx={{ py: "1rem" }}>
          <Grid item xs={12} sm={5} lg={4}>
            <TextField
              label="Search"
              fullWidth
              size="small"
              id="filled-basic"
              // placeholder="Search"
              onChange={debouncedChangeHandler}
            />
          </Grid>
          <Grid item sm={6} xs={12} gap={2}>
            <CustomButton
              label={"Filter"}
              variant={"contained"}
              aria-describedby={id}
              onClick={handlePopoverClick}
              startIcon={<TuneIcon fontSize="small" />}
              color="error"
              sx={{ height: "100%" }}
            />
            <Popover
              id={id}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Card>
                <CardContent sx={{ p: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <DatePickerCustom
                      label={"From Date"}
                      selected={fromDate}
                      onChange={debouncedFromDateChangeHandler}
                      maxDate
                    />

                    <DatePickerCustom
                      label={"To Date"}
                      selected={toDate}
                      onChange={debouncedToDateChangeHandler}
                      minDate={fromDate}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Popover>
            <CustomButton
              sx={{ ml: 2, height: "100%" }}
              label={"Clear"}
              variant={"contained"}
              onClick={handleSearchClear}
              color="error"
            />
          </Grid>
        </Grid>
      </Box>
      <Card sx={{ mb: "1rem" }} elevation={1}>
        <CardContent sx={{ p: 2 }}>
          <Box component={Paper}>
            <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
              {isLoading ? (
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <CircularProgress color="primary" sx={loaderStyle} />
                </Grid>
              ) : (
                <>
                  {data?.items !== null && data?.items?.length > 0 ? (
                    <>
                      <CustomTable
                        columns={columns}
                        data={data.items}
                        handleSort={handleOrder}
                      ></CustomTable>
                      <CustomPagination
                        component={"div"}
                        count={Number(data?.totalCount)}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleLimitChange}
                        page={page}
                        rowsPerPage={maxResultCount}
                        rowsPerPageOptions={[5, 10, 15, 20, 25]}
                      />
                    </>
                  ) : (
                    <span style={{ alignContent: "center" }}>No Record Available</span>
                  )}
                </>
              )}
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
      <Modal
        open={sortOpen}
        onClose={handleSortClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <List
          className={classes.paper}
          style={{
            height: "315px",
            width: "300px",
            boxSizing: "content-box",
            overflow: "auto",
            paddingRight: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "1rem",
            }}
          >
            <CustomTypography
              sx={{ fontWeight: "400", color: theme.palette.error.main }}
              variant="h6"
              label={"Sort Internal Application"}
            />
            <ButtonBase sx={{ borderRadius: "12px" }} onClick={handleSortClose}>
              <HighlightOffRoundedIcon color="error" />
            </ButtonBase>
          </Box>

          <DragDropContext
            onDragEnd={(param: any) => {
              const srcI = param?.source?.index
              const desI = param?.destination?.index

              if (desI !== undefined && srcI !== desI) {
                const srcOrder = data.items[srcI].orderNumber
                const desOrder = data.items[desI].orderNumber

                const srcId = data.items[srcI].id // Source item ID
                const desId = data.items[desI].id // Destination item ID

                data.items[srcI].orderNumber = desOrder
                data.items[desI].orderNumber = srcOrder

                void handleSort(srcId, desId, desOrder, srcOrder)
              }
            }}
          >
            <Droppable droppableId="droppable-1">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {data?.items?.map((item: any, index1: number) => (
                    <Draggable
                      key={item?.id}
                      draggableId={"draggable-" + String(item?.id)}
                      index={index1}
                    >
                      {(provided, snapshot) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{ width: "100%", padding: 0 }}
                        >
                          <Box sx={{ width: "100%" }}>
                            <Item sx={{ display: "flex" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "1rem",
                                }}
                              >
                                <img
                                  width="30px"
                                  height="30px"
                                  src={`${window.__RUNTIME_CONFIG__.VITE_BASE_URL
                                    }/core-api/internal-application-logos/file/${item.id as string}`}
                                  alt={"Image is not available"}
                                  style={{
                                    objectFit: "contain",
                                    objectPosition: "center",
                                  }}
                                />
                                <Typography variant="body1" color="#434343" textAlign="center">
                                  {item?.name}
                                </Typography>
                              </Box>
                              <DragIndicatorIcon />
                            </Item>
                          </Box>
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </List>
      </Modal>
      <DeleteConfirmationModal open={open} onHide={handleClose} onDelete={handleDelete} />
    </>
  )
}

export default InternalApplication
